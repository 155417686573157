import React, { useEffect, useState } from "react";

import I18n from "i18n-js";
import Lottie from "react-lottie";
import styled from "styled-components";
import {
  Image,
  Button,
  Menu,
  Segment,
  Icon,
  Label,
  Modal,
} from "semantic-ui-react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { refreshMe } from "app/store/me";
import {
  fetchGifts,
  earnGift,
  rewardGift,
  openModalGift,
  closeModalGift,
} from "app/store/gifts";

import congratsGiftLottie from "app/assets/lottie/congrats-gift.json";

export default function Gifts(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user: { kid, account_type },
    logged_in,
  } = useSelector((state) => state.me);
  const { fetch, gift, gifts, filters, open_reward_form } = useSelector(
    (state) => state.gifts
  );

  const [open, setOpen] = useState(false);
  const [photo, setPhoto] = useState();
  const [filteredGifts, setFilteredGifts] = useState([]);

  const color_mappings = {
    pending: "black",
    claimed: "#b19402",
    rewarded: "teal",
  };

  useEffect(() => {
    if (!logged_in || !fetch || account_type !== "family") return;

    dispatch(refreshMe());
    dispatch(fetchGifts(filters));
  }, [logged_in, account_type, fetch, filters]);

  useEffect(() => {
    if (kid) {
      setFilteredGifts(gifts.filter((gift) => gift.state !== "rewarded"));
    } else {
      setFilteredGifts(gifts.filter((gift) => gift.state == "claimed"));
    }
  }, [kid, gifts]);

  return (
    <GiftSection>
      {filteredGifts.map((gift) => {
        const { id, points, title, state, photo_url } = gift;

        return (
          <Button
            as="div"
            labelPosition="right"
            style={{ height: 30 }}
            onClick={() => {
              dispatch(openModalGift(gift));
            }}
          >
            <Button
              icon
              color="gray"
              style={{
                display: "flex",
                alignItems: "center",
                padding: "3px 10px 3px 3px",
              }}
            >
              {photo_url ? (
                <Image
                  style={{
                    width: 26,
                    borderRadius: 3,
                    cursor: "pointer",
                    marginRight: 4,
                  }}
                  size="tiny"
                  verticalAlign="top"
                  src={photo_url}
                />
              ) : (
                <Icon
                  size="large"
                  name="gift"
                  style={{
                    height: 22,
                    width: 26,
                    padding: 0,
                    borderRadius: 3,
                    cursor: "pointer",
                    color: "#1473ad",
                  }}
                />
              )}

              {title}
            </Button>
            <Label as="a" basic pointing="left">
              <span style={{ color: color_mappings[state] }}>
                {points} Points
              </span>
            </Label>
          </Button>
        );
      })}

      {open_reward_form && (
        <Modal
          size="tiny"
          open={true}
          onClose={() => dispatch(closeModalGift())}
        >
          <Modal.Content style={{ margin: 0, padding: 0 }}>
            {gift.state == "pending" ? (
              <div className="text-center">
                <h2 style={{ margin: 10 }}>{gift.title}</h2>
                {gift.photo_url ? (
                  <Image
                    style={{
                      width: "100%",
                      borderRadius: 3,
                      cursor: "pointer",
                      marginRight: 10,
                    }}
                    size="tiny"
                    verticalAlign="top"
                    src={gift.photo_url}
                  />
                ) : (
                  <Icon
                    name="gift"
                    size="massive"
                    style={{
                      alignSelf: "center",
                      margin: "auto",
                      color: "#1473ad",
                    }}
                  />
                )}
              </div>
            ) : (
              <>
                <h2 className="text-center" style={{ margin: 10 }}>
                  Congratulations!!!
                </h2>
                <Lottie
                  speed={1}
                  isClickToPauseDisabled
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: congratsGiftLottie,
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                    alignSelf: "center",
                    marginTop: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    zIndex: 1,
                    textAlign: "center",
                  }}
                  // isStopped={false}
                  isPaused={false}
                />
              </>
            )}

            {kid ? (
              <Button
                fluid
                size="large"
                disabled={gift.state !== "pending"}
                content="Claim"
                color="orange"
                onClick={() => {
                  dispatch(earnGift(gift.id));
                }}
              />
            ) : (
              <Button
                fluid
                size="large"
                disabled={gift.state === "rewarded"}
                content="Reward"
                color="orange"
                onClick={() => {
                  dispatch(rewardGift(gift.id));
                }}
              />
            )}
          </Modal.Content>
        </Modal>
      )}
    </GiftSection>
  );
}

const GiftSection = styled.div`
  display: flex;
`;

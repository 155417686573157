import React, { memo, useState, useEffect } from "react";

import ActionCable from "actioncable";

export const Context = React.createContext({});

const { Provider, Consumer } = Context;

function ActionCableSubscription(props) {
  const {
    cable,
    channel,
    onReceived,
    onConnected,
    onRejected,
    onDisconnected,
  } = props;

  useEffect(() => {
    if (!cable) return;

    const subscription = cable.subscriptions.create(channel, {
      received: onReceived,
      connected: onConnected,
      disconnected: onDisconnected,
      rejected: onRejected,
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [cable, channel]);

  return null;
}

// export const ActionCableConsumer = memo((props) => {
//   return (
//     <Consumer>
//       {({ cable }) => <ActionCableSubscription cable={cable} {...props} />}
//     </Consumer>
//   );
// });

// export const ActionCableConsumer;

export function ActionCableConsumer(props) {
  return (
    <Consumer>
      {({ cable }) => <ActionCableSubscription cable={cable} {...props} />}
    </Consumer>
  );
}

export function ActionCableProvider(props) {
  const { url } = props;
  const [cable, setCable] = useState();

  useEffect(() => {
    const consumer = ActionCable.createConsumer(url);
    setCable(consumer);

    return () => {
      consumer.disconnect();
    };
  }, [url]);

  return <Provider value={{ cable }}>{props.children}</Provider>;
}

import React from "react";

import Lottie from "react-lottie";
import * as loading from "../assets/lottie/loading.json";

function Loading(props) {
  const { style } = props;

  return (
    <Lottie
      speed={1}
      isClickToPauseDisabled
      style={{
        margin: "auto auto",
        ...style,
      }}
      options={{
        loop: true,
        autoplay: true,
        animationData: loading.default,
      }}
      height={220}
      width={220}
      isStopped={false}
      isPaused={false}
    />
  );
}

export default Loading;

import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { create } from "app/api";
import { handleMeResponse, handleActionResponse } from "../utils";

const slice = createSlice({
  name: "synthesis",
  initialState: {
    synthesis: {
      steps: [],
      answers: [],
      questions: [],
      remainders: [],
    },
    validations: {},
  },
  reducers: {
    settingSynthesis: (state, action) => {
      state.synthesis = action.payload;
    },
    handleValidations: (state, action) => {
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const { settingSynthesis, handleValidations } = slice.actions;

export const createSynthesis = (data) => async (dispatch, getState) => {
  try {
    const response = await create({
      endpoint: `/api/v1/synthesis`,
      data,
    });

    dispatch(settingSynthesis(response.data));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";

export default function ExplainSimpleQuestion(props) {
  const { ref, refs, questions } = props;

  return (
    <QuestionWrapper>
      <Description ref={(ref) => (refs.current["questions"] = ref)}>
        {questions.map((question) => {
          const { id, type, value } = question;

          switch (type) {
            case "constant":
              return (
                <span
                  id={id}
                  className="constant"
                  ref={(ref) => (refs.current[id] = ref)}
                >
                  {value}
                </span>
              );
              break;
            case "operator":
              return (
                <span
                  id={id}
                  className="operator"
                  ref={(ref) => (refs.current[id] = ref)}
                >
                  {value}
                </span>
              );
              break;
            case "equal":
              return (
                <span
                  id={id}
                  className="equal"
                  ref={(ref) => (refs.current[id] = ref)}
                >
                  {value}
                </span>
              );
              break;
            case "coeficient":
              return (
                <span
                  id={id}
                  className="coeficient"
                  ref={(ref) => (refs.current[id] = ref)}
                >
                  {value}
                </span>
              );
              break;
            case "variable":
              return (
                <span
                  id={id}
                  className="variable"
                  ref={(ref) => (refs.current[id] = ref)}
                >
                  {value}
                </span>
              );
              break;
            case "answer":
              return (
                <span
                  id={id}
                  className="answer"
                  ref={(ref) => (refs.current[id] = ref)}
                >
                  {value}
                </span>
              );
              break;
            default:
          }
        })}
      </Description>
    </QuestionWrapper>
  );
}

const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: unset;

  .constant {
    margin: 4px 4px;
  }
  .operator {
    margin: 4px 4px;
  }
  .equal {
    margin: 4px 4px;
    color: black;
  }
  .coeficient {
    margin: 4px 4px;
  }
  .variable {
    margin: 4px 4px;
    color: #839747;
  }
`;

const Description = styled.div`
  position: unset;
`;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "../utils";

const slice = createSlice({
  name: "teams",
  initialState: {
    team: {},
    teams: [],
    fetch: true,
    getting: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    destroying: false,
    open_settings_form: false,
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 6,
      sort: { id: "asc" },
      filter: {},
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingTeam: (state) => {
      state.getting = true;
    },
    gotTeam: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.team = action.payload;
    },
    fetchedTeams: (state, action) => {
      const { meta, teams } = action.payload;

      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.pages = meta.pages;
      state.teams = teams;
    },
    fetchingTeams: (state) => {
      state.fetching = true;
    },
    createdTeam: (state, action) => {
      state.fetch = true;
      state.creating = false;
      state.open_form = false;
      state.validations = {};
      state.team = action.payload;
    },
    creatingTeam: (state) => {
      state.creating = true;
    },
    updatedTeam: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.validations = {};
      state.team = action.payload;
    },
    updatingTeam: (state) => {
      state.updating = true;
    },
    destroyingTeam: (state) => {
      state.destroying = true;
    },
    destroyedTeam: (state, action) => {
      state.fetch = true;
      state.destroying = false;
      state.validations = {};
      state.open_settings_form = false;
      state.teams = [
        ...state.teams.filter((team) => team.id !== action.payload.id),
      ];
    },
    openedTeamForm: (state, action) => {
      state.open_form = true;
      state.team = action.payload;
    },
    closedTeamForm: (state) => {
      state.open_form = false;
      state.team = {};
      state.validations = {};
    },
    openedSettingsForm: (state, action) => {
      state.open_settings_form = true;
      state.team = action.payload;
    },
    closedSettingsForm: (state) => {
      state.open_settings_form = false;
      state.team = {};
      state.validations = {};
    },
    handleSetFilters: (state, action) => {
      state.fetch = true;
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotTeam,
  gettingTeam,
  fetchedTeams,
  fetchingTeams,
  createdTeam,
  creatingTeam,
  updatedTeam,
  updatingTeam,
  destroyedTeam,
  destroyingTeam,
  openedTeamForm,
  closedTeamForm,
  handleSetFilters,
  openedSettingsForm,
  closedSettingsForm,
  handleValidations,
} = slice.actions;

export const openTeamForm = (team = {}) => async (dispatch) => {
  dispatch(openedTeamForm(team));
};

export const closeTeamForm = () => async (dispatch) => {
  dispatch(closedTeamForm());
};

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const openSettingsForm = (team = {}) => async (dispatch) => {
  dispatch(openedSettingsForm(team));
};

export const closeSettingsForm = () => async (dispatch) => {
  dispatch(closedSettingsForm());
};

export const getTeam = (id) => async (dispatch) => {
  try {
    dispatch(gettingTeam());

    const response = await get({
      endpoint: `/api/v1/teams/${id}`,
    });
    const { team } = response.data;

    dispatch(gotTeam(team));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchTeams = (data) => async (dispatch) => {
  try {
    dispatch(fetchingTeams());

    const response = await fetch({
      endpoint: `/api/v1/teams`,
      data,
    });

    dispatch(fetchedTeams(response.data));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createTeam = (data) => async (dispatch) => {
  try {
    dispatch(creatingTeam());

    const response = await create({
      endpoint: `/api/v1/teams`,
      data: { team: data },
    });
    const { team } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdTeam(team));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateTeam = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingTeam());

    const response = await update({
      endpoint: `/api/v1/teams/${id}`,
      data: { team: data },
    });
    const { team } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedTeam(team));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const destroyTeam = (id, data) => async (dispatch) => {
  try {
    dispatch(destroyingTeam());

    const response = await destroy({
      endpoint: `/api/v1/teams/${id}`,
      data: { team: data },
    });
    const { team } = response.data;

    toastDeleteSuccessMessage();
    dispatch(destroyedTeam(team));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

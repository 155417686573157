import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { create } from "app/api";
import { handleActionResponse, toastCreateSuccessMessage } from "app/utils";

const slice = createSlice({
  name: "checkout",
  initialState: {
    success: false,
    charging: false,
    validations: {},
  },
  reducers: {
    checkedOutWithCreditCard: (state, action) => {
      state.success = true;
      state.charging = false;
      state.validations = {};
      state.cart = action.payload;
    },
    checkingOutWithCreditCard: (state) => {
      state.charging = true;
    },
    resettedCheckout: (state) => {
      state.success = false;
      state.charging = false;
      state.validations = {};
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  resettedCheckout,
  checkedOutWithCreditCard,
  checkingOutWithCreditCard,
  handleValidations,
} = slice.actions;

export const resetCheckout = () => async (dispatch) => {
  dispatch(resettedCheckout());
};

export const checkoutWithCreditCard = (data) => async (dispatch) => {
  try {
    dispatch(checkingOutWithCreditCard());

    const response = await create({
      endpoint: "/api/v1/checkout/credit_card",
      data: { cart: data },
    });
    const { cart } = response.data;

    dispatch(checkedOutWithCreditCard(cart));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "app/cookie";
import { get, create, update, destroy } from "app/api";
import {
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "app/utils";

const slice = createSlice({
  name: "cart",
  initialState: {
    cart: { cart_line_items: [] },
    checkout: { cart_line_items: [] },
    entities: {},
    getting: false,
    initialized: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    deleting: false,
    validations: {},
  },
  reducers: {
    gettingCart: (state) => {
      state.getting = true;
    },
    gotCart: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.cart = action.payload;
      state.checkout = action.payload;
      state.entities = action.payload.cart_line_items.reduce((items, item) => {
        items[item.product_id] = item;
        return items;
      }, {});
    },
    createdCart: (state, action) => {
      state.creating = false;
      state.open_form = false;
      state.validations = {};
      state.cart = action.payload;
      state.checkout = action.payload;
      state.entities = action.payload.cart_line_items.reduce((items, item) => {
        items[item.product_id] = item;
        return items;
      }, {});
    },
    creatingCart: (state) => {
      state.creating = true;
    },
    updatedCart: (state, action) => {
      state.updating = false;
      state.validations = {};
      state.carts = [
        ...state.carts.filter((cart) => cart.id !== action.payload.id),
        action.payload,
      ];
    },
    updatingCart: (state) => {
      state.updating = true;
    },
    deletedCart: (state, action) => {
      state.deleting = false;
      state.validations = {};
      state.cart = action.payload;
      state.checkout = action.payload;
      state.entities = action.payload.cart_line_items.reduce((items, item) => {
        items[item.product_id] = item;
        return items;
      }, {});
    },
    deletingCart: (state) => {
      state.deleting = true;
    },
    refreshedCart: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.cart = action.payload;
      state.entities = action.payload.cart_line_items.reduce((items, item) => {
        items[item.product_id] = item;
        return items;
      }, {});
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotCart,
  gettingCart,
  createdCart,
  creatingCart,
  updatedCart,
  updatingCart,
  deletedCart,
  deletingCart,
  refreshedCart,
  handleValidations,
} = slice.actions;

export const getCart = (id) => async (dispatch) => {
  try {
    dispatch(gettingCart());

    const response = await get({
      endpoint: `/api/v1/cart`,
    });
    const { cart } = response.data;

    dispatch(gotCart(cart));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const refreshCart = (id) => async (dispatch) => {
  try {
    const response = await get({
      endpoint: `/api/v1/cart`,
    });
    const { cart } = response.data;

    dispatch(refreshedCart(cart));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const addToCart = (data) => async (dispatch, getState) => {
  try {
    dispatch(creatingCart());

    const response = await create({
      endpoint: "/api/v1/cart",
      data: { cart: data },
    });
    const { cart } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdCart(cart));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const checkoutCart = (data) => async (dispatch, getState) => {
  try {
    dispatch(creatingCart());

    const response = await create({
      endpoint: "/api/v1/carts/checkout",
      data,
    });
    const { cart } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdCart(cart));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateCart = (data) => async (dispatch) => {
  try {
    dispatch(updatingCart());

    const response = await update({
      endpoint: `/api/v1/cart`,
      data: { cart: data },
    });
    const { cart } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedCart(cart));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const deleteFromCart = (data) => async (dispatch) => {
  try {
    dispatch(deletingCart());

    const response = await destroy({
      endpoint: `/api/v1/cart`,
      data: { cart: data },
    });
    const { cart } = response.data;

    dispatch(deletedCart(cart));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "app/cookie";
import { get, fetch, create, update, destroy } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "user_sudokus",
  initialState: {
    user_sudoku: null,
    user_sudokus: [],
    getting: false,
    initialized: false,
    fetch: true,
    fetched: false,
    fetching: false,
    editing: false,
    created: false,
    creating: false,
    updating: false,
    deleting: false,
    validations: {},
  },
  reducers: {
    gettingUserSudoku: (state) => {
      state.getting = true;
    },
    gotUserSudoku: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.user_sudoku = action.payload;
    },
    fetchedUserSudokus: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.user_sudokus = action.payload;
    },
    fetchingUserSudokus: (state) => {
      state.fetching = true;
    },
    createdUserSudoku: (state, action) => {
      state.creating = false;
      state.created = true;
      state.validations = {};
      state.user_sudoku = action.payload;
      state.user_sudokus = [...state.user_sudokus, action.payload];
    },
    creatingUserSudoku: (state) => {
      state.creating = true;
    },
    updatedUserSudoku: (state, action) => {
      const { user_sudokus } = state;
      const user_sudoku = action.payload;

      state.updating = false;
      state.validations = {};
      state.user_sudoku = user_sudoku;

      const index = user_sudokus.findIndex((uq) => uq.id == user_sudoku.id);

      if (index == -1) {
        state.user_sudokus = [...user_sudokus, user_sudoku];
      } else {
        user_sudokus[index] = user_sudoku;
        state.user_sudokus = [...user_sudokus];
      }
    },
    updatingUserSudoku: (state) => {
      state.updating = true;
    },
    deletedUserSudoku: (state, action) => {
      state.deleting = false;
      state.validations = {};
      state.user_sudokus = [
        ...state.user_sudokus.filter(
          (user_sudoku) => user_sudoku.id !== action.payload.id
        ),
      ];
    },
    deletingUserSudoku: (state) => {
      state.deleting = true;
    },
    resettedUserSudoku: (state) => {
      state.user_sudoku = null;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.creating = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotUserSudoku,
  gettingUserSudoku,
  fetchedUserSudokus,
  fetchingUserSudokus,
  createdUserSudoku,
  creatingUserSudoku,
  updatedUserSudoku,
  updatingUserSudoku,
  deletedUserSudoku,
  deletingUserSudoku,
  resettedUserSudoku,
  handleValidations,
} = slice.actions;

export const resetUserSudoku = () => async (dispatch) => {
  dispatch(resettedUserSudoku());
};

export const getUserSudoku = (id) => async (dispatch) => {
  try {
    dispatch(gettingUserSudoku());

    const response = await get({
      endpoint: `/api/v1/user_sudokus/${id}`,
    });
    const { user_sudoku } = response.data;

    dispatch(gotUserSudoku(user_sudoku));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchUserSudokus = (data) => async (dispatch) => {
  try {
    dispatch(fetchingUserSudokus());

    const response = await fetch({
      endpoint: `/api/v1/user_sudokus`,
      data,
    });
    const { user_sudokus } = response.data;

    dispatch(fetchedUserSudokus([...user_sudokus]));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createUserSudoku = (data) => async (dispatch) => {
  try {
    dispatch(creatingUserSudoku());

    const response = await create({
      endpoint: `/api/v1/user_sudokus`,
      data: { user_sudoku: data },
    });
    const { user_sudoku } = response.data;

    dispatch(createdUserSudoku(user_sudoku));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateUserSudoku = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingUserSudoku());

    const response = await update({
      endpoint: `/api/v1/user_sudokus/${id}`,
      data: { user_sudoku: data },
    });
    const { user_sudoku } = response.data;

    dispatch(updatedUserSudoku(user_sudoku));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const deleteUserSudoku = (id, data) => async (dispatch) => {
  try {
    dispatch(deletingUserSudoku());

    const response = await destroy({
      endpoint: `/api/v1/user_sudokus/${id}`,
      data: { user_sudoku: data },
    });
    const { user_sudoku } = response.data;

    dispatch(deletedUserSudoku(user_sudoku));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

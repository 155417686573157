const translations = {
  join: "Entrar",
  complete_payment: "Complete Payment",
  upload: "Upload",

  you: "You",
  with: "with",
  class_starts: "Comienza la lección:",

  general: "General",
  not_found: "We could not locate requested resource!",
  create_success: "Successfully created!",
  update_success: "Successfully updated!",
  delete_success: "Successfully deleted!",
  invite_success: "Successfully sent invitation!",
  login_success: "Successfully logged in!",
  accept_success: "Successfully confirmed!",
  account_unlocked: "Your account is unlocked!",
  thanks_for_joining_tutor: "We received your request. Thank you for joining!",

  credit_cards: "Credit Cards",
  paypal: "Paypal",

  welcome_invited_to_account: "%{name} thanks for joining %{account}.",

  math: "Math",
  html: "HTML",
  css: "CSS",
  chess: "Chess",

  how_can_we_help: "How can we help?",
  help: "Help",
  delivery_cost: "Delivery cost",
  minutes: "Minutes",
  all_rights_reserved: "All Rights Reserved",

  tax: "Tax",
  start_chat: "Chat",
  close: "Close",
  under_development: "Under development",

  country: "Country",
  city: "City",
  floor: "Floor",
  unit: "Unit",
  entrance: "Entrance",
  zip: "Zip",

  exact_time: "Exact Time",
  enter_verification_code: "Enter verification code",
  all_products_will_be_removed: "All products will be removed from your cart",

  deliver_to: "Deliver to",
  items: "Items",

  occasion: "Occasion",
  date: "Date",
  type_occasion: "Type Occasion",
  set_reminder: "Set Reminder",

  email_us: "Email",
  cities: "Cities",

  contact_information: "Contact Information",
  password_details: "Password Details",
  resend_confirmation: "Resend Confirmation",

  due: "Due",
  discount: "Discount",
  delivery_fee: "Delivery Fee",
  service_fee: "Service Fee",

  html_title_text: "Clevers.io - Online Learning Platform",

  edit_review: "Edit",

  are_you_sure_to_cancel: "Are you sure to cancel?",
  comment: "Comment",
  buy_now: "Buy Now",
  add_to_cart: "Add To Cart",
  loading: "Loading...",
  no_reviews: "No Reviews",
  num_of_reviews: "reviews",

  thank_you_for_your_order: "Thank you for your order",
  how_was_your_experience: "How was your experience?",
  quality: "Quality",
  communication: "Communication",
  add_your_comment: "Add your comment",
  submit: "Submit",

  choose_city: "Choose city",
  order_with_number: "Order#%{number}",
  enter_new_password: "Enter New Password",
  password_confirmation: "Password Confirmation",
  confirm: "Confirm",
  password_reset_success: "Password reset success",
  continue: "Continue",
  not_yet: "Not yet",
  rate: "Rate",
  cancel: "Cancel",
  locale: "Locale",
  success_sent_confirmation: "Successfully sent confirmation!",
  success_email_confirm: "Successfully confirmed email!",
  current_password: "Current Password",
  order_number: "Order Number",
  communication_rating: "Communication Rating",
  delivery_rating: "Delivery Rating",
  quality_rating: "Quality Rating",
  add_review: "Add Review",
  in_review: "In Review",
  approved: "Approved",
  order_cancelled: "Order is cancelled!",

  // DONE
  enter_city: "Enter City",
  enter_address: "Enter Address",
  reset: "Reset",
  cart: "Cart",
  and: "and",
  write: "Write",
  account: "Account",
  write_dots: "Write ...",
  any_time: "Any Time",
  filters: "Filters",
  select_date_and_hour: "Select Date and Hour",
  enter_and_verify_phone: "Enter and verify your phone number",
  edit_recipient: "Edit Recipient",
  request_address_from_recipient: "Request address from recipient!",

  credit_card: "Credit Card",
  apple_pay: "Apple Pay",
  cash: "Cash",
  recipients: "Recipients",
  postcard: "Postcard",
  want_to_send_postcard: "Do you want to send postcard?",

  price: "Price",
  size: "Size",
  color: "Color",
  flowers: "Flowers",
  occasions: "Occasions",
  products: "Products",

  remember_me: "Remember me",
  or: "Or",
  register: "Register",
  send_password: "Send Password",
  go_back: "Go back",
  enter_your_phone: "Enter your phone",

  first_name: "First Name",
  last_name: "Last Name",
  phone: "Phone",
  address: "Address",
  directions: "Delivery Instructions",
  new_recipient: "New Recipient",

  apple_pay: "Apple Pay",
  credit_card: "Credit Card",
  cash: "Cash",

  orders: "Orders",
  reviews: "Reviews",
  profile: "Profile",
  favorites: "Favorites",
  delivery: "Delivery",
  pickup: "Pickup",
  become_partner: "Become a Partner",
  track_order: "Track your order",

  number: "Number",
  recipient: "Recipient",
  amount: "Amount",
  status: "Status",
  actions: "Actions",

  shop: "Shop",

  created_at: "Created At",
  updated_at: "Updated At",
  pending: "Pending",
  accepted: "Accepted",
  in_progress: "In Progress",
  in_courier: "In Courier",
  in_route: "In Delivery",
  fulfilled: "Fulfilled",
  cancelled: "Cancelled",

  terms: "Terms",
  privacy_policy: "Privacy Policy",
  agree_to_terms: "By signing up, you agree to our",
  promotional_text:
    "Yes! I would like to receives updates, promotional offers from BUKETU.",

  welcome_step_one: "Welcome to Buketu",
  welcome_step_two: "Chat with Shops",
  welcome_step_three: "Track Orders on the Map",

  select_recipient: "Select Recipient",

  pay_with_apple: " Pay %{amount}",
  pay_with_cash: "Pay with cash %{amount}",
  pay_with_card: "Pay with card %{amount}",
  add_card: "Add Card",
  scan_card: "Scan Card",
  go_to_cart: "GO TO CART",

  small: "Small",
  medium: "Medium",
  large: "Large",

  chat: "Chat",
  total: "Total",
  cart_is_empty: "Cart is empty",
  continue_shopping: "Continue shopping",
  order_placed: "Order placed!",
  will_inform_abouut_order: "We will inform you as soon as shop accepts it",

  next: "Next",
  back: "Back",

  height: "Height",
  width: "Width",

  active_orders: "Active Orders",
  fulfilled_orders: "Fulfilled Orders",

  checkout: "Checkout",
  pay: "Pay",

  no_favorites: "You do not have favorites yet",

  settings: "Settings",
  cards: "Cards",
  notifications: "Notifications",
  languages: "Languages",
  currency: "Currency",
  password: "Password",
  signin: "Sign In",
  logout: "Logout",
  timezone: "Timezone",
  english: "English",
  russian: "Russian",
  uzbek: "Uzbek",
  success: "Successfully saved",
  email: "Email",
  login: "Login",
  signup: "Sign up",
  create_one: "Create one",
  forgot_password: "Forgot your password?",
  send: "Send",
  have_account: "Have an account?",
  dont_have_account: "Don't have an account?",
  auth_failed: "Login Failed",
  login_auth_failed: "Invalid Email or password",
  email_sent_success: "Successfully sent email",
  unauthorized: "You are not authorized!",
  too_many_request: "You are making to many request",
  not_found: "We could not find the resource you asked for",
  add_payment_method: "Add Payment Method",
  payment_added: "Payment method is added",

  card_number: "CARD NUMBER",
  expiry: "EXPIRY",
  cvc: "CVC/CCV",
  card_holder_name: "CARDHOLDER'S NAME",
  postal_code: "POSTAL CODE",
  fullname: "Full Name",

  save: "Save",
  server_error: "We are experiencing technical issues. Please try again later!",

  login_to_your_account: "Login to your account",
};

export default translations;

import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import {
  Grid,
  Icon,
  List,
  Header,
  Button,
  Message,
  Accordion,
  Statistic,
  Container,
} from "semantic-ui-react";

import Start from "app/components/shared/Start";
import Status from "app/components/shared/Status";
import EmptyLottieView from "app/components/EmptyLottieView";

import evaluatingLottie from "app/assets/lottie/scan-barcode.json";

export default function EvaluationMessage(props) {
  const {
    stats,
    points,
    status,
    evaluation_status,
    start_timer,
    timespent,
    timespent_in_words,
    onStart,
    onContinue,
  } = props;

  function getView() {
    if (status === "pending") {
      return (
        <Container style={{ alignItems: "center" }}>
          <EarnPointMessage>
            <span>{I18n.t("you_will_earn")}</span>
            <span className="points">{points}</span>
            <span>{I18n.t("points")}</span>
          </EarnPointMessage>
          <Start onStart={onStart} size={320} />
        </Container>
      );
    }

    if (evaluation_status === "completed") {
      return (
        <Container style={{ alignItems: "center" }}>
          <Stats>
            <Statistic size="huge">
              <Statistic.Value text>
                You
                <br />
                Scored
              </Statistic.Value>
            </Statistic>
            <Statistic size="huge" style={{ margin: 0 }} color="blue">
              <Statistic.Value text>
                {stats.map((s) => `${s}%`).join(",")}
              </Statistic.Value>
            </Statistic>
            <Statistic size="small" style={{ margin: 0 }} color="orange">
              <Statistic.Value text>{timespent_in_words}</Statistic.Value>
            </Statistic>
            <Button color="black" onClick={onContinue}>
              {I18n.t("continue")}
            </Button>
          </Stats>
        </Container>
      );
    }

    if (status === "completed") {
      return (
        <EmptyLottieView
          title={I18n.t("evaluation_in_progress")}
          animation={evaluatingLottie}
          speed={0.4}
          options={{
            loop: true,
            autoplay: true,
          }}
          style={{ width: 300 }}
        />
      );
    }
  }

  return (
    <>
      <Status status={status} timespent={timespent} start_timer={start_timer} />
      {getView()}
    </>
  );
}

const Stats = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 167px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const EarnPointMessage = styled.div`
  text-align: center;
  margin: 20px;
  font-size: 25px;
  color: #515e6e;

  .points {
    color: #96990d;
    font-weight: bold;
    margin: 0px 4px;
  }
`;

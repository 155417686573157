import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import {
  Icon,
  Header,
  Grid,
  Segment,
  Container,
  Statistic,
} from "semantic-ui-react";

import Gifts from "./Gifts";
import Progress from "./Progress";

import { fetchKids } from "app/store/kids";
import { fetchReports } from "app/store/reports";

export default function Dashboard() {
  const dispatch = useDispatch();

  const {
    user: { locale },
  } = useSelector((state) => state.me);
  const { filters, selectedKid } = useSelector((state) => state.kids);
  const { report, fetching, filters: reportFilters } = useSelector(
    (state) => state.reports
  );

  const {
    total_tasks,
    completed_tasks,
    progress,
    timespent,
    earned_gifts,
    earned_points,
    total_gifts,
  } = report;

  useEffect(() => {
    dispatch(fetchKids(filters));
  }, []);

  useEffect(() => {
    if (!selectedKid) return;

    dispatch(fetchReports({ kid_id: selectedKid.id, ...reportFilters }));
  }, [locale, selectedKid, reportFilters]);

  return (
    <Container style={{ paddingTop: 14, paddingBottom: 100 }}>
      <Grid columns="equal" padded stackable>
        <Grid.Row textAlign="center">
          <Grid.Column>
            <Segment loading={fetching}>
              <Header as="h4" icon textAlign="center">
                <Icon name="diamond" color="yellow" />
              </Header>
              <Statistic size="small" style={{ margin: 0 }}>
                <Statistic.Label>Earned Points</Statistic.Label>
                <Statistic.Value>{earned_points}</Statistic.Value>
              </Statistic>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment loading={fetching}>
              <Header as="h4" icon textAlign="center">
                <Icon name="time" />
              </Header>
              <Statistic size="small" style={{ margin: 0 }}>
                <Statistic.Label>Time Spent</Statistic.Label>
                <Statistic.Value>{timespent}</Statistic.Value>
              </Statistic>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment loading={fetching}>
              <Header as="h4" icon textAlign="center">
                <Icon name="clipboard list" color="olive" />
              </Header>
              <Statistic size="small" style={{ margin: 0 }}>
                <Statistic.Label>Completed Tasks</Statistic.Label>
                <Statistic.Value>
                  {completed_tasks} of {total_tasks}
                </Statistic.Value>
              </Statistic>
            </Segment>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row textAlign="center">
          <Grid.Column>
            <Segment loading={fetching}>
              <Gifts
                earned_points={earned_points}
                earned_gifts={earned_gifts}
                total_gifts={total_gifts}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column width={12}>
            <Segment loading={fetching}>
              <Progress progress={progress} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
}

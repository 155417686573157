import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Button, Statistic, Container } from "semantic-ui-react";

import Game from "./Game";
import Status from "../components/Status";
import EmptyLottieView from "app/components/EmptyLottieView";
import TaskLoading from "../../components/TaskLoading";

import { getMe } from "app/store/me";
import { getTask, startTask, completeTask } from "app/store/tasks";
import {
  getUserChessLearn,
  updateUserChessLearn,
  resetUserChessLearn,
} from "app/store/user_chess_learns";

import evaluatingLottie from "app/assets/lottie/scan-barcode.json";

export default function UserChessLearnLearns() {
  let { slug, chess_slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    user: { locale },
  } = useSelector((state) => state.me);

  const { task } = useSelector((state) => state.tasks);
  const { user_chess_learn } = useSelector((state) => state.user_chess_learns);

  useEffect(() => {
    dispatch(getTask(slug));

    return () => dispatch(resetUserChessLearn());
  }, [slug]);

  useEffect(() => {
    dispatch(getUserChessLearn(chess_slug));
  }, [slug, locale, chess_slug, task]);

  useEffect(() => {
    if (!user_chess_learn) return;

    const { status } = task;
    const { won, evaluation_status } = user_chess_learn;

    if (won && status !== "completed") {
      dispatch(completeTask(slug));
    }

    if (status === "completed" && evaluation_status !== "completed") {
      setTimeout(() => {
        dispatch(getUserChessLearn(chess_slug));
      }, 2000);
    }

    if (evaluation_status === "completed") {
      dispatch(getMe());
    }
  }, [slug, task, chess_slug, user_chess_learn]);

  function resetChess() {
    const { id, problem } = user_chess_learn;

    const solution = problem.map((r) => r.join("")).join(",");
    dispatch(updateUserChessLearn(id, { solution }));
  }

  if (!user_chess_learn) {
    return <TaskLoading />;
  }

  const { status, timespent, timespent_in_words } = task;
  const {
    id,
    stats,
    title,
    points,
    display_name,
    evaluation_status,
  } = user_chess_learn;

  function getScreen() {
    if (evaluation_status == "completed") {
      return (
        <>
          <Container style={{ alignItems: "center" }}>
            <Stats>
              <Statistic size="huge">
                <Statistic.Value text>
                  You
                  <br />
                  Scored
                </Statistic.Value>
              </Statistic>
              <Statistic size="huge" style={{ margin: 0 }} color="blue">
                <Statistic.Value text>
                  {stats.map((s) => `${s}%`).join(",")}
                </Statistic.Value>
              </Statistic>
              <Statistic size="small" style={{ margin: 0 }} color="orange">
                <Statistic.Value text>{timespent_in_words}</Statistic.Value>
              </Statistic>
              <Button color="black" onClick={() => navigate("/")}>
                Continue
              </Button>
            </Stats>
          </Container>
        </>
      );
    } else if (status == "completed") {
      return (
        <EmptyLottieView
          title="Evaluation is in progress..."
          animation={evaluatingLottie}
          speed={0.4}
          options={{
            loop: true,
            autoplay: true,
          }}
          style={{ width: "45%", marginBottom: 40, marginTop: -80 }}
        />
      );
    } else {
      return (
        <>
          <EarnPointMessage>{title}</EarnPointMessage>

          <EarnPointMessage>
            <span>{I18n.t("you_will_earn")}</span>
            <span className="points">{points}</span>
            <span>{I18n.t("points")}</span>
          </EarnPointMessage>
          <Game
            display_name={display_name}
            user_chess_learn={user_chess_learn}
            onChange={(changes) =>
              dispatch(updateUserChessLearn(id, { solution: changes }))
            }
            onStart={() => dispatch(startTask(slug))}
            onEnd={() => {
              dispatch(completeTask(slug));
            }}
          />
          <EarnPointMessage>{display_name}</EarnPointMessage>
        </>
      );
    }
  }

  return (
    <>
      <Status
        status={status}
        timespent={timespent}
        start_timer={status == "started"}
        show_action_buttons={false}
      />
      {getScreen()}
    </>
  );
}

const Stats = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 167px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const EarnPointMessage = styled.div`
  text-align: center;
  margin: 20px;
  font-size: 25px;
  color: #515e6e;

  .points {
    color: #96990d;
    font-weight: bold;
    margin: 0px 4px;
  }
`;

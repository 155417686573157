import React, { useState, useEffect } from "react";

import axios from "axios";

import Lottie from "react-lottie";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Icon,
  Header,
  Image,
  Message,
  Segment,
  Input,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import { login, logout, getSsoDetails, resetLoginNeeded } from "app/store/me";

import QrCode from "app/components/QrCode";
import SocialLogin from "app/components/social_login";
import StatusBar from "./StatusBar";

import * as signup from "app/assets/lottie/signup.json";

import config from "app/config";

export default function LoginForm(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { csrf_token } = useSelector((state) => state.csrf_token);
  const { user, sso, logging_in, logged_in, validations } = useSelector(
    (state) => state.me
  );
  const { state = {} } = location;
  // const { robot } = state;
  const [loginWithQrCode, setLoginWithQrCode] = useState(false);

  // const [data, setData] = useState({
  //   email: "",
  //   password: "secret",
  // });
  const [data, setData] = useState({});

  function loginUser() {
    dispatch(login(data));

    // if (data.email.match(/@/)) {
    //   dispatch(login(data));
    // } else {
    //   dispatch(loginKid({ username: data.email, password: data.password }));
    // }
  }

  // useEffect(() => {
  //   dispatch(resetLoginNeeded());
  // }, []);

  // useEffect(() => {
  //   if (robot) loginUser();
  // }, [robot]);

  useEffect(() => {
    if (logged_in) {
      navigate("/");
    }
  }, [logged_in]);

  return (
    <>
      <StatusBar />
      <Grid textAlign="center" verticalAlign="middle" style={{ padding: 0 }}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Lottie
            width={240}
            height={240}
            options={{
              loop: true,
              autoplay: true,
              animationData: signup.default,
            }}
            isStopped={false}
            isPaused={false}
          />

          <Form size="large" loading={logging_in} autoComplete="off">
            <Segment stacked>
              <Form.Input
                fluid
                // required
                icon="mail"
                value={data.email}
                iconPosition="left"
                placeholder="Email"
                onChange={(event) => {
                  const email = event.target.value;

                  dispatch(getSsoDetails(email));
                  setData({ ...data, email });
                }}
                error={
                  validations.email
                    ? {
                        content: validations.email,
                      }
                    : false
                }
              />
              {!sso.enabled && (
                <Form.Input
                  fluid
                  // required
                  icon="lock"
                  value={data.password}
                  iconPosition="left"
                  placeholder="Password"
                  // autoComplete="new-password"
                  onChange={(event) => {
                    setData({ ...data, password: event.target.value });
                  }}
                  type="password"
                  error={
                    validations.password
                      ? {
                          content: validations.password,
                        }
                      : false
                  }
                />
              )}

              {sso.enabled ? (
                <Button
                  fluid
                  compact
                  color="teal"
                  size="large"
                  disabled={logging_in}
                  onClick={(event) => {
                    event.preventDefault();

                    window.location.href = sso.endpoint;
                  }}
                >
                  Login with SSO
                </Button>
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    compact
                    type="button"
                    color="white"
                    size="large"
                    icon="qrcode"
                    onClick={() => {
                      setLoginWithQrCode(true);
                    }}
                  />
                  <Button
                    // fluid
                    compact
                    type="submit"
                    color="teal"
                    size="large"
                    disabled={logging_in}
                    onClick={() => {
                      loginUser();
                    }}
                  >
                    Login
                  </Button>
                </div>
              )}
            </Segment>
          </Form>
          {false && (
            <SocialLogin
              url={config.url}
              text="Sign in with"
              csrf_token={csrf_token}
            />
          )}
          <Message
            style={{
              color: "#474a4e",
              fontWeight: "bold",
            }}
          >
            Forgot Password? <Link to="/forgot_password">Recover</Link>
          </Message>
          <Message
            style={{
              color: "#474a4e",
              fontWeight: "bold",
            }}
          >
            New to Clevers? <Link to="/signup">Sign up</Link>
          </Message>
        </Grid.Column>
        {loginWithQrCode && (
          <QrCode
            onUrl={(url) => (window.location = url)}
            onClose={() => setLoginWithQrCode(false)}
          />
        )}
      </Grid>
    </>
  );
}

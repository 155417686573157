import React, { useState, useEffect } from "react";

import Lottie from "react-lottie";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Input,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import * as signup from "../../assets/lottie/signup.json";
import { resetPassword } from "../../store/me";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { logged_in, validations } = useSelector((state) => state.me);

  const search = location.search;
  const params = new URLSearchParams(search);
  const reset_password_token = params.get("reset_password_token");
  const [data, setData] = useState({
    password: "",
    password_confirmation: "",
    reset_password_token,
  });

  useEffect(() => {
    if (!reset_password_token) {
      navigate("/");
    }
  }, [reset_password_token]);

  useEffect(() => {
    if (logged_in) {
      navigate("/");
    }
  }, [logged_in]);

  return (
    <Grid textAlign="center" verticalAlign="middle" style={{ padding: 0 }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Lottie
          width={240}
          height={240}
          options={{
            loop: true,
            autoplay: true,
            animationData: signup.default,
          }}
          isStopped={false}
          isPaused={false}
        />
        <Form size="large" loading={false}>
          <Segment stacked>
            <Form.Input
              fluid
              // required
              icon="lock"
              type="password"
              value={data.password}
              iconPosition="left"
              placeholder="Password"
              onChange={(event) => {
                setData({ ...data, password: event.target.value });
              }}
              error={
                validations.password
                  ? {
                      content: validations.password,
                    }
                  : false
              }
            />

            <Form.Input
              fluid
              // required
              icon="lock"
              type="password"
              value={data.password_confirmation}
              iconPosition="left"
              placeholder="Password Confirmation"
              onChange={(event) => {
                setData({ ...data, password_confirmation: event.target.value });
              }}
              error={
                validations.password_confirmation
                  ? {
                      content: validations.password_confirmation,
                    }
                  : false
              }
            />

            <Button
              color="teal"
              fluid
              size="large"
              onClick={() => {
                dispatch(resetPassword(data));
              }}
            >
              Submit
            </Button>
          </Segment>
        </Form>
        <Message
          style={{
            color: "#474a4e",
            fontWeight: "bold",
          }}
        >
          Back to? <Link to="/login">Login</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}

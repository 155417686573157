export const DIMS = 3;
export const DRAW = 0;
export const PLAYER_X = "x";
export const PLAYER_O = "o";
export const SCORES = {
  x: 1,
  0: 0,
  o: -1,
};
export const SQUARE_DIMS = 100;

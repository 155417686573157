import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "app/cookie";
import { get, fetch, create, update, destroy } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "user_chess_challenges",
  initialState: {
    user_chess_challenge: null,
    user_chess_challenges: [],
    getting: false,
    initialized: false,
    fetch: true,
    fetched: false,
    fetching: false,
    editing: false,
    created: false,
    creating: false,
    updating: false,
    deleting: false,
    validations: {},
  },
  reducers: {
    gettingUserChessChallenge: (state) => {
      state.getting = true;
    },
    gotUserChessChallenge: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.user_chess_challenge = action.payload;
    },
    fetchedUserChessChallenges: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.user_chess_challenges = action.payload;
    },
    fetchingUserChessChallenges: (state) => {
      state.fetching = true;
    },
    createdUserChessChallenge: (state, action) => {
      state.creating = false;
      state.created = true;
      state.validations = {};
      state.user_chess_challenge = action.payload;
      state.user_chess_challenges = [
        ...state.user_chess_challenges,
        action.payload,
      ];
    },
    creatingUserChessChallenge: (state) => {
      state.creating = true;
    },
    updatedUserChessChallenge: (state, action) => {
      const { user_chess_challenges } = state;
      const user_chess_challenge = action.payload;

      state.updating = false;
      state.validations = {};
      state.user_chess_challenge = user_chess_challenge;

      const index = user_chess_challenges.findIndex(
        (uq) => uq.id == user_chess_challenge.id
      );

      if (index == -1) {
        state.user_chess_challenges = [
          ...user_chess_challenges,
          user_chess_challenge,
        ];
      } else {
        user_chess_challenges[index] = user_chess_challenge;
        state.user_chess_challenges = [...user_chess_challenges];
      }
    },
    updatingUserChessChallenge: (state) => {
      state.updating = true;
    },
    deletedUserChessChallenge: (state, action) => {
      state.deleting = false;
      state.validations = {};
      state.user_chess_challenges = [
        ...state.user_chess_challenges.filter(
          (user_chess_challenge) =>
            user_chess_challenge.id !== action.payload.id
        ),
      ];
    },
    deletingUserChessChallenge: (state) => {
      state.deleting = true;
    },
    resettedUserChessChallenge: (state) => {
      state.user_chess_challenge = null;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.creating = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotUserChessChallenge,
  gettingUserChessChallenge,
  fetchedUserChessChallenges,
  fetchingUserChessChallenges,
  createdUserChessChallenge,
  creatingUserChessChallenge,
  updatedUserChessChallenge,
  updatingUserChessChallenge,
  deletedUserChessChallenge,
  deletingUserChessChallenge,
  resettedUserChessChallenge,
  handleValidations,
} = slice.actions;

export const resetUserChessChallenge = () => async (dispatch) => {
  dispatch(resettedUserChessChallenge());
};

export const getUserChessChallenge = (id) => async (dispatch) => {
  try {
    dispatch(gettingUserChessChallenge());

    const response = await get({
      endpoint: `/api/v1/user_chess_challenges/${id}`,
    });
    const { user_chess_challenge } = response.data;

    dispatch(gotUserChessChallenge(user_chess_challenge));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchUserChessChallenges = (data) => async (dispatch) => {
  try {
    dispatch(fetchingUserChessChallenges());

    const response = await fetch({
      endpoint: `/api/v1/user_chess_challenges`,
      data,
    });
    const { user_chess_challenges } = response.data;

    dispatch(fetchedUserChessChallenges([...user_chess_challenges]));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createUserChessChallenge = (data) => async (dispatch) => {
  try {
    dispatch(creatingUserChessChallenge());

    const response = await create({
      endpoint: `/api/v1/user_chess_challenges`,
      data: { user_chess_challenge: data },
    });
    const { user_chess_challenge } = response.data;

    dispatch(createdUserChessChallenge(user_chess_challenge));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateUserChessChallenge = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingUserChessChallenge());

    const response = await update({
      endpoint: `/api/v1/user_chess_challenges/${id}`,
      data: { user_chess_challenge: data },
    });
    const { user_chess_challenge } = response.data;

    dispatch(updatedUserChessChallenge(user_chess_challenge));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const deleteUserChessChallenge = (id, data) => async (dispatch) => {
  try {
    dispatch(deletingUserChessChallenge());

    const response = await destroy({
      endpoint: `/api/v1/user_chess_challenges/${id}`,
      data: { user_chess_challenge: data },
    });
    const { user_chess_challenge } = response.data;

    dispatch(deletedUserChessChallenge(user_chess_challenge));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

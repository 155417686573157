import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { create } from "app/api";

const slice = createSlice({
  name: "checkout",
  initialState: {
    initialized: false,
    connected: false,
    validations: {},
  },
  reducers: {
    connectedZoom: (state) => {
      state.initialized = true;
      state.connected = true;
      state.validations = {};
    },
    failedZoom: (state) => {
      state.initialized = true;
      state.connected = false;
      state.validations = {};
    },
    handleValidations: (state, action) => {
      state.initialized = true;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const { failedZoom, connectedZoom, handleValidations } = slice.actions;

export const connectZoom = (data) => async (dispatch) => {
  try {
    const response = await create({
      endpoint: "/api/v1/zoom/auth",
      data: { auth: data },
    });

    dispatch(connectedZoom());
  } catch (response) {
    console.log(response);
    dispatch(failedZoom());
  }
};

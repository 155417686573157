import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "app/cookie";
import { get, fetch, create, update, destroy } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "user_chess_catches",
  initialState: {
    user_chess_catch: null,
    user_chess_catches: [],
    getting: false,
    initialized: false,
    fetch: true,
    fetched: false,
    fetching: false,
    editing: false,
    created: false,
    creating: false,
    updating: false,
    deleting: false,
    validations: {},
  },
  reducers: {
    gettingUserChessCatch: (state) => {
      state.getting = true;
    },
    gotUserChessCatch: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.user_chess_catch = action.payload;
    },
    fetchedUserChessCatches: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.user_chess_catches = action.payload;
    },
    fetchingUserChessCatches: (state) => {
      state.fetching = true;
    },
    createdUserChessCatch: (state, action) => {
      state.creating = false;
      state.created = true;
      state.validations = {};
      state.user_chess_catch = action.payload;
      state.user_chess_catches = [...state.user_chess_catches, action.payload];
    },
    creatingUserChessCatch: (state) => {
      state.creating = true;
    },
    updatedUserChessCatch: (state, action) => {
      const { user_chess_catches } = state;
      const user_chess_catch = action.payload;

      state.updating = false;
      state.validations = {};
      state.user_chess_catch = user_chess_catch;

      const index = user_chess_catches.findIndex(
        (uq) => uq.id == user_chess_catch.id
      );

      if (index == -1) {
        state.user_chess_catches = [...user_chess_catches, user_chess_catch];
      } else {
        user_chess_catches[index] = user_chess_catch;
        state.user_chess_catches = [...user_chess_catches];
      }
    },
    updatingUserChessCatch: (state) => {
      state.updating = true;
    },
    deletedUserChessCatch: (state, action) => {
      state.deleting = false;
      state.validations = {};
      state.user_chess_catches = [
        ...state.user_chess_catches.filter(
          (user_chess_catch) => user_chess_catch.id !== action.payload.id
        ),
      ];
    },
    deletingUserChessCatch: (state) => {
      state.deleting = true;
    },
    resettedUserChessCatch: (state) => {
      state.user_chess_catch = null;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.creating = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotUserChessCatch,
  gettingUserChessCatch,
  fetchedUserChessCatches,
  fetchingUserChessCatches,
  createdUserChessCatch,
  creatingUserChessCatch,
  updatedUserChessCatch,
  updatingUserChessCatch,
  deletedUserChessCatch,
  deletingUserChessCatch,
  resettedUserChessCatch,
  handleValidations,
} = slice.actions;

export const resetUserChessCatch = () => async (dispatch) => {
  dispatch(resettedUserChessCatch());
};

export const getUserChessCatch = (id) => async (dispatch) => {
  try {
    dispatch(gettingUserChessCatch());

    const response = await get({
      endpoint: `/api/v1/user_chess_catches/${id}`,
    });
    const { user_chess_catch } = response.data;

    dispatch(gotUserChessCatch(user_chess_catch));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchUserChessCatches = (data) => async (dispatch) => {
  try {
    dispatch(fetchingUserChessCatches());

    const response = await fetch({
      endpoint: `/api/v1/user_chess_catches`,
      data,
    });
    const { user_chess_catches } = response.data;

    dispatch(fetchedUserChessCatches([...user_chess_catches]));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createUserChessCatch = (data) => async (dispatch) => {
  try {
    dispatch(creatingUserChessCatch());

    const response = await create({
      endpoint: `/api/v1/user_chess_catches`,
      data: { user_chess_catch: data },
    });
    const { user_chess_catch } = response.data;

    dispatch(createdUserChessCatch(user_chess_catch));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateUserChessCatch = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingUserChessCatch());

    const response = await update({
      endpoint: `/api/v1/user_chess_catches/${id}`,
      data: { user_chess_catch: data },
    });
    const { user_chess_catch } = response.data;

    dispatch(updatedUserChessCatch(user_chess_catch));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const deleteUserChessCatch = (id, data) => async (dispatch) => {
  try {
    dispatch(deletingUserChessCatch());

    const response = await destroy({
      endpoint: `/api/v1/user_chess_catches/${id}`,
      data: { user_chess_catch: data },
    });
    const { user_chess_catch } = response.data;

    dispatch(deletedUserChessCatch(user_chess_catch));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

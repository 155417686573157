import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, update } from "../api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "../utils";

const slice = createSlice({
  name: "preference",
  initialState: {
    preference: { dark_mode: false },
    getting: false,
    updating: false,
    validations: {},
  },
  reducers: {
    gettingPreference: (state) => {
      state.getting = true;
    },
    gotPreference: (state, action) => {
      state.getting = false;
      state.preference = action.payload;
    },
    updatedPreference: (state, action) => {
      state.updating = false;
      state.preference = action.payload;
    },
    updatingPreference: (state) => {
      state.updating = true;
    },
    handleValidations: (state, action) => {
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotPreference,
  gettingPreference,
  updatedPreference,
  updatingPreference,
  handleValidations,
} = slice.actions;

export const getPreference = () => async (dispatch, getState) => {
  try {
    dispatch(gettingPreference());

    const response = await get({
      endpoint: `/api/v1/preference`,
    });
    const { preference } = response.data;

    dispatch(gotPreference(preference));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updatePreference = (data) => async (dispatch, getState) => {
  try {
    dispatch(updatingPreference());

    const response = await update({
      endpoint: `/api/v1/preference`,
      data: { preference: data },
    });
    const { preference } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedPreference(preference));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import { Button, Statistic, Container } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Game from "./Game";
import Start from "app/components/shared/Start";
import Status from "app/components/shared/Status";
import EmptyLottieView from "app/components/EmptyLottieView";

import { getMe } from "app/store/me";
import { getTask, startTask, completeTask } from "app/store/tasks";
import {
  getUserSudoku,
  updateUserSudoku,
  resetUserSudoku,
} from "app/store/user_sudokus";

import evaluatingLottie from "app/assets/lottie/scan-barcode.json";

export default function UserSudokus() {
  let { slug, sudoku_slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    user: { locale },
  } = useSelector((state) => state.me);

  const { task } = useSelector((state) => state.tasks);
  const { user_sudoku } = useSelector((state) => state.user_sudokus);

  useEffect(() => {
    dispatch(getTask(slug));
  }, [slug]);

  useEffect(() => {
    dispatch(getUserSudoku(sudoku_slug));
  }, [slug, locale, sudoku_slug, task]);

  useEffect(() => {
    if (!user_sudoku) return;

    const { status } = task;
    const { evaluation_status } = user_sudoku;

    if (status === "completed" && evaluation_status !== "completed") {
      setTimeout(() => {
        dispatch(getUserSudoku(sudoku_slug));
      }, 2000);
    }

    if (evaluation_status === "completed") {
      dispatch(getMe());
    }
  }, [sudoku_slug, user_sudoku]);

  function resetSudoku() {
    const { id, problem } = user_sudoku;

    const solution = problem.map((r) => r.join("")).join(",");
    dispatch(updateUserSudoku(id, { solution }));
  }

  function submitSudoku() {
    const { id } = user_sudoku;

    dispatch(completeTask(slug));
  }

  if (!user_sudoku) {
    return null;
  }

  function getScreen() {
    const { status, timespent_in_words } = task;
    const { id, stats, points, evaluation_status } = user_sudoku;

    if (evaluation_status == "completed") {
      return (
        <>
          <Container style={{ alignItems: "center" }}>
            <Stats>
              <Statistic size="huge">
                <Statistic.Value text>
                  You
                  <br />
                  Scored
                </Statistic.Value>
              </Statistic>
              <Statistic size="huge" style={{ margin: 0 }} color="blue">
                <Statistic.Value text>
                  {stats.map((s) => `${s}%`).join(",")}
                </Statistic.Value>
              </Statistic>
              <Statistic size="small" style={{ margin: 0 }} color="orange">
                <Statistic.Value text>{timespent_in_words}</Statistic.Value>
              </Statistic>
              <Button color="black" onClick={() => navigate("/")}>
                Continue
              </Button>
            </Stats>
          </Container>
        </>
      );
    } else if (status == "completed") {
      return (
        <EmptyLottieView
          title="Evaluation is in progress..."
          animation={evaluatingLottie}
          speed={0.4}
          options={{
            loop: true,
            autoplay: true,
          }}
          style={{ width: "45%", marginBottom: 40, marginTop: -80 }}
        />
      );
    } else {
      return (
        <>
          <EarnPointMessage>
            <span>{I18n.t("you_will_earn")}</span>
            <span className="points">{points}</span>
            <span>{I18n.t("points")}</span>
          </EarnPointMessage>
          {status === "pending" ? (
            <Start onStart={() => dispatch(startTask(slug))} size={320} />
          ) : (
            <Game
              sudoku={user_sudoku}
              onChange={(changes) =>
                dispatch(updateUserSudoku(id, { solution: changes }))
              }
            />
          )}
        </>
      );
    }
  }

  const { status, timespent } = task;

  return (
    <>
      <Status
        status={status}
        show_reset_button
        timespent={timespent}
        start_timer={status === "started"}
        show_action_buttons={status === "started"}
        onReset={resetSudoku}
        onSubmit={submitSudoku}
      />
      {getScreen()}
    </>
  );
}

const Stats = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 167px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const EarnPointMessage = styled.div`
  text-align: center;
  margin: 20px;
  font-size: 25px;
  color: #515e6e;

  .points {
    color: #96990d;
    font-weight: bold;
    margin: 0px 4px;
  }
`;

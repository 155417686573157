import React from "react";

import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";

export default function Cart() {
  return <div>Empty</div>;
}

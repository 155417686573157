import React, { useEffect } from "react";

import I18n from "i18n-js";
import { Button, Menu, Segment } from "semantic-ui-react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { showTopBarStatus, hideTopBarStatus } from "app/store/main";

import VoiceSlideSelector from "./VoiceSlideSelector";

export default function MathStatusBar(props) {
  return (
    <Segment
      style={{
        margin: 0,
        padding: 0,
        position: "absolute",
        width: "100%",
        top: 0,
        zIndex: 10,
        // background: "#ab95a126",
      }}
    >
      <VoiceSlideSelector />
    </Segment>
  );
}

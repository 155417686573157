import React from "react";

import I18n from "i18n-js";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Label,
  Button,
  Menu,
  Icon,
  Message,
  Segment,
  Popup,
} from "semantic-ui-react";

export default function StatusBar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, logged_in } = useSelector((state) => state.me);
  const { top_bar_status } = useSelector((state) => state.main);
  const {
    cart: { items_count },
  } = useSelector((state) => state.cart);

  if (!top_bar_status) return null;

  return (
    <Segment
      style={{
        margin: 0,
        padding: 0,
        position: "sticky",
        top: 0,
        zIndex: 10,
      }}
    >
      <Menu secondary>
        <Menu.Item style={{ padding: "0px 0px 0px 10px" }}>
          <Button basic color="blue" compact style={{ padding: 8 }}>
            Math
          </Button>
        </Menu.Item>

        <Menu.Item style={{ padding: 0 }}>
          <Button basic color="black" compact style={{ padding: 8 }}>
            Chess
          </Button>
        </Menu.Item>

        <Menu.Item style={{ padding: 0 }}>
          <Button basic color="orange" compact style={{ padding: 8 }}>
            Sudoku
          </Button>
        </Menu.Item>

        <Menu.Item style={{ padding: 0 }}>
          <Button basic color="black" compact style={{ padding: 8 }}>
            Tic Tac Toe
          </Button>
        </Menu.Item>

        <Menu.Item style={{ padding: 0 }}>
          <Button basic color="olive" compact style={{ padding: 8 }}>
            Geography
          </Button>
        </Menu.Item>

        <Menu.Menu position="right"></Menu.Menu>
      </Menu>
    </Segment>
  );
}

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { fetch, update } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "payment_methods",
  initialState: {
    fetch: true,
    fetched: false,
    fetching: false,
    initialized: false,
    payment_methods: [],
    validations: {},
  },
  reducers: {
    fetchedPaymentMethods: (state, action) => {
      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.payment_methods = action.payload;
    },
    fetchingPaymentMethods: (state) => {
      state.fetching = true;
    },
    refetchPaymentMethods: (state) => {
      state.fetch = true;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  fetchedPaymentMethods,
  fetchingPaymentMethods,
  refetchPaymentMethods,
  handleValidations,
} = slice.actions;

export const fetchPaymentMethods = () => async (dispatch) => {
  try {
    dispatch(fetchingPaymentMethods());

    const response = await fetch({
      endpoint: `/api/v1/payment_methods`,
    });
    const { payment_methods } = response.data;

    dispatch(fetchedPaymentMethods(payment_methods));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const setAsDefaultCreditCard = (id, data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: `/api/v1/payment_methods/${id}/default`,
      data: {
        payment_method: data,
      },
    });

    dispatch(refetchPaymentMethods());
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import ReactAudioPlayer from "react-audio-player";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Header, Menu, Button } from "semantic-ui-react";

import Math from "./Math";
import SimpleMath from "./SimpleMath";
import ExplainSimpleMath from "./ExplainSimpleMath";
import Formula from "./Formula";
import QuestionDescription from "./QuestionDescription";
import { updateUserQuiz } from "app/store/user_quizzes";
import { resetSynthesis } from "app/store/questions/synthesis";

export default function SmallKidsQuestions(props) {
  const dispatch = useDispatch();

  const {
    dark,
    locale,
    points,
    quiz_slug,
    answers,
    questions,
    skippable,
    help_enabled,
    instant_feedback,
    onSubmit,
  } = props;
  const { key_by_voices } = useSelector((state) => state.voice_feedbacks);

  const [question, setQuestion] = useState(null);
  const [helpNeeded, setHelpNeeded] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAudioUrl, setCurrentAudioUrl] = useState();
  const [currentSelected, setCurrentSelected] = useState();

  useEffect(() => {
    return () => {
      setCurrentIndex(0);
    };
  }, []);

  useEffect(() => {
    setHelpNeeded(false);
    dispatch(resetSynthesis());
    setQuestion(questions[currentIndex]);
  }, [currentIndex, questions]);

  useEffect(() => {
    if (!instant_feedback || !currentSelected || !question) return;

    const answer = answers[question.id];

    if (answer.correct == currentSelected) {
      setCurrentAudioUrl(key_by_voices.excellent);
    } else {
      setCurrentAudioUrl(key_by_voices.try_again);
    }

    setCurrentSelected(null);
  }, [instant_feedback, question, answers]);

  if (!question || !quiz_slug) {
    return null;
  }

  const {
    id,
    kind,
    level,
    subject,
    branch,
    title,
    choices,
    audios,
    values,
    metadata,
    explainable,
    explanation,
    template_type,
    translations,
    question_group,
  } = question;
  const audio_url = audios["title"];
  const { formula } = metadata;
  const answer = answers[question.id] || {};

  return (
    <QuestionsContainer>
      {audio_url && <ReactAudioPlayer src={audio_url} autoPlay />}

      <EarnPointMessage>
        <span>{I18n.t("you_will_earn")}</span>
        <span className="points">{points}</span>
        <span>{I18n.t("points")}</span>
      </EarnPointMessage>

      <div className={dark ? "questions" : "questions light"}>
        <div className="question-section">
          <div className="question-count">
            <span>
              {I18n.t("user_quizzes_question_of_total", {
                number: currentIndex + 1,
                total: questions.length,
              })}
            </span>
          </div>
        </div>
        <div className="question-description">
          {subject === "math" && (
            <div>
              {level == "tk" && branch == "addition_two" ? (
                <Formula formula={formula} />
              ) : (
                <QuestionDescription text={translations["title"] || title} />
              )}

              {help_enabled && explainable && (
                <Button
                  circular
                  icon="help"
                  color="pink"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                  onClick={() => setHelpNeeded(!helpNeeded)}
                />
              )}
            </div>
          )}
          {subject === "geography" && (
            <div>
              <QuestionDescription text={translations["title"] || title} />
              {help_enabled && explainable && (
                <Button
                  circular
                  icon="help"
                  color="pink"
                  style={{
                    position: "absolute",
                    right: 10,
                    top: 10,
                  }}
                  onClick={() => setHelpNeeded(!helpNeeded)}
                />
              )}
            </div>
          )}
        </div>

        {help_enabled &&
          explainable &&
          helpNeeded &&
          explanation == "simple" && (
            <ExplainSimpleMath
              help
              question_id={question.id}
              explanation={explanation}
            />
          )}

        {help_enabled &&
          explainable &&
          helpNeeded &&
          explanation == "medium" && (
            <Math help question_id={question.id} question={values} />
          )}

        <div className="answer-section">
          {choices.map((choice) => {
            const { id, title, audios, translations } = choice;
            const { id: question_id } = question;
            const audio_url = audios["text"];

            return (
              <div
                key={`choice-${id}`}
                className={answer.selected == id ? "answer selected" : "answer"}
                onMouseOver={() => {
                  if (audio_url) {
                    setCurrentAudioUrl(audio_url);
                  } else {
                    setCurrentAudioUrl();
                  }
                }}
                // onMouseOut={() => {
                //   setCurrentAudioUrl();
                // }}
                onClick={() => {
                  setCurrentSelected(id);

                  dispatch(
                    updateUserQuiz(quiz_slug, {
                      answers: {
                        ...answers,
                        [question_id]: {
                          selected: id,
                        },
                      },
                    })
                  ).then(() => {
                    // User deselected choice
                    // if (answer.selected) {
                    //   return;
                    // }
                    // if (currentIndex < questions.length - 1) {
                    //   setTimeout(() => {
                    //     setCurrentIndex(currentIndex + 1);
                    //   }, 500);
                    // }
                  });
                }}
              >
                {answer.selected == id ? (
                  <input
                    key={`choice-checked-${id}`}
                    type={kind == "single_choice" ? "radio" : "checkbox"}
                    checked
                    style={{
                      margin: "4px 4px 4px -4px",
                      width: 14,
                      height: 14,
                      display: "none",
                    }}
                  />
                ) : (
                  <input
                    key={`choice-unchecked-${id}`}
                    type={kind == "single_choice" ? "radio" : "checkbox"}
                    style={{
                      margin: "4px 4px 4px -4px",
                      width: 14,
                      height: 14,
                      display: "none",
                    }}
                  />
                )}

                {translations["text"] || title}
              </div>
            );
          })}
        </div>

        {currentIndex !== 0 && (
          <Icon
            inverted
            name="chevron left"
            color={dark ? "violet" : "black"}
            size="big"
            style={{
              position: "absolute",
              bottom: 10,
              left: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              setCurrentIndex(currentIndex - 1);
            }}
          />
        )}

        {questions.length - 1 !== currentIndex && (
          <Icon
            inverted
            name="chevron right"
            color={dark ? "violet" : "black"}
            size="big"
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              cursor: "pointer",
            }}
            disabled={!skippable && !answer.selected}
            onClick={() => {
              setCurrentIndex(currentIndex + 1);
            }}
          />
        )}

        {questions.length - 1 === currentIndex && (
          <Menu.Item style={{ padding: "0px 0px 0px 4px" }}>
            <Button
              size="large"
              color="blue"
              style={{
                padding: 8,
                position: "absolute",
                bottom: 10,
                right: 20,
                cursor: "pointer",
              }}
              disabled={!skippable && !answer.selected}
              onClick={() => onSubmit()}
            >
              Submit
            </Button>
          </Menu.Item>
        )}

        {currentAudioUrl && (
          <ReactAudioPlayer
            src={currentAudioUrl}
            autoPlay
            onEnded={() => setCurrentAudioUrl()}
          />
        )}
      </div>
    </QuestionsContainer>
  );
}

const QuestionsContainer = styled.div`
  .questions {
    background-color: #252d4a;
    width: 100%;
    /* min-height: 200px; */
    height: min-content;
    min-height: 268px;
    border-radius: 19px;
    padding: 20px;
    box-shadow: 0px 3px 9px 0px rgb(0 0 0 / 75%);
    /* display: flex;
    justify-content: space-evenly; */
    position: relative;
    padding-bottom: 50px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    &.light {
      background: white;
      box-shadow: 0px 2px 4px 0px rgb(130 135 138 / 75%);

      .answer-section {
        .answer {
          // color: #5a4e22;
          // background-color: #d59d82;
          color: #656563;
          border: 1px solid #7a7a7a
          background-color: #ffffff;
        }

        .selected:hover {
          background-color: #a59085;
        }

        .selected {
          color: #3b3b3a;
          background-color: #dac6bc;
        }

        .answer:hover {
          background-color: #a59085;
        }
      }

      .question-count {
        color: #585a5d;
      }
    }

    .question-description {
      min-height: 100px;
      font-size: 1.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #dfdfdf;
      padding: 10px;
      border-radius: 4px;
      color: #493d4a;
      background: #f1edeb;
    }

    .question-section {
      width: 100%;
      position: relative;
    }

    .question-count {
      margin-bottom: 20px;
      color: #c7d5e2;
    }

    .question-count span {
      font-size: 28px;
    }

    .question-text {
      margin-bottom: 12px;
    }

    .timer-text {
      background: rgb(230, 153, 12);
      padding: 15px;
      margin-top: 20px;
      margin-right: 20px;
      border: 5px solid rgb(255, 189, 67);
      border-radius: 15px;
      text-align: center;
    }

    /* ANSWERS/RIGHT SECTION */
    .answer-section {
      /* width: 100%; */
      display: flex;
      /* flex-direction: column; */
      justify-content: center;
      margin-top: 20px;
      flex-wrap: wrap;

      .answer {
        /* width: 100%; */
        font-size: 1.2rem;
        color: #ffffff;
        /* background-color: #252d4a; */
        border-radius: 4px;
        display: flex;
        padding: 8px;
        margin-left: 10px;
        border: 1px solid #5a4e22;
        /* justify-content: flex-start;
        text-align: left; */
        /* border: 2px solid #234668; */
        cursor: pointer;
        margin-bottom: 8px;

        background: #f1edeb;
        color: black;
        line-height: 1em;
        text-align: center;
        background: white;
        font-weight: bold;
      }

      .selected:hover {
        background-color: #555e7d;
        background-color: #b3a3ab;
        background-color: #dac6bc;
        color: #252d4a;
        color: #3b3b3a;
      }

      .incorrect {
        background-color: #ff3333;
      }

      .answer:hover {
        /* background-color: #555e7d; */
      }

      .answer:focus {
        outline: none;
      }

      .answer svg {
        margin-right: 5px;
      }
    }
  }
`;

const EarnPointMessage = styled.div`
  text-align: center;
  margin: 0px 0px 20px 0px;
  font-size: 25px;
  color: #515e6e;
  padding: 4px;

  .points {
    color: #96990d;
    font-weight: bold;
    margin: 0px 4px;
  }
`;

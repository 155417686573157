import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "app/cookie";
import { get, fetch, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "app/utils";

const slice = createSlice({
  name: "assignments",
  initialState: {
    assignment: {},
    assignments: [],
    getting: false,
    initialized: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    deleting: false,
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 8,
      sort: { subject: "asc", branch: "asc", start_date: "asc" },
      filter: {},
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingAssignment: (state) => {
      state.getting = true;
    },
    gotAssignment: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.assignment = action.payload;
    },
    fetchedAssignments: (state, action) => {
      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.assignments = action.payload;
    },
    fetchingAssignments: (state) => {
      state.fetching = true;
    },
    openingAssignmentForm: (state, action) => {
      state.open_form = true;
      state.assignment = action.payload;
    },
    closedAssignmentForm: (state) => {
      state.open_form = false;
      state.assignment = {};
      state.validations = {};
    },
    createdAssignment: (state, action) => {
      state.creating = false;
      state.open_form = false;
      state.validations = {};
      state.assignments = [...state.assignments, action.payload];
    },
    creatingAssignment: (state) => {
      state.creating = true;
    },
    updatedAssignment: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.validations = {};
      state.assignment = action.payload;
    },
    updatingAssignment: (state) => {
      state.updating = true;
    },
    deletedAssignment: (state, action) => {
      state.fetch = true;
      state.deleting = false;
      state.validations = {};
    },
    deletingAssignment: (state) => {
      state.deleting = true;
    },
    fetchedMeta: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.meta = action.payload;
      state.pages = action.payload.pages;
    },
    handleSetFilters: (state, action) => {
      state.fetch = true;
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  fetchedMeta,
  gotAssignment,
  gettingAssignment,
  openingAssignmentForm,
  closedAssignmentForm,
  fetchedAssignments,
  fetchingAssignments,
  createdAssignment,
  creatingAssignment,
  updatedAssignment,
  updatingAssignment,
  deletedAssignment,
  deletingAssignment,
  handleSetFilters,
  handleValidations,
} = slice.actions;

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const openAssignmentForm = (assignment = {}) => async (dispatch) => {
  dispatch(openingAssignmentForm(assignment));
};

export const closeAssignmentForm = () => async (dispatch) => {
  dispatch(closedAssignmentForm());
};

function getPath() {
  return getCookie("kids") ? "kids" : "";
}

export const getAssignment = (id) => async (dispatch, getState) => {
  try {
    dispatch(gettingAssignment());

    const state = getState();
    const { account_id } = state.me;

    const response = await get({
      endpoint: `/api/v1/assignments/${id}`,
    });
    const { assignment } = response.data;

    dispatch(gotAssignment(assignment));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchAssignments = (data) => async (dispatch, getState) => {
  try {
    dispatch(fetchingAssignments());

    const response = await fetch({
      endpoint: `/api/v1/assignments`,
      data,
    });
    const { meta, assignments } = response.data;

    dispatch(fetchedMeta(meta));
    dispatch(fetchedAssignments(assignments));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createAssignment = (data) => async (dispatch, getState) => {
  try {
    dispatch(creatingAssignment());

    const state = getState();
    const { account_id } = state.me;
    const response = await create({
      endpoint: `/api/v1/assignments`,
      data: { assignment: data },
    });
    const { assignment } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdAssignment(assignment));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateAssignment = (id, data) => async (dispatch, getState) => {
  try {
    dispatch(updatingAssignment());

    const state = getState();
    const { account_id } = state.me;
    const response = await update({
      endpoint: `/api/v1/assignments/${id}`,
      data: { assignment: data },
    });
    const { assignment } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedAssignment(assignment));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const deleteAssignment = (id, data) => async (dispatch, getState) => {
  try {
    dispatch(deletingAssignment());

    const state = getState();
    const { account_id } = state.me;
    const response = await destroy({
      endpoint: `/api/v1/assignments/${id}`,
      data: { assignment: data },
    });
    const { assignment } = response.data;

    toastDeleteSuccessMessage();
    dispatch(deletedAssignment(assignment));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const changeStatusAssignment = (id, status) => async (dispatch) => {
  try {
    dispatch(updatingAssignment());

    const response = await update({
      endpoint: `/api/v1/assignments/${id}/${status}`,
    });
    const { assignment } = response.data;

    dispatch(updatedAssignment(assignment));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "../utils";

const slice = createSlice({
  name: "gifts",
  initialState: {
    gift: { points: 0 },
    gifts: [],
    fetch: true,
    getting: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    destroying: false,
    initialized: false,
    open_form: false,
    open_reward_form: false,
    open_settings_form: false,
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 6,
      sort: { id: "asc" },
      filter: {},
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingGift: (state) => {
      state.getting = true;
    },
    gotGift: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.gift = action.payload;
    },
    fetchedGifts: (state, action) => {
      const { meta, gifts } = action.payload;

      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.pages = meta.pages;
      state.gifts = gifts;
    },
    fetchingGifts: (state) => {
      state.fetching = true;
    },
    createdGift: (state, action) => {
      state.fetch = true;
      state.creating = false;
      state.open_form = false;
      state.gift = action.payload;
      state.validations = {};
    },
    creatingGift: (state) => {
      state.creating = true;
    },
    updatedGift: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.gift = action.payload;
      state.validations = {};
    },
    updatingGift: (state) => {
      state.updating = true;
    },
    destroyingGift: (state) => {
      state.destroying = true;
    },
    destroyedGift: (state, action) => {
      state.fetch = true;
      state.destroying = false;
      state.validations = {};
      state.open_settings_form = false;
      state.gifts = [
        ...state.gifts.filter((gift) => gift.id !== action.payload.id),
      ];
    },
    earnedGift: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.gift = action.payload;
      state.validations = {};
    },
    openedGiftForm: (state, action) => {
      state.open_form = true;
      state.gift = action.payload;
    },
    closedGiftForm: (state) => {
      state.open_form = false;
      state.gift = { points: 0 };
      state.validations = {};
    },
    openedSettingsForm: (state, action) => {
      state.open_settings_form = true;
      state.gift = action.payload;
    },
    closedSettingsForm: (state) => {
      state.open_settings_form = false;
      state.gift = { points: 0 };
      state.validations = {};
    },
    openedForm: (state, action) => {
      state.open_form = true;
      state.gift = action.payload;
    },
    closedForm: (state) => {
      state.open_form = false;
      state.gift = { points: 0 };
      state.validations = {};
    },
    resettedGift: (state, action) => {
      state.gift = { points: 0 };
    },
    openedModalGift: (state, action) => {
      state.gift = action.payload;
      state.open_reward_form = true;
    },
    closedModalGift: (state, action) => {
      state.gift = { points: 0 };
      state.open_reward_form = false;
    },
    handleSetFilters: (state, action) => {
      state.fetch = true;
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotGift,
  openedForm,
  closedForm,
  resettedGift,
  gettingGift,
  fetchedGifts,
  fetchingGifts,
  createdGift,
  creatingGift,
  updatedGift,
  updatingGift,
  destroyedGift,
  destroyingGift,
  earnedGift,
  openedGiftForm,
  closedGiftForm,
  openedModalGift,
  closedModalGift,
  handleSetFilters,
  openedSettingsForm,
  closedSettingsForm,
  handleValidations,
} = slice.actions;

export const openForm = (gift = {}) => async (dispatch) => {
  dispatch(openedForm(gift));
};

export const closeForm = () => async (dispatch) => {
  dispatch(closedForm());
};

export const resetGift = () => async (dispatch) => {
  dispatch(resettedGift());
};

export const openGiftForm = (gift = {}) => async (dispatch) => {
  dispatch(openedGiftForm(gift));
};

export const closeGiftForm = () => async (dispatch) => {
  dispatch(closedGiftForm());
};

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const openSettingsForm = (gift = {}) => async (dispatch) => {
  dispatch(openedSettingsForm(gift));
};

export const closeSettingsForm = () => async (dispatch) => {
  dispatch(closedSettingsForm());
};

export const openModalGift = (gift) => async (dispatch) => {
  dispatch(openedModalGift(gift));
};

export const closeModalGift = () => async (dispatch) => {
  dispatch(closedModalGift());
};

export const getGift = (id) => async (dispatch) => {
  try {
    dispatch(gettingGift());

    const response = await get({
      endpoint: `/api/v1/gifts/${id}`,
    });
    const { gift } = response.data;

    dispatch(gotGift(gift));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchGifts = (data) => async (dispatch) => {
  try {
    dispatch(fetchingGifts());

    const response = await fetch({
      endpoint: `/api/v1/gifts`,
      data,
    });

    dispatch(fetchedGifts(response.data));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createGift = (data) => async (dispatch) => {
  try {
    dispatch(creatingGift());

    const response = await create({
      endpoint: `/api/v1/gifts`,
      data: { gift: data },
    });
    const { gift } = response.data;

    dispatch(createdGift(gift));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateGift = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingGift());

    const response = await update({
      endpoint: `/api/v1/gifts/${id}`,
      data: { gift: data },
    });
    const { gift } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedGift(gift));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const earnGift = (id) => async (dispatch) => {
  try {
    dispatch(updatingGift());

    const response = await update({
      endpoint: `/api/v1/gifts/${id}/earn`,
    });
    const { gift } = response.data;

    dispatch(earnedGift(gift));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const rewardGift = (id) => async (dispatch) => {
  try {
    dispatch(updatingGift());

    const response = await update({
      endpoint: `/api/v1/gifts/${id}/reward`,
    });
    const { gift } = response.data;

    dispatch(updatedGift(gift));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const destroyGift = (id, data) => async (dispatch) => {
  try {
    dispatch(destroyingGift());

    const response = await destroy({
      endpoint: `/api/v1/gifts/${id}`,
      data: { gift: data },
    });
    const { gift } = response.data;

    dispatch(destroyedGift(gift));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

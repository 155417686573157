import React, { useState, useEffect } from "react";

import { Container } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Kids from "./kids";
import Parents from "./parents";

import LoadingStatus from "app/components/Status";

export default function Main() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    user: { kid, account_type },
    logged_in,
    login_in_progress,
  } = useSelector((state) => state.me);
  const { initialized } = useSelector((state) => state.reports);

  useEffect(() => {
    if (login_in_progress) return;

    if (!logged_in) {
      navigate("/login");
    }
  }, [logged_in]);

  if (!logged_in || login_in_progress) return <LoadingStatus />;

  if (account_type == "master") {
    return <Container textAlign="center">Master Account</Container>;
  }

  if (account_type == "individual") {
    return <Container textAlign="center">Individual Account</Container>;
  }

  if (account_type == "enterprise") {
    return <Container textAlign="center">Enterprise Account</Container>;
  }

  return kid ? <Kids /> : <Parents />;
}

import axios from "axios";

import moment from "moment-timezone";
import { createSlice } from "@reduxjs/toolkit";

const start_date = moment().startOf("isoWeek").format("YYYY-MM-DD");
const end_date = moment().endOf("isoWeek").format("YYYY-MM-DD");

const slice = createSlice({
  name: "globals",
  initialState: {
    date_filters: {
      start_date,
      end_date,
    },
  },
  reducers: {
    settedDateFilters: (state, action) => {
      state.date_filters = action.payload;
    },
  },
});

export default slice.reducer;

const { settedDateFilters } = slice.actions;

export const setDateFilters = (data) => async (dispatch) => {
  dispatch(settedDateFilters(data));
};

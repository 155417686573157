import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get } from "app/api";

const slice = createSlice({
  name: "csrf_token",
  initialState: {
    csrf_token: "",
  },
  reducers: {
    gotCSRFToken: (state, action) => {
      state.csrf_token = action.payload;
    },
  },
});

const { gotCSRFToken } = slice.actions;

export const getCSRFToken = () => async (dispatch) => {
  try {
    const response = await get({
      endpoint: `/api/v1/csrf_token`,
    });
    const { token } = response.data;

    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    dispatch(gotCSRFToken(token));
  } catch (response) {}
};

export default slice.reducer;

import React, { useState, useEffect } from "react";

import Lottie from "react-lottie";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../../components/Loading";

import { acceptConfirmation } from "../../store/me";

export default function EmailConfirmation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const search = location.search;
  const params = new URLSearchParams(search);
  const confirmation_token = params.get("confirmation_token");

  const { user, login_needed } = useSelector((state) => state.me);

  useEffect(() => {
    if (confirmation_token) {
      dispatch(acceptConfirmation({ confirmation_token }));
    } else {
      navigate("/");
    }
  }, [confirmation_token]);

  useEffect(() => {
    if (login_needed) {
      navigate("/login");
    }
  }, [login_needed]);

  return <Loading />;
}

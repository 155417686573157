import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { get, create } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "tic_tacs",
  initialState: {
    tic_tac: {},
    fetched: false,
    fetching: false,
    created: false,
    creating: false,
    validations: {},
  },
  reducers: {
    gettingTicTac: (state) => {
      state.getting = true;
    },
    gotTicTac: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.fetched = true;
      state.tic_tac = action.payload;
    },
    movedTicTac: (state, action) => {
      state.creating = false;
      state.created = true;
      state.validations = {};
    },
    movingTicTac: (state) => {
      state.creating = true;
    },
    resettedTicTac: (state) => {
      state.tic_tac = {};
      state.fetched = false;
      state.fetching = false;
      state.created = false;
      state.creating = false;
      state.validations = {};
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.creating = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotTicTac,
  gettingTicTac,
  movedTicTac,
  movingTicTac,
  resettedTicTac,
  handleValidations,
} = slice.actions;

export const resetTicTac = () => async (dispatch) => {
  dispatch(resettedTicTac());
};

export const getTicTac = (id) => async (dispatch) => {
  try {
    dispatch(gettingTicTac());

    const response = await get({
      endpoint: `/api/v1/tic_tacs/${id}`,
    });
    const { tic_tac } = response.data;

    dispatch(gotTicTac(tic_tac));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const moveTicTac = (id, data) => async (dispatch) => {
  try {
    dispatch(movingTicTac());

    const response = await create({
      endpoint: `/api/v1/tic_tacs/${id}/moves`,
      data,
    });
    const { tic_tac } = response.data;

    dispatch(gotTicTac(tic_tac));
    dispatch(movedTicTac());
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

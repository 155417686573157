import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastSuccessMessage,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "app/utils";

const slice = createSlice({
  name: "subscriptions",
  initialState: {
    subscription: {},
    subscriptions: [],
    bySubscriptionId: {},
    meta: {},
    fetch: true,
    getting: false,
    fetch: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    destroying: false,
    initialized: false,
    open_form: false,
    open_settings_form: false,
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 4,
      sort: { rank: "asc", id: "asc" },
      filter: {},
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingSubscription: (state) => {
      state.getting = true;
    },
    gotSubscription: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.subscription = action.payload;
    },
    fetchedSubscriptions: (state, action) => {
      const { subscriptions, meta } = action.payload;

      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.subscriptions = subscriptions;
      state.meta = meta;
      state.bySubscriptionId = subscriptions.reduce((previous, current) => {
        previous[current.id] = current;
        return previous;
      }, {});
    },
    fetchingSubscriptions: (state) => {
      state.fetching = true;
    },
    openingSubscriptionForm: (state, action) => {
      state.open_form = true;
      state.subscription = action.payload;
      state.validations = {};
    },
    closedSubscriptionForm: (state) => {
      state.open_form = false;
      state.subscription = {};
      state.validations = {};
    },
    openingSettingsForm: (state, action) => {
      state.validations = {};
      state.subscription = action.payload;
      state.open_settings_form = true;
    },
    closedSettingsForm: (state) => {
      state.validations = {};
      state.open_settings_form = false;
    },
    createdSubscription: (state, action) => {
      state.fetch = true;
      state.creating = false;
      state.open_form = false;
      state.validations = {};
      state.subscription = action.payload;
      state.subscriptions = [...state.subscriptions, action.payload];
    },
    creatingSubscription: (state) => {
      state.creating = true;
    },
    updatedSubscription: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.validations = {};
      state.subscription = action.payload;
      state.subscriptions = [
        ...state.subscriptions.filter(
          (subscription) => subscription.id !== action.payload.id
        ),
        action.payload,
      ];
    },
    updatingSubscription: (state) => {
      state.updating = true;
    },
    destroyedSubscription: (state, action) => {
      const subscriptions = [
        ...state.subscriptions.filter(
          (subscription) => subscription.id !== action.payload.id
        ),
      ];
      state.fetch = true;
      state.destroying = false;
      state.validations = {};
      state.open_settings_form = false;
      state.subscriptions = subscriptions;

      const { filters } = state;
      const isEmptyList = subscriptions.length === 0;

      if (isEmptyList && filters.page > 1) {
        state.filters = { ...filters, page: filters.page - 1 };
      }
    },
    destroyingSubscription: (state) => {
      state.destroying = true;
    },
    refetch: (state, action) => {
      state.fetch = true;
      state.destroying = false;
      state.subscription = action.payload;
    },
    fetchedMeta: (state, action) => {
      state.meta = action.payload;
      state.pages = action.payload.pages;
    },
    handleSetFilters: (state, action) => {
      state.fetch = true;
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.creating = false;
      state.validations = action.payload;
    },
    handleUnexpectedErrors: (state) => {
      state.fetching = false;
      state.creating = false;
      state.validations = {};
    },
  },
});

export default slice.reducer;

const {
  gotSubscription,
  gettingSubscription,
  openingSubscriptionForm,
  closedSubscriptionForm,
  openingSettingsForm,
  closedSettingsForm,
  fetchedSubscriptions,
  fetchingSubscriptions,
  createdSubscription,
  creatingSubscription,
  updatedSubscription,
  updatingSubscription,
  destroyedSubscription,
  destroyingSubscription,
  refetch,
  fetchedMeta,
  handleSetFilters,
  handleValidations,
  handleUnexpectedErrors,
} = slice.actions;

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const openForm = (subscription = {}) => async (dispatch) => {
  dispatch(openingSubscriptionForm(subscription));
};

export const closeForm = () => async (dispatch) => {
  dispatch(closedSubscriptionForm());
};

export const openSettingsForm = (subscription = {}) => async (dispatch) => {
  dispatch(openingSettingsForm(subscription));
};

export const closeSettingsForm = () => async (dispatch) => {
  dispatch(closedSettingsForm());
};

export const getSubscription = (id) => async (dispatch) => {
  try {
    dispatch(gettingSubscription());

    const response = await get({
      endpoint: `/api/v1/subscriptions/${id}`,
    });
    const { subscription } = response.data;

    dispatch(gotSubscription(subscription));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchSubscriptions = (data) => async (dispatch) => {
  try {
    dispatch(fetchingSubscriptions());

    const response = await fetch({
      endpoint: `/api/v1/subscriptions`,
      data,
    });
    const { subscriptions, meta } = response.data;

    dispatch(fetchedMeta(meta));
    dispatch(fetchedSubscriptions({ subscriptions: subscriptions, meta }));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createSubscription = (data) => async (dispatch) => {
  try {
    dispatch(creatingSubscription());

    const response = await create({
      endpoint: `/api/v1/subscriptions`,
      data: { subscription: data },
    });
    const { subscription } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdSubscription(subscription));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) {
      dispatch(handleValidations(validations));
    } else {
      dispatch(handleUnexpectedErrors());
    }
  }
};

export const updateSubscription = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingSubscription());

    const response = await update({
      endpoint: `/api/v1/subscriptions/${id}`,
      data: { subscription: data },
    });
    const { subscription } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedSubscription(subscription));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) {
      dispatch(handleValidations(validations));
    } else {
      dispatch(handleUnexpectedErrors());
    }
  }
};

export const destroySubscription = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingSubscription());

    const response = await destroy({
      endpoint: `/api/v1/subscriptions/${id}`,
    });
    const { subscription } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedSubscription(subscription));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) {
      dispatch(handleValidations(validations));
    } else {
      dispatch(handleUnexpectedErrors());
    }
  }
};

export const activateSubscription = (id, data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: `/api/v1/subscriptions/${id}/activate`,
      data: { subscription: data },
    });
    const { subscription } = response.data;

    dispatch(refetch(subscription));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const resumeSubscription = (id, data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: `/api/v1/subscriptions/${id}/resume`,
      data: { subscription: data },
    });
    const { subscription } = response.data;

    dispatch(refetch(subscription));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) {
      dispatch(handleValidations(validations));
    } else {
      dispatch(handleUnexpectedErrors());
    }
  }
};

export const cancelSubscription = (id, data) => async (dispatch) => {
  try {
    dispatch(destroyingSubscription());

    const response = await update({
      endpoint: `/api/v1/subscriptions/${id}/cancel`,
      data: { subscription: data },
    });
    const { subscription } = response.data;

    toastSuccessMessage();
    dispatch(refetch(subscription));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

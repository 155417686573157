import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { create } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "setup_intents",
  initialState: {
    setup_intent: { client_secret: null },
    creating: false,
    validations: {},
  },
  reducers: {
    createdSetupIntent: (state, action) => {
      state.creating = false;
      state.setup_intent = action.payload;
    },
    handledSetupIntent: (state, action) => {
      state.creating = false;
      state.setup_intent = action.payload;
    },
    handleValidations: (state, action) => {
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  createdSetupIntent,
  handledSetupIntent,
  handleValidations,
} = slice.actions;

export const createSetupIntent = () => async (dispatch, getState) => {
  try {
    const response = await create({
      endpoint: `/api/v1/setup_intents`,
    });
    const { setup_intent } = response.data;

    dispatch(createdSetupIntent(setup_intent));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const attachSetupIntent = (id) => async (dispatch, getState) => {
  try {
    const response = await create({
      endpoint: `/api/v1/setup_intents/${id}/attach`,
    });
    const { setup_intent } = response.data;

    dispatch(handledSetupIntent(setup_intent));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import React, { useState, useEffect } from "react";

import { Container } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Status from "./Status";
import Dashboard from "./Dashboard";

export default function Main() {
  return (
    <>
      <Status />
      <Dashboard />
    </>
  );
}

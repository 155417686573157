import React, { useState, useEffect } from "react";

import I18n from "i18n-js";
import { useSelector, useDispatch } from "react-redux";
import { Header, Container, Accordion, Grid, Icon } from "semantic-ui-react";

import Loading from "app/components/Loading";

export default function TermsPage(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.me);

  return (
    <Container style={{ margin: 20, fontSize: 18 }}>
      <Header as="h2" icon textAlign="center" style={{ marginBottom: 20 }}>
        <Header.Content
          style={{
            margin: "25px 0px",
            fontSize: "28px",
            color: "#63666b",
          }}
        >
          {I18n.t("terms")}
        </Header.Content>
      </Header>
      <Grid>
        <div style={{ marginBottom: 20 }}>
          <h3>This privacy policy was last updated: January 21, 2022</h3>
          <p>
            We at Nunoin Inc. (“Clevers”, “we”, “us” or “our”) are committed to
            protecting your privacy and would like to inform you on how we
            collect and protect your information to provide better user
            experience. This Privacy Policy describes our practices regarding
            your data. Please thoroughly read this Privacy Policy. Using Clevers
            services means you agree to our usage of your information. If you do
            not consent, discontinue use of Clevers services.
          </p>
          <h4>
            Table of Contents:
            <ul>
              <li>Children’s Safety Guarantee</li>
              <li>Types of Accounts and Users</li>
              <li>Data Collection</li>
              <li>Data Collection: Child Accounts</li>
              <li>Data Usage</li>
              <li>Data Usage: Child Accounts</li>
              <li>Data Disclosure</li>
              <li>Your Rights/Choices</li>
              <li>Additional California Resident Policies</li>
              <li>International Transfer</li>
              <li>Changes to the Privacy Policy</li>
            </ul>
          </h4>

          <h3>Children’s Safety Guarantee</h3>
          <p>
            Our services are backed by our Child Safe Guarantee. Your child’s
            accounts will never include any pop-up ads or ads directed towards
            children.
          </p>
          <h3>Types of Accounts and Users</h3>
          <p>
            How we collect, use and share information depends on the type of
            Account and user. Currently, we have adult and child accounts.{" "}
          </p>
          <h3>Data Collection</h3>
          <p>
            You can browse the site without providing personal information.
            However, in order to register you need to provide some personal
            information. You may choose to voluntarily provide additional
            information that we do not require, and in those situations you are
            responsible for the information.
          </p>
          <p>
            There are two types of data we mainly collect: direct and indirect.
            Direct information is data you provide for us (contact data, account
            credentials, demographic data, billing data, profile data, etc).
            This may include personal information such as your name, address and
            phone number. Indirect information is data that is collected
            automatically (service use data, device data, cookies, etc).
          </p>
          <h3>Data Collection: Child Accounts</h3>
          <p>
            Child Accounts are monitored by Adult accounts and collect free-form
            content such as photos, audio/video files and artwork. We encourage
            Adults to monitor all content saved by Child Users.
          </p>
          <h3>Data Usage</h3>
          <p>
            We collect your data for a multitude of reasons. These include
            operating, maintaining and providing you with optimal functionality
            as well as communicating with you, troubleshooting issues and
            improving our services.
          </p>
          <h4>We use your data to:</h4>
          <ul>
            <li>Providing optimal service</li>
            <li>
              Perform service requested by you (responding to requests, customer
              service, etc)
            </li>
            <li>Personalize your experience</li>
            <li>Process payments</li>
            <li>prevent/address fraud or breach of policy</li>
            <li>Conduct research</li>
            <li>Monitor trends/usage</li>
            <li>Manage your account</li>
            <li>
              Fulfill any other business/commercial purposes with your
              notice/consent
            </li>
          </ul>

          <h3>Data Usage: Child Accounts</h3>
          <h4>Information collected from Child Accounts can be used to:</h4>
          <ul>
            <li>
              Measure child’s performance in activities and adapt to their
              skillset
            </li>
            <li>Analyze and provide progress reports on their performance</li>
            <li>To assess and improve services</li>
            <li>Personalize to their experience</li>
            <li>Maintain the function of the service</li>
            <li>View via correlated Adult Accounts</li>
            <li>As we believe it to be appropriate</li>
          </ul>

          <h3>Data Disclosure:</h3>
          <p>
            Clevers takes great care to protect your personal information. We do
            not sell your information to third parties. This section explains in
            which circumstances we may share your data.
          </p>
          <h4>We may share your personal information:</h4>
          <ul>
            <li>
              To Service Providers: we share information with companies that
              process information on our company’s behalf. They assist us with
              tasks such as payment processing, website hosting, data analytics,
              marketing and more.
            </li>
            <li>
              To Vendors and Other Parties: we share information with vendors
              and other parties for business/commercial purposes. Vendors may
              act as service providers.
            </li>
            <li>
              To Affiliates: We share your data with our corporate family that
              are in the common branch of Clevers.
            </li>
            <li>
              To Partners: certain agreements with other platforms require your
              data to distribute our services.
            </li>
            <li>
              With Consent: We share your data with notice to you or with your
              consent.
            </li>
          </ul>
          <p>
            We may also share de-identified or aggregated information via which
            one cannot reasonably identify an individual.
          </p>

          <h3>Your Rights/Choices</h3>
          <p>
            You have rights and choices with information that relates to you,
            rights and choices which vary depending on the type of information.
          </p>
          <h4>This section further details your rights regarding:</h4>
          <ul>
            <li>
              Your account information: You may access, update, or remove any of
              your provided information via your account settings. We will keep
              your information as required due to legal obligations and to
              enforce agreements. California residents have additional rights
              further detailed in a later section called “Disclosure for
              California Residents”
            </li>
            <li>
              Tracking technology: You may consent/accept certain tracking
              technologies such as cookies and pixels, do not track settings and
              app and location technologies. Please note that disabling tracking
              technologies may result in dysfunction of certain services.
            </li>
            <li>
              Analytics and Advertising: The companies we work with provide
              targeted ads we give to you. Most of these companies are part of
              the DAA (Digital Advertising Alliance) or NAI (Network Advertising
              Initiative) Links to opt out of certain targeted ads:
            </li>
            <div>
              <a href="https://www.aboutads.info/choices">
                https://www.aboutads.info/choices
              </a>
            </div>
            <div>
              <a href="https://www.aboutads.info/appchoices">
                https://www.aboutads.info/appchoices
              </a>
            </div>
            <div>
              <a href="https://www.networkadvertising.org/choices/">
                https://www.networkadvertising.org/choices
              </a>
            </div>
            <li>
              Communications: We mainly use emails and push notifications. You
              may opt out of promotional emails at any time by following the
              instructions in said emails. If you have consented to allow push
              notifications, you may turn them off any time in your browser
              settings.
            </li>
          </ul>

          <h3>Additional California Resident Policies</h3>
          <h4>
            California Resident Users have additional rights under the CCPA
            (California Consumer Privacy Act). If you are an eligible user, you
            have the:
          </h4>
          <ul>
            <li>
              Right to Know: right to request more about the categories and
              specific pieces of information that we have collected about you
              and access to a copy of your personal information.
            </li>
            <li>
              Right to Correction: right to your inaccurate personal data
              corrected.
            </li>
            <li>
              Right to Deletion: right to request deletion of personal data that
              we have collected about you.
            </li>
            <li>
              Right to Non-Discrimination: if you choose to exercise any of your
              rights under the CCPA, Clevers will respect you the same way we
              respect all users. There is no penalty for exercising your rights
              under the CCPA.
            </li>
          </ul>

          <h3>International Transfer</h3>
          <p>
            Clevers is based in the United States and the information we collect
            is governed by US law. If you are accessing our service from outside
            US borders, please note that information collected via the service
            may be transferred to, processed, stored and used in the US and
            other jurisdictions. Data protection laws may be different than in
            your area of residence. Therefore, use of services or providing any
            information constitutes your consent to the transfer to and from,
            processing, sharing and storage of information about you in the US
            and other jurisdictions as set in this Privacy Policy.
          </p>
          <h3>Changes to the Privacy Policy</h3>
          <p>
            Clevers has the right to modify or update this Privacy Policy to
            reflect the changes in our business and practices. Please note this
            may mean it could be helpful to review this page periodically. When
            the Privacy Policy is changed, the “last updated” date is modified
            at the top of the Privacy Policy.
          </p>
        </div>
      </Grid>
    </Container>
  );
}

import React, { useState } from "react";

import Lottie from "react-lottie";
import QrReader from "react-qr-scanner";
import { Modal } from "semantic-ui-react";

import loadingLottie from "../assets/lottie/loading.json";

export default function QrCode(props) {
  const { style, onUrl, onClose } = props;
  const [loading, setLoading] = useState(true);

  return (
    <Modal
      open
      size="tiny"
      style={{ width: 420, background: "black" }}
      onClose={onClose}
    >
      {loading && (
        <Lottie
          speed={1}
          isClickToPauseDisabled
          options={{
            loop: true,
            autoplay: true,
            animationData: loadingLottie,
          }}
          height={320}
          width={420}
          isStopped={false}
          isPaused={false}
        />
      )}
      <QrReader
        delay={{
          delay: 100,
          result: "No result",
        }}
        style={{
          height: 320,
          width: 420,
          display: loading ? "none" : "block",
        }}
        onLoad={() => setLoading(false)}
        onError={(err) => console.log(err)}
        onScan={(data) => {
          if (data && data["text"]) {
            onUrl(data["text"]);
          } else {
            console.log("no data", data);
          }
        }}
      />
    </Modal>
  );
}

import React from "react";

import styled from "styled-components";
import { Button } from "semantic-ui-react";

export default function Start(props) {
  const { loading = false, size = 400, onStart } = props;

  return (
    <Wrapper style={{ width: size, height: size }}>
      <Button
        compact
        color="teal"
        size="massive"
        content="Start"
        loading={loading}
        onClick={onStart}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  margin: auto auto;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #d6dbe5;
`;

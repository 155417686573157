import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Message,
  Header,
  List,
  Statistic,
  Container,
} from "semantic-ui-react";

import Status from "./Status";
import EmptyLottieView from "app/components/EmptyLottieView";

import { getMe } from "app/store/me";
import { getTask, startTask, completeTask } from "app/store/tasks";

import evaluatingLottie from "app/assets/lottie/scan-barcode.json";

export default function UserChessChallenges() {
  let { slug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mobile = searchParams.get("mobile");

  const {
    user: { locale, level },
  } = useSelector((state) => state.me);

  const { task } = useSelector((state) => state.tasks);
  const { permissions } = useSelector((state) => state.settings);
  const { update_task_status_allowed } = permissions;

  useEffect(() => {
    dispatch(getTask(slug));
  }, [slug]);

  useEffect(() => {
    if (!task) return;

    if (task.status === "completed") {
      dispatch(getMe());
    }
  }, [task]);

  function submitTask() {
    dispatch(completeTask(slug));
  }

  function getScreen() {
    const {
      id,
      title,
      description,
      links,
      status,
      points,
      timespent_in_words,
    } = task;

    if (status === "completed") {
      return (
        <>
          <Container style={{ alignItems: "center" }}>
            <Stats>
              <Statistic size="huge">
                <Statistic.Value text>
                  You
                  <br />
                  Earned
                </Statistic.Value>
              </Statistic>
              <Statistic size="huge" style={{ margin: 0 }} color="blue">
                <Statistic.Value text>{points} Points</Statistic.Value>
              </Statistic>
              <Statistic size="small" style={{ margin: 0 }} color="orange">
                <Statistic.Value text>{timespent_in_words}</Statistic.Value>
              </Statistic>
              <Button color="black" onClick={() => navigate("/")}>
                Continue
              </Button>
            </Stats>
          </Container>
        </>
      );
    } else if (status == "completed") {
      return (
        <EmptyLottieView
          title="Evaluation is in progress..."
          animation={evaluatingLottie}
          speed={0.4}
          options={{
            loop: true,
            autoplay: true,
          }}
          style={{ width: "45%", marginBottom: 40, marginTop: -80 }}
        />
      );
    } else {
      return (
        <>
          <EarnPointMessage>
            <span>{I18n.t("you_will_earn")}</span>
            <span className="points">{points}</span>
            <span>{I18n.t("points")}</span>
          </EarnPointMessage>
          <TaskDetails>
            <Header as="h1" style={{ textAlign: "center" }}>
              {title}
            </Header>

            {status == "pending" && (
              <Button
                color="blue"
                size="massive"
                compact
                onClick={() => dispatch(startTask(slug))}
              >
                Start
              </Button>
            )}

            {status == "started" && (
              <>
                <div style={{ lineHeight: 1.2 }}>{description}</div>

                <List divided verticalAlign="middle">
                  {links.map((link, i) => {
                    return (
                      <List.Item key={`${link}`}>
                        <List.Content floated="right">
                          <Button
                            compact
                            onClick={() => {
                              window.open(link, "_blank");
                            }}
                          >
                            Visit
                          </Button>
                        </List.Content>
                        <List.Content>
                          <List.Header
                            as="a"
                            style={{
                              wordBreak: "break-all",
                              textAlign: "left",
                            }}
                            onClick={() => {
                              window.open(link, "_blank");
                            }}
                          >
                            {link}
                          </List.Header>
                        </List.Content>
                      </List.Item>
                    );
                  })}
                </List>
              </>
            )}
          </TaskDetails>
        </>
      );
    }
  }

  const { status, timespent } = task;

  return (
    <>
      <Status status={status} timespent={timespent} onSubmit={submitTask} />
      {getScreen()}
    </>
  );
}

const Stats = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 167px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const EarnPointMessage = styled.div`
  text-align: center;
  margin: 20px 20px 60px 20px;
  font-size: 25px;
  color: #515e6e;

  .points {
    color: #96990d;
    font-weight: bold;
    margin: 0px 4px;
  }
`;

const TaskDetails = styled.div`
  margin: 20px;
  font-size: 25px;
  color: #515e6e;
  max-width: 650px;
  margin: auto auto;
  background: white;
  padding: 24px;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0px 0px 4px #e9e9e9;
`;

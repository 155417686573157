import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update } from "app/api";
import { handleActionResponse, toastCreateSuccessMessage } from "app/utils";

const slice = createSlice({
  name: "chess",
  initialState: {
    chess: {},
    chesses: [],
    chess_games: [],
    chess_queues: [],
    success: false,
    creating: false,
    created: false,
    validations: {},
  },
  reducers: {
    gotChess: (state, action) => {
      state.validations = {};
      state.chess = action.payload;
    },
    fetchedChesses: (state, action) => {
      state.validations = {};
      state.chesses = action.payload;
    },

    createdChess: (state, action) => {
      state.created = true;
      state.creating = false;
      state.validations = {};
      state.chess = action.payload;
    },
    creatingChess: (state, action) => {
      state.created = false;
      state.creating = true;
      state.validations = {};
      state.chess = action.payload;
    },
    gotChessGames: (state, action) => {
      state.chess_games = action.payload;
    },
    gotChessQueues: (state, action) => {
      state.chess_queues = action.payload;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotChess,
  createdChess,
  creatingChess,
  gotChessGames,
  fetchedChesses,
  gotChessQueues,
  handleValidations,
} = slice.actions;

export const fetchChesses = (data) => async (dispatch) => {
  try {
    const response = await get({
      endpoint: "/api/v1/chesses",
      data,
    });
    const { chesses } = response.data;

    dispatch(fetchedChesses(chesses));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const getChess = (id, data) => async (dispatch) => {
  try {
    const response = await get({
      endpoint: `/api/v1/chesses/${id}`,
      data: { chess: data },
    });
    const { chess } = response.data;

    dispatch(gotChess(chess));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createChess = (data) => async (dispatch) => {
  try {
    dispatch(creatingChess());

    const response = await create({
      endpoint: "/api/v1/chesses",
      data: { chess: data },
    });
    const { chess } = response.data;

    dispatch(createdChess(chess));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const getChessQueues = (chess_id) => async (dispatch) => {
  try {
    const response = await fetch({
      endpoint: "/api/v1/chess_queues",
      data: { chess_id },
    });
    const { chess_queues } = response.data;

    dispatch(gotChessQueues(chess_queues));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createChessQueues = (chess_id) => async (dispatch) => {
  try {
    const response = await create({
      endpoint: "/api/v1/chess_queues",
      data: { chess_id },
    });
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const getChessGames = (chess_id) => async (dispatch) => {
  try {
    const response = await fetch({
      endpoint: "/api/v1/chess_games",
      data: { chess_id },
    });
    const { chess_games } = response.data;

    dispatch(gotChessGames(chess_games));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createChessGame = (chess_id) => async (dispatch) => {
  try {
    const response = await create({
      endpoint: "/api/v1/chess_games",
      data: { chess_id },
    });
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateChessGame = (id, data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: `/api/v1/chess_games/${id}`,
      data,
    });
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const joinChess = (id) => async (dispatch) => {
  try {
    const response = await create({
      endpoint: `/api/v1/chesses/${id}/join`,
    });
    const { chess } = response.data;

    dispatch(gotChess(chess));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import React, { useRef, useState, useEffect } from "react";

import I18n from "i18n-js";
import Switch from "react-switch";
import ReactPlayer from "react-player";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  Grid,
  Icon,
  List,
  Header,
  Button,
  Message,
  Accordion,
  Statistic,
  Container,
} from "semantic-ui-react";

import Questions from "./Questions";
import Start from "app/components/shared/Start";
import Status from "app/components/shared/Status";
import SmallKidsQuestions from "./SmallKidsQuestions";
import Loading from "app/components/Loading";
import EvaluationMessage from "../../components/EvaluationMessage";

import quizCompletedLottie from "app/assets/lottie/quiz-completed.json";

import { getTask, startTask, completeTask } from "app/store/tasks";
import {
  getUserQuiz,
  updateUserQuiz,
  startUserQuiz,
  completeUserQuiz,
  resetUserQuiz,
} from "app/store/user_quizzes";

import { getMe } from "app/store/me";
import { getPreference, updatePreference } from "app/store/preference";
import { fetchVoiceFeedbacks } from "app/store/voice_feedbacks";

export default function UserQuizzes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { slug, quiz_slug } = useParams();

  const playerRef = useRef(null);
  const [answers, setAnswers] = useState({});
  const [questions, setQuestions] = useState([]);

  const [dark, setDark] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const {
    user: { locale, level },
  } = useSelector((state) => state.me);
  const { mobile } = useSelector((state) => state.main);
  const {
    preference: { dark_mode },
  } = useSelector((state) => state.preference);

  const { task } = useSelector((state) => state.tasks);
  const { user_quiz } = useSelector((state) => state.user_quizzes);

  const smallKids =
    level === "tk" || level === "kindergarten" || level === "grade_one";
  useEffect(() => {
    dispatch(fetchVoiceFeedbacks());
  }, [locale]);

  useEffect(() => {
    dispatch(getTask(slug));
  }, [slug]);

  useEffect(() => {
    setDark(dark_mode);
  }, [dark_mode]);

  useEffect(() => {
    dispatch(getUserQuiz(quiz_slug));
  }, [slug, locale, quiz_slug]);

  useEffect(() => {
    if (!user_quiz) return;

    const { status } = task;
    const { evaluation_status } = user_quiz;

    if (status === "completed" && evaluation_status !== "completed") {
      setTimeout(() => {
        dispatch(getUserQuiz(quiz_slug));
      }, 2000);
    }

    if (evaluation_status === "completed") {
      dispatch(getMe());
    }
  }, [task, quiz_slug, user_quiz]);

  useEffect(() => {
    dispatch(getPreference());

    return () => {
      setAnswers({});
      setQuestions([]);

      dispatch(resetUserQuiz());
    };
  }, []);

  useEffect(() => {
    if (!user_quiz) {
      return;
    }
    const { status, answers, questions } = user_quiz;

    // if (status === "pending") {
    //   dispatch(
    //     updateUserQuiz(quiz_slug, {
    //       status: "started",
    //     })
    //   );
    // }

    setAnswers(answers);
    setQuestions(questions);
  }, [user_quiz]);

  useEffect(() => {
    if (questions.length === 0) {
      return;
    }
    if (user_quiz && user_quiz.status === "completed") {
      return;
    }

    // if (questions.length === Object.keys(answers).length) {
    //   dispatch(
    //     updateUserQuiz(quiz_slug, {
    //       status: "completed",
    //     })
    //   );
    // }
  }, [user_quiz, answers, questions]);

  if (!user_quiz || questions.length === 0) {
    return null;
  }

  const { status, timespent, timespent_in_words } = task;
  const {
    points,
    stats,
    skippable,
    help_enabled,
    evaluation_status,
    instant_feedback,
  } = user_quiz;

  if (
    status === "pending" ||
    status === "completed" ||
    evaluation_status === "completed"
  ) {
    return (
      <EvaluationMessage
        stats={stats}
        points={points}
        status={status}
        timespent={timespent}
        start_timer={status === "started"}
        evaluation_status={evaluation_status}
        timespent_in_words={timespent_in_words}
        onStart={() => dispatch(startTask(slug))}
        onContinue={() => {
          if (mobile) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ action: "finished" })
            );
          } else {
            navigate("/");
          }
        }}
      />
    );
  }

  return (
    <QuestionStyles>
      <Status
        status={status}
        timespent={timespent}
        start_timer={status === "started"}
      />
      <Container style={{ marginTop: 40 }}>
        <Grid style={{ position: "relative" }}>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <SmallKidsQuestions
              dark={dark}
              locale={locale}
              points={points}
              quiz_slug={quiz_slug}
              answers={answers}
              questions={questions}
              skippable={skippable}
              help_enabled={help_enabled}
              instant_feedback={instant_feedback}
              onSubmit={() => dispatch(completeTask(slug))}
            />
          </Grid.Column>
        </Grid>
      </Container>
    </QuestionStyles>
  );
}

const Stats = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 167px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const EarnPointMessage = styled.div`
  text-align: center;
  margin: 20px;
  font-size: 25px;
  color: #515e6e;

  .points {
    color: #96990d;
    font-weight: bold;
    margin: 0px 4px;
  }
`;

const PlayerStyles = styled.div`
  video {
    width: 100%;
    height: auto;
    background: white;
    box-shadow: 0px 0px 17px 8px #efefefd1;
    border-radius: 4px;
  }
`;

const QuestionStyles = styled.div``;

import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";

export default function QuestionDescription(props) {
  const { text } = props;

  return <Description>{text}</Description>;
}

const Description = styled.div`
  font-size: 32px;
  line-height: 1;
  text-align: center;
`;

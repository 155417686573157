import React, { useEffect, useState } from "react";

import I18n from "i18n-js";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import { Button, Menu, Segment } from "semantic-ui-react";

import Gifts from "app/components/Gifts";

import { selectKid } from "app/store/kids";
import { setFilters } from "app/store/reports";
import { setDateFilters } from "app/store/globals";

export default function Status(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const today = moment();

  const { date_filters } = useSelector((state) => state.globals);

  const start_date = moment(date_filters.start_date);
  const end_date = moment(date_filters.end_date);
  const [dateRange, onChange] = useState([start_date, end_date]);

  const {
    user: { locale },
    logged_in,
  } = useSelector((state) => state.me);
  const { selectedKid } = useSelector((state) => state.kids);

  const {
    kid,
    kids,
    initialized,
    fetch,
    pages,
    filters,
    fetching,
  } = useSelector((state) => state.kids);

  useEffect(() => {
    if (selectedKid || kids.length == 0) return;

    dispatch(selectKid(kids[0]));
  }, [kids]);

  useEffect(() => {
    const { start_date, end_date } = date_filters;

    if (!start_date || !end_date) return;

    dispatch(
      setFilters({
        start_date,
        end_date,
      })
    );
  }, [date_filters]);

  useEffect(() => {
    const start_date = moment(dateRange[0]).format("YYYY-MM-DD");
    const end_date = moment(dateRange[1]).format("YYYY-MM-DD");

    dispatch(
      setDateFilters({
        start_date,
        end_date,
      })
    );
  }, [dateRange]);

  return (
    <Segment
      style={{
        margin: 0,
        padding: 0,
        position: "sticky",
        top: 0,
        zIndex: 10,
      }}
    >
      <Menu secondary>
        {kids.map((kid) => {
          const { id, short_display_name } = kid;

          return (
            <Menu.Item
              key={id}
              style={{ padding: "0px 0px 0px 2px", margin: "2px 0px 2px 6px" }}
            >
              <Button
                basic={selectedKid && id !== selectedKid.id}
                color="blue"
                compact
                style={{ padding: 8 }}
                onClick={() => dispatch(selectKid(kid))}
              >
                {short_display_name}
              </Button>
            </Menu.Item>
          );
        })}

        <Menu.Item style={{ padding: "0px 0px 0px 4px" }}>
          <Gifts />
        </Menu.Item>

        <Menu.Menu position="right">
          <Menu.Item style={{ padding: "0px 0px 0px 4px" }}>
            <DateRangePicker
              locale={locale}
              onChange={onChange}
              value={dateRange}
              clearIcon={null}
            />
          </Menu.Item>

          <Menu.Item style={{ padding: "0px 0px 0px 4px" }}>
            <Button
              color="blue"
              compact
              style={{ padding: 8 }}
              onClick={() => {
                navigate("/account_settings/tasks");
              }}
            >
              Manage Tasks
            </Button>
          </Menu.Item>
          <Menu.Item style={{ padding: "0px 0px 0px 10px", marginRight: 10 }}>
            <Button
              color="teal"
              compact
              style={{ padding: 8 }}
              onClick={() => {
                navigate("/account_settings/family");
              }}
            >
              Manage Kids
            </Button>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </Segment>
  );
}

import React, { useState, useEffect } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  Tab,
  List,
  Grid,
  Button,
  Header,
  Container,
  Placeholder,
  Pagination,
} from "semantic-ui-react";

import "react-datepicker/dist/react-datepicker.css";

import Loading from "app/components/Loading";
import EmptyLottieView from "app/components/EmptyLottieView";
import { GridPagination, GridContainer } from "app/styles";

import { setFilters, fetchTasks, resetFetchTasks } from "app/store/tasks";

import taskLottie from "app/assets/lottie/tasks.json";

export default function Tasks(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    user: { locale },
  } = useSelector((state) => state.me);
  const { date_filters } = useSelector((state) => state.globals);

  const { fetch, fetching, tasks, pages, filters, initialized } = useSelector(
    (state) => state.tasks
  );
  const { permissions } = useSelector((state) => state.settings);
  const { update_task_status_allowed } = permissions;

  const { page, per_page, sort } = filters;
  const {
    current_page,
    next_page,
    prev_page,
    total_count,
    total_pages,
  } = pages;
  const showPagination = total_count > per_page;

  useEffect(() => {
    return () => {
      dispatch(resetFetchTasks());
    };
  }, []);

  useEffect(() => {
    dispatch(fetchTasks(filters));
  }, [locale]);

  useEffect(() => {
    if (!fetch) {
      return;
    }

    dispatch(fetchTasks(filters));
  }, [locale, fetch, filters]);

  useEffect(() => {
    const { start_date, end_date } = date_filters;

    if (!start_date || !end_date) return;

    dispatch(
      setFilters({
        filter: {
          range: [start_date, end_date],
        },
      })
    );
  }, [date_filters]);

  // useEffect(() => {
  //   if (!fetch) return;
  //
  //   dispatch(fetchTasks(filters));
  // }, [fetch, filters]);

  if (!initialized) {
    return (
      <Tab.Pane attached={false}>
        <Placeholder>
          <Placeholder.Header image>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      </Tab.Pane>
    );
  }

  // {stats.map((stat) => {
  //   return (
  //     <Statistic
  //       color="grey"
  //       size="mini"
  //       style={{ margin: "0px 10px 0px 0px" }}
  //     >
  //       <Statistic.Value>{stat}%</Statistic.Value>
  //     </Statistic>
  //   );
  // })}

  return (
    <Container>
      <Header as="h1" style={{ textAlign: "center", marginTop: 20 }}>
        {I18n.t("tasks")}
      </Header>
      <DashboardContainer>
        <GridContainer style={{ minHeight: 360 }}>
          <Grid columns="1" divided>
            {tasks.length === 0 ? (
              <Grid.Column>
                <EmptyLottieView
                  title="No Tasks"
                  animation={taskLottie}
                  style={{ width: "34%", height: "80%" }}
                />
              </Grid.Column>
            ) : (
              <>
                <Grid.Column>
                  <List divided relaxed>
                    {tasks.map((task) => {
                      const {
                        id,
                        path,
                        state_color,
                        username,
                        display_title,
                        display_date_formated,
                        display_day_formated,
                        display_date_duration,
                        display_button_state,
                      } = task;

                      return (
                        <List.Item>
                          <List.Content floated="right">
                            {update_task_status_allowed && (
                              <Button
                                compact
                                color={state_color}
                                onClick={() => {
                                  navigate(path);
                                }}
                              >
                                {display_button_state}
                              </Button>
                            )}
                          </List.Content>
                          <List.Icon
                            name="smile outline"
                            size="large"
                            color={state_color}
                            verticalAlign="middle"
                          />
                          <List.Content>
                            <List.Header
                              as="a"
                              onClick={() => {
                                navigate(path);
                              }}
                            >
                              {display_title}
                            </List.Header>
                            <List.Description>
                              <span
                                style={{
                                  marginRight: "2px",
                                  color: "rgb(39 51 62)",
                                  fontWeight: "bold",
                                }}
                              >
                                {username} -
                              </span>
                              <span
                                style={{
                                  color: "#626262",
                                  fontWeight: "bold",
                                }}
                              >
                                {display_date_formated} - {display_day_formated}
                              </span>{" "}
                              <span
                                style={{
                                  color: "#786e5f",
                                  fontWeight: "bold",
                                }}
                              >
                                {display_date_duration}
                              </span>
                            </List.Description>
                          </List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                </Grid.Column>
              </>
            )}
          </Grid>
        </GridContainer>
      </DashboardContainer>
      <Grid>
        <Grid.Column
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <GridPagination>
            {showPagination && (
              <Pagination
                size="mini"
                activePage={page}
                defaultActivePage={page}
                totalPages={total_pages}
                onPageChange={(event, { activePage }) => {
                  dispatch(setFilters({ page: activePage }));
                }}
              />
            )}
          </GridPagination>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

const DashboardContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  background: white;
  margin: 20px 0px;
  background: #ffffff;
  box-shadow: 0px 0px 5px 0px #80808036;
  padding: 20px;
  border-radius: 4px;
  height: auto;
  /* min-height: 500px; */
`;

import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Button } from "semantic-ui-react";

import Talk from "app/components/Talk";
import Robot from "app/components/MovingRobot";
import StatusBar from "./StatusBar";

import { useWindowDimensions } from "app/hooks";
import { fetchSynthesis } from "app/store/public/synthesis";
import { createSynthesis } from "app/store/questions/synthesis";

import {
  setType,
  setPlaying,
  setScale,
  setAnimate,
  setPosition,
  setLocation,
  setDimentions,
  setMultiplier,
} from "app/store/robot";

const itemVariants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const listVariants = {
  open: {
    opacity: 1,
    transition: {
      staggerDirection: -1,
      staggerChildren: 0.2,
      when: "beforeChildren",
    },
  },
  closed: {
    opacity: 0,
    transition: {
      when: "afterChildren",
      staggerChildren: 0.2,
    },
  },
};

export default function Math(props) {
  const { help, question, question_id } = props;
  const refs = useRef({});
  const mathRef = useRef();
  const answerRef = useRef();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();

  const { locale } = useSelector((state) => state.talk);
  const { synthesis, fetching } = useSelector(
    (state) => state.questions_synthesis
  );

  const currentIndexColor = "#4364af";
  const [isVisible, setVisible] = useState(true);
  const [currentText, setCurrentText] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [style, setStyle] = useState({
    opacity: 1,
    display: "flex",
    color: "#13a4a8",
    marginBottom: 2,
    justifyContent: "flex-end",
    textAlign: "center",
  });

  const [item, setItem] = useState({
    color: "#13a4a8",
    width: 33.3,
  });

  const [position, setPosition] = useState();
  const [currentTimeout, setCurrentTimeout] = useState();
  const [lessonInProgress, setLessonInProgress] = useState(false);
  const [fetchNeeded, setFetchNeeded] = useState(false);
  const [replay, setReplay] = useState(false);

  const [steps, setSteps] = useState([...synthesis.steps]);
  const [answers, setAnswers] = useState([...synthesis.answers]);
  const [questions, setQuestions] = useState([...synthesis.questions]);
  const [remainders, setRemainders] = useState([...synthesis.remainders]);
  const [activateRobot, setActivateRobot] = useState(false);

  console.log("synthesis", synthesis);

  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) return;

    SpeechRecognition.startListening({ continuous: true, language: locale });
  }, [locale]);

  useEffect(() => {
    if (help) {
      dispatch(setType("right-hand"));

      // const utterance = new window.SpeechSynthesisUtterance();
      //
      // utterance.text = " ";
      // utterance.onend = function () {
      //   dispatch(setPlaying(true));
      //   setActivateRobot(true);
      // };
      // window.speechSynthesis.speak(utterance);
      dispatch(createSynthesis(question_id, locale));
      setActivateRobot(true);
    }
  }, [help, question_id, locale]);

  useEffect(() => {
    hydrate();
  }, [synthesis]);

  useEffect(() => {
    if (!replay) {
      return;
    }
    setReplay(false);

    hydrate();
    setCurrentStep(null);
  }, [replay]);

  useEffect(() => {
    dispatch(setLocation("bottom-left"));
    dispatch(setDimentions({ width: 44, height: 44 }));
  }, []);

  function hydrate() {
    setSteps([...synthesis.steps]);
    setAnswers([...synthesis.answers]);
    setQuestions([...synthesis.questions]);
    setRemainders([...synthesis.remainders]);
  }

  function runStep(step) {
    if (fetchNeeded) {
      setLessonInProgress(false);
      return;
    }

    if (step.action === "talk") {
      setCurrentText(step.message);
      dispatch(setType("talking"));
      return;
    }

    if (step.action === "end_lesson") {
      dispatch(setType("idle"));
      setLessonInProgress(false);
      return;
    }

    setTimeout(() => {
      dispatch(setType("left-hand"));

      if (step.action === "set_align_dots") {
        setQuestions(
          questions.map((question) => {
            return question.map((q) => {
              return { ...q, display: "block" };
            });
          })
        );
      } else if (step.action === "set_show_zeros") {
        setQuestions(
          questions.map((question) => {
            return question.map((q) => {
              if (q.action == "set_show_zeros") {
                q.visibility = "visible";
                return { ...q, display: "block", visibility: "visible" };
              }

              return { ...q, display: "block" };
            });
          })
        );
      } else if (step.action === "set_answer") {
        const answer = answers[currentIndex];

        setAnswers([
          ...answers.map((a) => {
            if (a === answer) {
              return { ...answer, visibility: "visible" };
            }

            return a;
          }),
        ]);
      } else if (step.action === "start_step") {
        setCurrentIndex(answers.length - 1);
      } else if (step.action === "next_step") {
        setCurrentIndex(currentIndex - 1);
      } else if (step.action === "end_step") {
        setCurrentIndex(null);
        dispatch(setType("talking"));
        dispatch(setType("idle"));
      } else if (step.action === "set_remainder") {
        let skip = false;

        const reversed = remainders
          .slice()
          .reverse()
          .map((r) => {
            if (!r.value || r.visibility == "visible" || skip) return r;

            skip = true;

            return { ...r, visibility: "visible" };
          });

        setRemainders(reversed.reverse());
      }

      setLessonInProgress(false);
      setCurrentStep(null);
    }, 1000);
  }

  // useEffect(() => {
  //   if (!question.length) return;
  //
  //   setSteps([]);
  //   setCurrentStep(null);
  //
  //   if (lessonInProgress) {
  //     setFetchNeeded(true);
  //     return;
  //   }
  //
  //   dispatch(fetchSynthesis({ type: "add_numbers", question, locale }));
  // }, [locale, question]);

  useEffect(() => {
    if (!help || currentStep || !steps.length) return;

    const step = steps.shift();

    setCurrentStep(step);
    setSteps(steps);
    setLessonInProgress(true);

    runStep(step);
  }, [help, steps, currentStep]);

  useEffect(() => {
    if (!mathRef.current) return;

    setPosition(mathRef.current.getBoundingClientRect());
  }, [mathRef]);

  useEffect(() => {
    if (!Object.keys(refs).length) return;

    const ref = refs.current[`answer-${currentIndex}`];

    if (!ref) return;

    const { x, y } = ref.getBoundingClientRect();

    dispatch(setAnimate({ x: ref.offsetLeft - 45, y: ref.offsetHeight - 40 }));
    // dispatch(setAnimate({ x: width - x + 90, y: y - 20 }));
  }, [refs, currentIndex]);

  return (
    isVisible && (
      <div ref={mathRef}>
        <Talk
          text={currentText}
          onEnd={() => {
            setCurrentText(null);
            setLessonInProgress(false);
            setCurrentStep(null);
          }}
        />
        <ExplainWrapper>
          <LanguageSelect>
            <StatusBar />
          </LanguageSelect>
          <div style={{ alignItems: "center", color: "#80437b" }}>+</div>
          <div>
            <motion.div
              key="remainders"
              initial={"closed"}
              // exit={"closed"}
              animate="open"
              variants={listVariants}
              transition={{ duration: 1 }}
              style={{ ...style, position: "relative", bottom: -13 }}
            >
              {remainders.map((num, index) => (
                <motion.span
                  key={`remainders-${index}`}
                  variants={itemVariants}
                  style={{
                    ...item,
                    visibility: num.visibility,
                    display: num.display,
                    fontSize: "22px",
                    color: "#ca7113",
                    fontWeight: "bold",
                    textAlign: "center",
                    width: num.type === "dot" ? 11 : 33.3,
                  }}
                >
                  {num.value}
                </motion.span>
              ))}
            </motion.div>

            {questions.map((question, i) => {
              return (
                <motion.div
                  key={`number-${i}`}
                  initial={"closed"}
                  // exit={"closed"}
                  animate="open"
                  variants={listVariants}
                  transition={{ duration: 1 }}
                  style={style}
                >
                  {question.map((num, index) => (
                    <motion.span
                      key={`number-${i}-${index}`}
                      variants={itemVariants}
                      style={{
                        ...item,
                        visibility: num.visibility,
                        display: num.display,
                        width: num.type === "dot" ? 11 : 33.3,
                        color:
                          index === currentIndex
                            ? currentIndexColor
                            : item.color,
                      }}
                    >
                      {num.value}
                    </motion.span>
                  ))}
                </motion.div>
              );
            })}

            <LineSeparator />
            <motion.div
              key="answers"
              initial={"closed"}
              // exit={"closed"}
              animate="open"
              variants={listVariants}
              transition={{ duration: 2 }}
              style={style}
            >
              {answers.map((num, index) => (
                <span
                  key={`answers-answer-${index}`}
                  ref={(ref) => (refs.current[`answer-${index}`] = ref)}
                >
                  <motion.span
                    variants={itemVariants}
                    style={{
                      ...item,
                      visibility: num.visibility,
                      display: num.display,
                      width: num.type === "dot" ? 11 : 33.3,
                      borderBottom: "3px solid #2a3363",
                      color:
                        index === currentIndex ? currentIndexColor : item.color,
                    }}
                  >
                    {num.value}
                  </motion.span>
                </span>
              ))}
            </motion.div>
          </div>

          <Robot
            moving={true}
            onClick={() => {
              // dispatch(setType("right-hand"));
              //
              // if (window.speechSynthesis) {
              //   alert("Yes wspeechre");
              //   const utterance = new window.SpeechSynthesisUtterance();
              //
              //   utterance.text = " ";
              //   utterance.onend = function () {
              //     dispatch(setPlaying(true));
              //     setActivateRobot(true);
              //   };
              //   window.speechSynthesis.speak(utterance);
              // }
            }}
          />
          <Button
            icon="redo"
            circular
            color="blue"
            onClick={() => setReplay(true)}
            style={{ position: "absolute", right: "4px", bottom: "4px" }}
          />
        </ExplainWrapper>
      </div>
    )
  );
}

const Wrapper = styled.div`
  display: flex;
  font-size: 38px;
  font-weight: bold;
  box-shadow: 0px 0px 4px #949494;
  padding: 14px;
`;

const MathDigit = styled.div`
  color: #13a4a8;
`;

const MathSign = styled.div`
  color: #561764;
  margin: 0px 6px;
`;

const LineSeparator = styled.div`
  height: 4px;
  background: #212321;
`;

const LanguageSelect = styled.div`
  height: 40px;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
`;

const ExplainWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 4px;
  padding: 60px 0px 10px 0px;
  background: rgb(239 239 239);
  font-size: 38px;
  line-height: normal;
  margin-bottom: 0px;
`;

import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Button } from "semantic-ui-react";

import StatusBar from "./StatusBar";
import Talk from "app/components/Talk";
import Robot from "app/components/MovingRobot";
import ExplainSimpleAnswers from "./ExplainSimpleAnswers";
import ExplainSimpleQuestion from "./ExplainSimpleQuestion";

import { useWindowDimensions } from "app/hooks";
import { fetchSynthesis } from "app/store/public/synthesis";
import { createSimpleSynthesis } from "app/store/questions/synthesis";

import {
  setType,
  setPlaying,
  setScale,
  setAnimate,
  setPosition,
  setLocation,
  setDimentions,
  setMultiplier,
} from "app/store/robot";

export default function ExplainSimpleMath(props) {
  const { help, question_id } = props;

  const refs = useRef({});
  const mathRef = useRef();
  const explainRef = useRef();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();

  const { locale } = useSelector((state) => state.talk);
  const { synthesis, fetching } = useSelector(
    (state) => state.questions_synthesis
  );

  const [currentText, setCurrentText] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [replay, setReplay] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [lessonInProgress, setLessonInProgress] = useState(false);

  const [steps, setSteps] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (!SpeechRecognition.browserSupportsSpeechRecognition()) return;

    SpeechRecognition.startListening({ continuous: true, language: locale });
  }, [locale]);

  useEffect(() => {
    dispatch(setLocation("bottom-left"));
    dispatch(setDimentions({ width: 44, height: 44 }));
  }, []);

  useEffect(() => {
    if (!synthesis.steps.length) return;

    setSteps(synthesis.steps);
    setAnswers(synthesis.answers);
    setQuestions(synthesis.questions);
  }, [synthesis]);

  useEffect(() => {
    reset();
    dispatch(createSimpleSynthesis(question_id, locale));
  }, [question_id, locale]);

  useEffect(() => {
    if (!lessonInProgress) return;

    const step = steps[currentIndex];

    setCurrentTimeout(null);
    setCurrentStep(step);
  }, [steps, currentIndex, lessonInProgress]);

  useEffect(() => {
    if (!currentStep) return;

    runStep();
  }, [currentStep]);

  useEffect(() => {
    if (replay) {
      setReplay(false);
      setRefresh(false);
      setCurrentIndex(0);
      setLessonInProgress(true);
    }
  }, [replay]);

  useEffect(() => {
    if (!refresh) {
      setRefresh(true);
    }
  }, [refresh]);

  function reset() {
    setCurrentIndex(0);
    dispatch(setType("idle"));
    setLessonInProgress(false);
    setCurrentStep(null);
    dispatch(setAnimate({ x: 0, y: 0 }));
  }

  function runStep() {
    const { action, message, target, duration, value, styles } = currentStep;

    if (action === "talk") {
      setCurrentText(message);
      dispatch(setType("talking"));

      return;
    }

    if (action == "toggle_color") {
      const ref = refs.current[target];
      if (!ref) return;

      const color = ref.style.color;
      ref.style.color = value;

      setTimeout(() => {
        ref.style.color = color;
        setCurrentIndex(currentIndex + 1);
      }, duration);

      return;
    }

    if (action === "move") {
      const ref = refs.current[target];
      if (!ref) return;

      const height = ref.offsetParent.offsetHeight;

      dispatch(setType("left-hand"));
      dispatch(
        setAnimate({
          x: ref.offsetLeft - 10,
          y: height - ref.offsetTop - 84,
        })
      );

      setCurrentTimeout(
        setTimeout(() => {
          if (lessonInProgress) {
            setCurrentIndex(currentIndex + 1);
          }
        }, duration || 400)
      );

      return;
    }

    if (action == "reveal") {
      const ref = refs.current[target];
      if (!ref) return;
      ref.style.visibility = "visible";
      setCurrentIndex(currentIndex + 1);

      return;
    }

    if (action === "end_lesson") {
      reset();

      return;
    }

    if (action == "delay") {
      setCurrentTimeout(
        setTimeout(() => {
          if (lessonInProgress) {
            setCurrentIndex(currentIndex + 1);
          }
        }, duration)
      );

      return;
    }

    if (action == "replace") {
      const ref = refs.current[target];
      if (!ref) return;

      ref.innerHTML = value;

      setCurrentIndex(currentIndex + 1);

      return;
    }

    if (action == "remove") {
      const ref = refs.current[target];
      if (!ref) return;

      setTimeout(() => {
        ref.style.display = "none";
        setCurrentIndex(currentIndex + 1);
      }, duration);

      return;
    }

    if (action == "style") {
      const ref = refs.current[target];
      if (!ref) return;

      styles.forEach((style) => {
        ref.children[0].style[style.key] = style.value;
        ref.children[0].style.transform = "scale(1.2)";
      });

      setCurrentIndex(currentIndex + 1);

      return;
    }
  }

  return (
    <div ref={mathRef}>
      <Talk
        text={currentText}
        onEnd={() => {
          setCurrentText(null);
          if (lessonInProgress) {
            setCurrentIndex(currentIndex + 1);
          }
        }}
      />
      <ExplainWrapper ref={explainRef}>
        <LanguageSelect>
          <StatusBar />
        </LanguageSelect>

        {refresh && (
          <Main>
            <ExplainSimpleAnswers refs={refs} answers={answers} />
            <ExplainSimpleQuestion refs={refs} questions={questions} />
          </Main>
        )}

        <Robot moving={true} />

        {!lessonInProgress && (
          <Button
            icon="redo"
            circular
            color="blue"
            onClick={() => setReplay(true)}
            style={{ position: "absolute", right: "44px", bottom: "4px" }}
          />
        )}
        <Button
          icon="play"
          circular
          color="black"
          onClick={() => setLessonInProgress(true)}
          style={{ position: "absolute", right: "4px", bottom: "4px" }}
        />
      </ExplainWrapper>
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  font-size: 38px;
  font-weight: bold;
  box-shadow: 0px 0px 4px #949494;
  padding: 14px;
`;

const Main = styled.div`
  min-height: 200px;
  display: flex;
  width: 100%;
  margin: 0px 20px;
`;

const MathDigit = styled.div`
  color: #13a4a8;
`;

const MathSign = styled.div`
  color: #561764;
  margin: 0px 6px;
`;

const LineSeparator = styled.div`
  height: 4px;
  background: #212321;
`;

const LanguageSelect = styled.div`
  height: 40px;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
`;

const ExplainWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 4px;
  padding: 60px 0px 40px 0px;
  background: rgb(239 239 239);
  font-size: 38px;
  line-height: normal;
  margin-bottom: 0px;
`;

const translations = {
  choose_city: "Choose city",
  order_with_number: "Order#%{number}",
  enter_new_password: "Enter New Password",
  password_confirmation: "Password Confirmation",
  confirm: "Confirm",
  password_reset_success: "Password reset success",
  continue: "Continue",
  not_yet: "Not yet",
  rate: "Rate",
  cancel: "Bekor Qilish",
  locale: "Til",
  success_sent_confirmation: "success_sent_confirmation",
  upload: "Upload",

  enter_city: "Enter City",
  enter_address: "Manzilni kiriting",
  cart: "KORZINKA",
  reset: "Yangilash",
  and: "va",
  account: "Shaxsiy kabinet",
  write: "Yozing",
  write_dots: "Yozing ...",
  add_to_cart: "KORZONKAGA QO'SH",
  any_time: "Istalgan vaqt",
  filters: "Filtrlar",
  select_date_and_hour: "Kun va yetqazib berish soatini tanlang",
  enter_and_verify_phone: "Telefon raqamingizni kiriting va tasdiqlang",
  edit_recipient: "Qabul qiluvchini tahrirlash",
  request_address_from_recipient: "Qabul qiluvchidan manzilni so'rang!",

  credit_card: "Kredit Karta",
  apple_pay: "Apple Pay",
  cash: "Naqd pul",
  recipients: "Qabul qiluvchilar",
  postcard: "Открытка",
  want_to_send_postcard: "Siz pochta kartasini yubormoqchimisiz?",

  price: "Narx",
  size: "O'lcham",
  color: "Rang",
  flowers: "Gullar",
  products: "Mahsulotlar",

  remember_me: "Eslab qol",
  or: "Yoki",
  register: "Ro'yxatdan o'tish",
  send_password: "Parol yuborish",
  go_back: "Ortga qaytish",
  enter_your_phone: "Telefoningizni kiriting",

  first_name: "Ism",
  last_name: "Familiya",
  phone: "Telefon",
  address: "Manzil",
  directions: "Yetkazib berish bo'yicha ko'rsatmalar",
  new_recipient: "Yangi qabul qilo'chi",

  profile: "Profil",
  orders: "Buyurtmalar",
  reviews: "Sharhlar",
  favorites: "Sevimlilar",
  delivery: "Etqazib Berish",
  pickup: "O'zi Olib Ketish",
  become_partner: "Hamkor bo'ling",
  track_order: "Buyurtmani kuzatish",

  flowers: "Gullar",
  occasions: "Munosabatlar",

  number: "Zakaz Nomeri",
  recipient: "Oluvchi",
  amount: "To'lov",
  status: "Holati",
  actions: "Amallar",

  shop: "Do'kon",

  created_at: "Yaratilgan Vaqti",
  updated_at: "Yangilangan Vaqti",
  pending: "Kutilmoqda",
  accepted: "Qabul qilingdi",
  in_progress: "Jarayonda",
  in_courier: "Operatorda",
  in_route: "Yetkazib berishda",
  fulfilled: "Bajarildi",
  cancelled: "Bekor qilindi",

  terms: "Shartlarimiz",
  privacy_policy: "Maxfiylik siyosatimga",
  agree_to_terms: "Ro'yhatdan o'tish bilan siz roziligingizni bildirasiz",
  promotional_text:
    "Ha! Men BUKETUdan yangilanishlar, reklama takliflarini qabul qilmoqchiman.",

  welcome_step_one: "Buketuga xush kelibsiz",
  welcome_step_two: "Do'konlar bilan suhbat",
  welcome_step_three: "Kartada buyurtmalarni kuzatib boring",

  select_recipient: "Qabul qiluvchini tanlang",

  pay_with_apple: "Apple Pay bilan to'lov %{amount}",
  pay_with_cash: "Naqd pul bilan to'lov %{amount}",
  pay_with_card: "Karta bilan to'lov %{amount}",

  add_card: "Kartani qo'shish",
  scan_card: "Kartani skanerlash",
  go_to_cart: "ПЕРЕЙТИ НА КОРЗИНУ",

  small: "Kichik",
  medium: "O'rta",
  large: "Katta",

  chat: "Suhbat",
  total: "Total",
  cart_is_empty: "Cart is empty",
  continue_shopping: "Xarid qilishni davom eting",
  order_placed: "Buyurtma yaratildi!",
  will_inform_abouut_order:
    "Do'kon buyurtmalarni qabul qilishi bilanoq sizga xabar beramiz",

  next: "Keyingi",
  back: "Orqaga",

  Alstroemeria: "alstromeria",
  chocolates: "Chocolates",
  Godiva: "Godiva",
  bears: "Bears",
  "Teddy Bear": "Teaddy Bear",
  cakes: "Cakes",
  Birthday: "Birthday",
  vases: "Vases",
  Clear: "Clear",
  balloons: "Balloons",

  height: "Balandligi",
  width: "Kengligi",

  active_orders: "Faol buyurtmalar",
  fulfilled_orders: "Bajarilgan buyruqlar",

  checkout: "Корзинка",
  pay: "To'lash",

  no_favorites: "Sizda hali tanlanganlar yo'q",

  settings: "Sozlamalar",
  cards: "Kredit kartalari",
  notifications: "Bildirishnomalar",
  languages: "Tillar",
  currency: "Valyuta",
  password: "Parol",
  signin: "Tizimga kirish",
  logout: "Chiqish",
  timezone: "Vaqt zonasi",
  english: "Ingliz tili",
  russian: "Rus tili",
  uzbek: "Uzbek tili",
  success: "Muvaffaqiyatli saqlandi",
  email: "Email",
  signup: "Ro'yxatdan o'tish",
  create_one: "Yarating",
  have_account: "Akkountingiz bormi?",
  dont_have_account: "Akkountingiz yo'qmi?",
  forgot_password: "Parolni unutdingizmi?",
  send: "Yubor",
  fullname: "To'liq ismi sharif",

  login: "Kirish",
  auth_failed: "Tizimga kirishda xatolik yuz berdiv",
  login_auth_failed: "Noto'g'ri elektron pochta yoki parol",
  email_sent_success: "Xabar muvaffaqiyatli yuborildi",
  unauthorized: "Sizda bu amalni bajarish huquqi yo'q",
  too_many_request: "Siz ko'p narsani talab qilmoqdasiz",
  not_found: "Siz so'ragan manbani topa olmadik",
  add_payment_method: "To'lov usulini qo'shing",
  payment_added: "Karta qo'shildi",

  card_number: "KARTA RAQAMI",
  expiry: "TUGASH MUDDATI",
  cvc: "CVC/CCV",
  card_holder_name: "KARTOCHKA EGASINING ISMI",
  postal_code: "POCHTA KOD",
  fullname: "To'liq ismi sharif",

  save: "Saqlash",
  server_error:
    "Ba'zi muammolarga duch kelmoqdamiz. Iltimos keyinroq qayta urinib ko'ring!",

  login_to_your_account: "AKKOUNTGA KIRISH",
};

export default translations;

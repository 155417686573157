import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "../utils";

const slice = createSlice({
  name: "saml_settings",
  initialState: {
    saml_setting: {},
    fetch: true,
    getting: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    destroyed: false,
    destroying: false,
    extracted: false,
    open_form: false,
    validations: {},
  },
  reducers: {
    extractedSamlSetting: (state, action) => {
      state.extracted = true;
      state.saml_setting = { ...state.saml_setting, ...action.payload };
    },
    extractingSamlSetting: (state) => {
      state.extracted = false;
    },
    gettingSamlSetting: (state) => {
      state.getting = true;
    },
    gotSamlSetting: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.saml_setting = action.payload;
    },
    createdSamlSetting: (state, action) => {
      state.fetch = true;
      state.creating = false;
      state.open_form = false;
      state.validations = {};
      state.saml_setting = action.payload;
    },
    creatingSamlSetting: (state) => {
      state.creating = true;
    },
    updatedSamlSetting: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.validations = {};
      state.saml_setting = action.payload;
    },
    updatingSamlSetting: (state) => {
      state.updating = true;
    },
    destroyingSamlSetting: (state) => {
      state.destroying = true;
    },
    destroyedSamlSetting: (state, action) => {
      state.fetch = true;
      state.destroyed = true;
      state.destroying = false;
      state.open_form = false;
      state.saml_setting = {};
      state.validations = {};
    },
    openedForm: (state, action) => {
      state.open_form = true;
    },
    closedForm: (state) => {
      state.open_form = false;
      state.validations = {};
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  openedForm,
  closedForm,
  extractedSamlSetting,
  extractingSamlSetting,
  gotSamlSetting,
  gettingSamlSetting,
  createdSamlSetting,
  creatingSamlSetting,
  updatedSamlSetting,
  updatingSamlSetting,
  destroyedSamlSetting,
  destroyingSamlSetting,
  handleValidations,
} = slice.actions;

export const openForm = (saml_setting = {}) => async (dispatch) => {
  dispatch(openedForm(saml_setting));
};

export const closeForm = () => async (dispatch) => {
  dispatch(closedForm());
};

export const extractMetadata = (data) => async (dispatch) => {
  try {
    dispatch(extractingSamlSetting());

    const response = await create({
      endpoint: `/api/v1/extract/metadata`,
      data: { data },
    });
    const { saml_setting } = response.data;

    dispatch(extractedSamlSetting(saml_setting));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const getSamlSetting = () => async (dispatch) => {
  try {
    dispatch(gettingSamlSetting());

    const response = await get({
      endpoint: `/api/v1/saml_settings`,
    });
    const { saml_setting } = response.data;

    dispatch(gotSamlSetting(saml_setting));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createSamlSetting = (data) => async (dispatch) => {
  try {
    dispatch(creatingSamlSetting());

    const response = await create({
      endpoint: `/api/v1/saml_settings`,
      data: { saml_setting: data },
    });
    const { saml_setting } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdSamlSetting(saml_setting));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateSamlSetting = (data) => async (dispatch) => {
  try {
    dispatch(updatingSamlSetting());

    const response = await update({
      endpoint: `/api/v1/saml_settings`,
      data: { saml_setting: data },
    });
    const { saml_setting } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedSamlSetting(saml_setting));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const destroySamlSetting = (data) => async (dispatch) => {
  try {
    dispatch(destroyingSamlSetting());

    const response = await destroy({
      endpoint: `/api/v1/saml_settings`,
      data: { saml_setting: data },
    });
    const { saml_setting } = response.data;

    toastDeleteSuccessMessage();
    dispatch(destroyedSamlSetting(saml_setting));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "app/cookie";
import { get, fetch, create, update, destroy } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "user_chess_plays",
  initialState: {
    user_chess_play: null,
    user_chess_plays: [],
    getting: false,
    initialized: false,
    fetch: true,
    fetched: false,
    fetching: false,
    editing: false,
    created: false,
    creating: false,
    updating: false,
    deleting: false,
    validations: {},
  },
  reducers: {
    gettingUserChessPlay: (state) => {
      state.getting = true;
    },
    gotUserChessPlay: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.user_chess_play = action.payload;
    },
    fetchedUserChessPlays: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.user_chess_plays = action.payload;
    },
    fetchingUserChessPlays: (state) => {
      state.fetching = true;
    },
    createdUserChessPlay: (state, action) => {
      state.creating = false;
      state.created = true;
      state.validations = {};
      state.user_chess_play = action.payload;
      state.user_chess_plays = [...state.user_chess_plays, action.payload];
    },
    creatingUserChessPlay: (state) => {
      state.creating = true;
    },
    updatedUserChessPlay: (state, action) => {
      const { user_chess_plays } = state;
      const user_chess_play = action.payload;

      state.updating = false;
      state.validations = {};
      state.user_chess_play = user_chess_play;

      const index = user_chess_plays.findIndex(
        (uq) => uq.id == user_chess_play.id
      );

      if (index == -1) {
        state.user_chess_plays = [...user_chess_plays, user_chess_play];
      } else {
        user_chess_plays[index] = user_chess_play;
        state.user_chess_plays = [...user_chess_plays];
      }
    },
    updatingUserChessPlay: (state) => {
      state.updating = true;
    },
    deletedUserChessPlay: (state, action) => {
      state.deleting = false;
      state.validations = {};
      state.user_chess_plays = [
        ...state.user_chess_plays.filter(
          (user_chess_play) => user_chess_play.id !== action.payload.id
        ),
      ];
    },
    deletingUserChessPlay: (state) => {
      state.deleting = true;
    },
    resettedUserChessPlay: (state) => {
      state.user_chess_play = null;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.creating = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotUserChessPlay,
  gettingUserChessPlay,
  fetchedUserChessPlays,
  fetchingUserChessPlays,
  createdUserChessPlay,
  creatingUserChessPlay,
  updatedUserChessPlay,
  updatingUserChessPlay,
  deletedUserChessPlay,
  deletingUserChessPlay,
  resettedUserChessPlay,
  handleValidations,
} = slice.actions;

export const resetUserChessPlay = () => async (dispatch) => {
  dispatch(resettedUserChessPlay());
};

export const getUserChessPlay = (id) => async (dispatch) => {
  try {
    dispatch(gettingUserChessPlay());

    const response = await get({
      endpoint: `/api/v1/user_chess_plays/${id}`,
    });
    const { user_chess_play } = response.data;

    dispatch(gotUserChessPlay(user_chess_play));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateUserChessPlay = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingUserChessPlay());

    const response = await update({
      endpoint: `/api/v1/user_chess_plays/${id}`,
      data: { user_chess_play: data },
    });
    const { user_chess_play } = response.data;

    dispatch(updatedUserChessPlay(user_chess_play));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

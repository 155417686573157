import React from "react";

import Chessboard from "chessboardjsx";

import StockFish from "./Stockfish.js";

export default function Game(props) {
  const { user_chess_catch, onEnd, onChange } = props;
  const {
    choice,
    problem,
    solution,
    display_name,
    steps_taken,
  } = user_chess_catch;

  return (
    <div style={boardsContainer}>
      <StockFish
        fen={solution}
        problem={problem}
        choice={choice}
        display_name={display_name}
        steps_taken={steps_taken}
        onChange={onChange}
        onEnd={onEnd}
      >
        {({ position, onDrop, orientation }) => {
          return (
            <Chessboard
              width={320}
              onDrop={onDrop}
              position={position}
              orientation={orientation}
              boardStyle={boardStyle}
            />
          );
        }}
      </StockFish>
    </div>
  );
}

const boardsContainer = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};
const boardStyle = {
  borderRadius: "5px",
  boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`,
};

import React, { useState, useEffect, useRef } from "react";

import cx from "classnames";
import styled from "styled-components";

export default function Game(props) {
  const { sudoku, onChange } = props;
  const inputRefs = useRef({});

  const [gridData, setGridData] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [editingCell, setEditingCell] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (!sudoku) return;

    setCompleted(sudoku.status === "completed");
    setGridData(sudoku.problem);
    setSolutions(
      sudoku.solution.split(",").map((a) => a.split("").map((n) => parseInt(n)))
    );
  }, [sudoku]);

  useEffect(() => {
    if (editingCell) {
      if (inputRefs.current) {
        const inputRef = inputRefs.current[editingCell];
        inputRef && inputRef.focus();
      }
    }
  }, [editingCell]);

  function setValue(x, y, value) {
    const parsedValue = parseInt(value);

    if (value.length && parsedValue > 9) {
      return false;
    }

    let rows = [...solutions[y]];

    try {
      rows[x] = parsedValue;
      solutions[y] = rows;
    } catch (err) {
      return false;
    }

    setSolutions([...solutions]);

    onChange(solutions.map((r) => r.join("")).join(","));
  }

  return (
    <Wrapper>
      <WrapperSudoku>
        <SudokuContainer>
          <SudokuTable classNam="sudoku-table">
            <tbody>
              {solutions.map((row, y) => (
                <tr
                  key={y}
                  className={cx({
                    "block-boder": (y + 1) % 3 === 0,
                  })}
                >
                  {row.map((value, x) => (
                    <td
                      key={x}
                      className={cx({
                        "block-boder": (x + 1) % 3 === 0,
                        focused: editingCell == [x, y].join(),
                        "selected-value":
                          selectedValue != 0 && selectedValue == value,
                      })}
                      onClick={() => {
                        setSelectedValue(value);
                        setEditingCell([x, y].join());
                      }}
                    >
                      <div className="cell">
                        <div
                          className="value"
                          className={cx({
                            value: true,
                            "user-added": !gridData[y][x],
                          })}
                        >
                          {value ? value : ""}
                        </div>
                        <input
                          type="text"
                          value={""}
                          onChange={(e) => setValue(x, y, e.target.value)}
                          readOnly={gridData[y][x] || completed}
                          ref={(input) =>
                            (inputRefs.current[[x, y].join()] = input)
                          }
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </SudokuTable>
        </SudokuContainer>
      </WrapperSudoku>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 100%;

  @media only screen and (max-width: 500px) {
    .sudoku-table {
      td,
      th {
        input {
          font-size: 30px;
          padding-left: 15px;
        }
      }
      .sudoku-tips {
        font-size: 8px;
        span {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
`;

const WrapperSudoku = styled.div`
  display: flex;
  /* height: 100px; */
  margin-top: 20px;
  justify-content: center;
`;

const SudokuContainer = styled.div`
  width: 95%;
  max-width: 600px;
`;

const SudokuTable = styled.table`
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
  border-radius: 1em;
  /* border: 3px solid black; */
  border: 2px solid #696b6e;
  background-color: #f5f7fb;
  background-color: #f9f9f9;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;

  tr {
    &:hover {
      background: #e9e9e9;
    }
  }

  td {
    &:hover {
      background: #c8cccd;
    }
    &.focused {
      background: #c8cccd;
    }
    &.selected-value {
      background: #c8cccd;
    }
  }

  td,
  th {
    width: 11.11%;
    position: relative;
    border: 1px solid #7a889f;
    overflow: hidden;
    color: #333;
    cursor: pointer;

    input {
      caret-color: transparent;
      padding: 0px;
      width: 1px;
      height: 100%;
      border: 0;
      outline: 0;
      /* font-size: 48px; */
      text-align: center;
      color: #4d607a;
      font-weight: 200;
      cursor: pointer;
      background: inherit;
    }
    &:after {
      content: "";
      display: block;
      margin-top: 100%;
    }
    &.active .cell {
      border: 1px solid black;
    }
    .cell {
      /* font-size: 2em; */
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .value {
        padding: 0 10px;
        /* width: 50px; */
        /* height: 100%; */
        border: 0;
        outline: 0;
        font-size: 36px;
        text-align: center;
        color: #4d607a;
        font-weight: 200;
        cursor: pointer;
        background: inherit;
        font-family: sans-serif;

        &.user-added {
          color: #416fb7;
        }
      }
    }
  }
  tr.block-boder:not(:last-child) {
    border-bottom: 3px solid black;
    border-bottom: 2px solid #696b6e;
  }
  td.block-boder:not(:last-child) {
    border-right: 3px solid black;
    border-right: 2px solid #696b6e;
  }
  td.solved {
    color: #467fcf;
  }

  .sudoku-tips {
    display: flex;
    flex-wrap: wrap;
    cursor: text;
    font-size: 1rem;
    span {
      display: block;
      width: 18px;
      height: 18px;
    }
  }
`;

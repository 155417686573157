import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import Status from "./Status";
import TopBar from "./TopBar";

import { getCSRFToken } from "app/store/csrf_token";
import { fetchCredentials } from "app/store/credentials";

export default function NavBar(props) {
  const dispatch = useDispatch();
  const { logged_in } = useSelector((state) => state.me);

  useEffect(() => {
    dispatch(getCSRFToken());
    dispatch(fetchCredentials());
  }, [logged_in]);

  return <TopBar />;
}

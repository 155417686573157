import React, { useState, useEffect } from "react";

import { Container } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Status from "./Status";
import Identify from "../Identify";
import Dashboard from "./Dashboard";

import Loading from "app/components/Loading";

export default function Main() {
  return (
    <>
      <Status />
      <Container style={{ paddingTop: 14, paddingBottom: 100 }}>
        <Identify />
        <Dashboard />
      </Container>
    </>
  );
}

import React from "react";
import ReactDOM from "react-dom";

import I18n from "i18n-js";

import App from "app";

import { getCookie } from "app/cookie";
import { en, es, ru, uz } from "app/translations";

import "moment/locale/ru";
import "moment/locale/nb";
import "moment/locale/es";

import "semantic-ui-css/semantic.min.css";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";

I18n.fallbacks = true;
I18n.translations = { en, es, ru, uz };
I18n.locale = getCookie("locale") || "en";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "reports",
  initialState: {
    report: {
      earned_points: 0,
      earned_gifts: 0,
      total_gifts: 0,
      completed_tasks: 0,
      total_tasks: 0,
      timespent: "0 min",
      progress: {
        index_by: "",
        keys: [],
        data: [],
      },
    },
    fetch: true,
    fetched: false,
    fetching: false,
    initialized: false,
    filters: {
      start_date: null,
      end_date: null,
    },
    validations: {},
  },
  reducers: {
    fetchingReport: (state) => {
      state.fetching = true;
    },
    fetchedReport: (state, action) => {
      state.fetch = true;
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.report = action.payload;
      state.validations = {};
    },
    handleSetFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.validations = action.payload;
    },
    handleError: (state, action) => {
      state.fetching = false;
      console.log(action.payload);
    },
  },
});

export default slice.reducer;

const {
  fetchedReport,
  fetchingReport,
  handleSetFilters,
  handleValidations,
  handleError,
} = slice.actions;

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const fetchReports = (data) => async (dispatch) => {
  try {
    dispatch(fetchingReport());

    const response = await get({
      endpoint: `/api/v1/reports`,
      data,
    });
    const { report } = response.data;

    dispatch(fetchedReport(report));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) {
      dispatch(handleValidations(validations));
    } else {
      console.log(response);
      dispatch(handleError(response));
    }
  }
};

const translations = {
  add: "Add",
  remove: "Remove",
  add_kid: "Add Kid",
  add_member: "Add Member",
  add_course: "Add Course",
  add_class: "Add Class",
  add_assignment: "Add Assignment",

  create: "Create",
  update: "Update",
  destroy: "Destroy",

  learning: "Learning",
  teaching: "Teaching",

  student: "Student",
  teacher: "Teacher",
};

export default translations;

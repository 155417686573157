import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { fetch } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "credentials",
  initialState: {
    credentials: {
      stripe_key: null,
      google_api_key: null,
    },
    fetch: true,
    fetched: false,
    fetching: false,
    initialized: false,
    filters: {
      start_date: null,
      end_date: null,
    },
    validations: {},
  },
  reducers: {
    fetchingCredentials: (state) => {
      state.fetching = true;
    },
    fetchedCredentials: (state, action) => {
      state.fetch = true;
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.credentials = action.payload;
      state.validations = {};
    },
    handleSetFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.validations = action.payload;
    },
    handleError: (state, action) => {
      state.fetching = false;
      console.log(action.payload);
    },
  },
});

export default slice.reducer;

const {
  fetchedCredentials,
  fetchingCredentials,
  handleSetFilters,
  handleValidations,
  handleError,
} = slice.actions;

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const fetchCredentials = (data) => async (dispatch) => {
  try {
    dispatch(fetchingCredentials());

    const response = await fetch({
      endpoint: `/api/v1/credentials`,
      data,
    });
    const { credentials } = response.data;

    dispatch(fetchedCredentials(credentials));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) {
      dispatch(handleValidations(validations));
    } else {
      console.log(response);
      dispatch(handleError(response));
    }
  }
};

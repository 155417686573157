import React from "react";

import Chessboard from "chessboardjsx";

import StockFish from "./integrations/Stockfish.js";
import Start from "../components/Start";

export default function Game(props) {
  const { status, user_chess_play, onStart, onEnd, onChange } = props;
  const {
    id,
    won,
    data,
    mode,
    choice,
    problem,
    solution,
    display_name,
    steps_taken,
  } = user_chess_play;

  const { fen } = data;

  return (
    <div style={boardsContainer}>
      <StockFish
        id={id}
        fen={fen}
        data={data}
        mode={mode}
        status={status}
        winner={won}
        problem={problem}
        choice={choice}
        display_name={display_name}
        onChange={onChange}
        onEnd={onEnd}
      >
        {({ position, onDrop, orientation }) => {
          return status === "pending" ? (
            <Start onStart={onStart} size={320} />
          ) : (
            <Chessboard
              width={320}
              onDrop={onDrop}
              position={position}
              orientation={orientation}
              boardStyle={boardStyle}
            />
          );
        }}
      </StockFish>
    </div>
  );
}

const boardsContainer = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
};
const boardStyle = {
  borderRadius: "5px",
  boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`,
};

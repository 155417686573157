import React, { Suspense, useContext, createContext, useState } from "react";

import { Route, Routes } from "react-router-dom";

import Main from "app/screens/main";
import Cart from "app/screens/cart";
import QrCode from "app/screens/qr_code";
import Zoom from "app/screens/zoom";
import Login from "app/screens/login";
import Signup from "app/screens/signup";
import Checkout from "app/screens/checkout";
import TermsPage from "app/screens/terms";
import HelpPage from "app/screens/help_page";
import UnlockEmail from "app/screens/unlock_email";
import UnlockKidsEmail from "app/screens/unlock_email/Kids";
import ResetPassword from "app/screens/reset_password";
import ForgotPassword from "app/screens/forgot_password";
import AcceptInvitation from "app/screens/accept_invitation";
import EmailConfirmation from "app/screens/email_confirmation";

import TaskUserTasks from "app/screens/tasks/pages/user_tasks";
import TaskUserSudokus from "app/screens/tasks/pages/user_sudokus";
import TaskUserQuizzes from "app/screens/tasks/pages/user_quizzes";
import TaskUserTicTac from "app/screens/tasks/pages/user_tic_tac";
import TaskUserChessPlays from "app/screens/tasks/pages/user_chess_plays";
import TaskUserChessLearns from "app/screens/tasks/pages/user_chess_learns";
import TaskUserChessCatches from "app/screens/tasks/pages/user_chess_catches";
import TaskUserChessChallenges from "app/screens/tasks/pages/user_chess_challenges";
// import AccountSettings from "./screens/AccountSettings";
import NotFound from "app/screens/not_found";
import Loading from "app/components/Loading";

const Math = React.lazy(() => import("./screens/math"));
const Chess = React.lazy(() => import("./screens/chess"));
const JoinChess = React.lazy(() => import("./screens/join_chess"));
const PlayChess = React.lazy(() => import("./screens/chess/play"));
const AccountSettings = React.lazy(() => import("./screens/account_settings"));

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route exact path="/qrcode/:id" element={<QrCode />} />
      <Route
        exact
        path="/math/playground"
        element={
          <Suspense fallback={<Loading />}>
            <Math />
          </Suspense>
        }
      />

      <Route path="/zoom/auth" element={<Zoom />} />

      <Route exact path="/tasks/:slug" element={<TaskUserTasks />} />
      <Route
        exact
        path="/tasks/:slug/user_sudokus/:sudoku_slug"
        element={<TaskUserSudokus />}
      />

      <Route
        exact
        path="/tasks/:slug/user_quizzes/:quiz_slug"
        element={<TaskUserQuizzes />}
      />
      <Route
        exact
        path="/tasks/:slug/user_chess_plays/:chess_slug"
        element={<TaskUserChessPlays />}
      />
      <Route
        exact
        path="/tasks/:slug/user_chess_learns/:chess_slug"
        element={<TaskUserChessLearns />}
      />
      <Route
        exact
        path="/tasks/:slug/user_chess_catches/:chess_slug"
        element={<TaskUserChessCatches />}
      />
      <Route
        exact
        path="/tasks/:slug/user_chess_challenges/:chess_slug"
        element={<TaskUserChessChallenges />}
      />
      <Route
        exact
        path="/tasks/:slug/user_sudokus/:sudoku_slug"
        element={<TaskUserSudokus />}
      />
      <Route
        exact
        path="/tasks/:slug/user_tic_tac/:user_tic_tac_slug"
        element={<TaskUserTicTac />}
      />

      <Route
        exact
        path="/chess"
        element={
          <Suspense fallback={<Loading />}>
            <Chess />
          </Suspense>
        }
      />
      <Route
        exact
        path="/chess/:slug"
        element={
          <Suspense fallback={<Loading />}>
            <PlayChess />
          </Suspense>
        }
      />
      <Route
        exact
        path="/chess/:slug/join"
        element={
          <Suspense fallback={<Loading />}>
            <JoinChess />
          </Suspense>
        }
      />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/help" element={<HelpPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route path="/email_confirmation" element={<EmailConfirmation />} />
      <Route path="/unlock_email" element={<UnlockEmail />} />
      <Route path="/unlock_kids_email" element={<UnlockKidsEmail />} />
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/accept_invitation" element={<AcceptInvitation />} />
      <Route
        path="/account_settings/*"
        element={
          <Suspense fallback={<Loading />}>
            <AccountSettings />
          </Suspense>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

import React, { useRef, useState, useEffect } from "react";

import Chess from "chess.js";
import { Icon } from "semantic-ui-react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { updateUserChessPlay } from "app/store/user_chess_plays";

const STOCKFISH = window.STOCKFISH;

export default function StockFish(props) {
  const dispatch = useDispatch();
  const {
    id,
    mode,
    winner,
    status,
    data: chess_play_data,
    choice,
    display_name,
  } = props;
  const historyRef = useRef(chess_play_data.history);

  const [engine, setEngine] = useState();
  const [skill, setSkill] = useState(20);
  const [game, setGame] = useState(new Chess());
  const [data, setData] = useState(chess_play_data);
  const [gameLoaded, setGameLoaded] = useState(false);
  const [previousHistory, setPreviousHistory] = useState([]);

  const [startTimer, setStartTimer] = useState(false);
  const [startTimerMove, setStartTimerMove] = useState(0);
  const [startTimerTimeout, setStartTimerTimeout] = useState();

  useEffect(() => {
    if (mode == "easy") {
      setSkill(0);
    } else if (mode == "medium") {
      setSkill(8);
    } else if (mode == "hard") {
      setSkill(14);
    } else {
      setSkill(20);
    }
  }, [mode]);

  useEffect(() => {
    game.load(data.fen);
    historyRef.current = data.history;
    setPreviousHistory(data.history);

    setGameLoaded(true);
  }, []);

  useEffect(() => {
    if (startTimerMove == 0) return;
    if (startTimerTimeout) clearTimeout(startTimerTimeout);

    setStartTimer(true);
    setStartTimerTimeout(setTimeout(() => setStartTimer(false), 1000 * 60));
  }, [startTimerMove]);

  useEffect(() => {
    if (data === chess_play_data) return;

    dispatch(updateUserChessPlay(id, { data }));
  }, [data]);

  useEffect(() => {
    if (!gameLoaded) return;

    let options = {};
    /// We can load Stockfish via Web Workers or via STOCKFISH() if loaded from a <script> tag.
    let engine =
      typeof STOCKFISH === "function"
        ? STOCKFISH()
        : new Worker(options.stockfishjs || "/stockfish.js");
    let evaler =
      typeof STOCKFISH === "function"
        ? STOCKFISH()
        : new Worker(options.stockfishjs || "/stockfish.js");
    let engineStatus = {};
    let time = {
      depth: 1,
      wtime: 1000,
      btime: 10000,
      winc: 1500,
      binc: 1500,
    };
    let playerColor = choice;
    let clockTimeoutID = null;
    // let isEngineRunning = false;
    let announced_game_over;
    // do not pick up pieces if the game is over
    // only pick up pieces for White

    if (status === "finished") return;

    setInterval(function () {
      if (announced_game_over) {
        return;
      }

      if (game.game_over()) {
        const turn = game.turn();
        const winner = turn == "w" ? "black" : "white";
        announced_game_over = true;

        setTimeout(() => {
          dispatch(
            updateUserChessPlay(id, {
              won: winner == "white",
              status: "completed",
            })
          );
        }, 2000);
      }

      // // checkmate?
      // if (game.in_checkmate()) {
      //   alert("checkmate");
      //   // status = "Game over, " + moveColor + " is in checkmate.";
      // }
      //
      // // draw?
      // else if (game.in_draw()) {
      //   // status = "Game over, drawn position";
      //   alert("draw bro");
      // }
      //
      // // game still on
      // else {
      //   // status = moveColor + " to move";
      //
      //   // check?
      //   if (game.in_check()) {
      //     alert("check for you");
      //     // status += ", " + moveColor + " is in check";
      //   }
      // }
    }, 500);

    function uciCmd(cmd, which) {
      (which || engine).postMessage(cmd);
    }
    uciCmd("uci");

    function clockTick() {
      let t =
        (time.clockColor === "white" ? time.wtime : time.btime) +
        time.startTime -
        Date.now();
      let timeToNextSecond = (t % 1000) + 1;
      clockTimeoutID = setTimeout(clockTick, timeToNextSecond);
    }

    function stopClock() {
      if (clockTimeoutID !== null) {
        clearTimeout(clockTimeoutID);
        clockTimeoutID = null;
      }
      if (time.startTime > 0) {
        let elapsed = Date.now() - time.startTime;
        time.startTime = null;
        if (time.clockColor === "white") {
          time.wtime = Math.max(0, time.wtime - elapsed);
        } else {
          time.btime = Math.max(0, time.btime - elapsed);
        }
      }
    }

    function startClock() {
      if (game.turn() === "w") {
        time.wtime += time.winc;
        time.clockColor = "white";
      } else {
        time.btime += time.binc;
        time.clockColor = "black";
      }
      time.startTime = Date.now();
      clockTick();
    }

    function getMoves() {
      let moves = "";

      const history = historyRef.current;

      for (let i = 0; i < history.length; ++i) {
        let move = history[i];
        moves +=
          " " + move.from + move.to + (move.promotion ? move.promotion : "");
      }

      return moves;
    }

    const prepareMove = () => {
      stopClock();

      let turn = game.turn() === "w" ? "white" : "black";
      if (!game.game_over()) {
        if (turn !== playerColor) {
          uciCmd("position startpos moves" + getMoves());
          uciCmd("position startpos moves" + getMoves(), evaler);
          uciCmd("eval", evaler);

          if (time && time.wtime) {
            uciCmd(
              "go " +
                (time.depth ? "depth " + time.depth : "") +
                " wtime " +
                time.wtime +
                " winc " +
                time.winc +
                " btime " +
                time.btime +
                " binc " +
                time.binc
            );
          } else {
            uciCmd("go " + (time.depth ? "depth " + time.depth : ""));
          }
        }

        if (historyRef.current.length >= 2 && !time.depth && !time.nodes) {
          startClock();
        }
      }
    };

    evaler.onmessage = function (event) {
      let line;

      if (event && typeof event === "object") {
        line = event.data;
      } else {
        line = event;
      }

      if (
        line === "uciok" ||
        line === "readyok" ||
        line.substr(0, 11) === "option name"
      ) {
        return;
      }
    };

    engine.onmessage = (event) => {
      let line;

      if (event && typeof event === "object") {
        line = event.data;
      } else {
        line = event;
      }

      if (line === "uciok") {
        engineStatus.engineLoaded = true;
      } else if (line === "readyok") {
        engineStatus.engineReady = true;
      } else {
        let match = line.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/);
        /// Did the AI move?
        if (match) {
          // isEngineRunning = false;
          console.log("moved");
          game.move({ from: match[1], to: match[2], promotion: match[3] });
          setData({
            ...data,
            fen: game.fen(),
            history: [...previousHistory, ...game.history({ verbose: true })],
          });

          historyRef.current = [
            ...previousHistory,
            ...game.history({ verbose: true }),
          ];

          prepareMove();
          uciCmd("eval", evaler);
          //uciCmd("eval");
          /// Is it sending feedback?
        } else if (
          (match = line.match(/^info .*\bdepth (\d+) .*\bnps (\d+)/))
        ) {
          engineStatus.search = "Depth: " + match[1] + " Nps: " + match[2];
        }

        /// Is it sending feed back with a score?
        if ((match = line.match(/^info .*\bscore (\w+) (-?\d+)/))) {
          let score = parseInt(match[2], 10) * (game.turn() === "w" ? 1 : -1);
          /// Is it measuring in centipawns?
          if (match[1] === "cp") {
            engineStatus.score = (score / 100.0).toFixed(2);
            /// Did it find a mate?
          } else if (match[1] === "mate") {
            engineStatus.score = "Mate in " + Math.abs(score);
          }

          /// Is the score bounded?
          if ((match = line.match(/\b(upper|lower)bound\b/))) {
            engineStatus.score =
              ((match[1] === "upper") === (game.turn() === "w")
                ? "<= "
                : ">= ") + engineStatus.score;
          }
        }
      }
    };

    setEngine({
      start: function () {
        uciCmd("ucinewgame");
        uciCmd("isready");
        uciCmd(`setoption name Skill Level value ${skill}`);
        uciCmd("setoption name Skill Level Maximum Error value 900");
        uciCmd("setoption name Skill Level Probability value 1000");
        engineStatus.engineReady = false;
        engineStatus.search = null;
        prepareMove();
        announced_game_over = false;
      },
      prepareMove: function () {
        prepareMove();
      },
      internalEngine: engine,
    });
  }, [gameLoaded]);

  useEffect(() => {
    if (!engine) return;

    window.engine = engine;
    engine.start();
  }, [engine]);

  function onDrop({ sourceSquare, targetSquare }) {
    let turn = game.turn() === "w" ? "white" : "black";

    if (turn !== choice) return;

    // see if the move is legal
    const move = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q",
    });

    // illegal move
    if (move === null) return;

    setStartTimerMove(startTimerMove + 1);

    return new Promise((resolve) => {
      setData({
        ...data,
        fen: game.fen(),
        history: [...previousHistory, ...game.history({ verbose: true })],
      });

      historyRef.current = [
        ...previousHistory,
        ...game.history({ verbose: true }),
      ];

      resolve();
    }).then(() => engine.prepareMove());
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <PlayerName>
        Computer
        {status == "finished" && !winner && (
          <Icon
            name="winner"
            style={{
              paddingLeft: 10,
              color: "#efc424",
              textShadow: "0px 0px 2px #000000",
            }}
          />
        )}
      </PlayerName>
      <div>
        {props.children({
          position: data.fen,
          onDrop: onDrop,
          orientation: choice,
        })}
      </div>
      <PlayerName>
        {display_name}{" "}
        {status == "finished" && winner && (
          <Icon
            name="winner"
            style={{
              paddingLeft: 10,
              color: "#efc424",
              textShadow: "0px 0px 2px #000000",
            }}
          />
        )}
      </PlayerName>
    </div>
  );
}

const PlayerName = styled.div`
  text-align: center;
  font-weight: bold;
  padding: 20px 0px;
  font-size: 20px;
  color: #332b1e;
`;

import { createSlice } from "@reduxjs/toolkit";

import { fetch } from "../api";

const slice = createSlice({
  name: "timezones",
  initialState: {
    timezones: [],
    fetching: true,
    fetched: false,
  },
  reducers: {
    fetched: (state, action) => {
      state.timezones = action.payload;
      state.fetching = false;
      state.fetched = true;
    },
  },
});

export default slice.reducer;
// Actions
const { fetched } = slice.actions;

export const fetchTimeZones = (data) => async (dispatch) => {
  try {
    const response = await fetch({
      endpoint: "/api/v1/timezones",
      data,
    });
    const { timezones } = response.data;

    dispatch(fetched(timezones));
  } catch (e) {
    return console.error(e.message);
  }
};

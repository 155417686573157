import { createSlice } from "@reduxjs/toolkit";

import { get, fetch } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "public_lessons",
  initialState: {
    lesson_id: null,
    lesson: null,
    lessons: [],
    fetch: true,
    getting: false,
    fetched: false,
    fetching: false,
    editing: false,
    updating: false,
    initialized: false,
    open_reward_form: false,
    open_settings_form: false,
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 6,
      sort: { id: "asc" },
      filter: {},
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingLesson: (state) => {
      state.getting = true;
    },
    gotLesson: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.lesson = action.payload;
    },
    fetchedLessons: (state, action) => {
      const { meta, lessons } = action.payload;

      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.pages = meta.pages;
      state.lessons = lessons;
    },
    fetchingLessons: (state) => {
      state.fetching = true;
      state.initialized = true;
    },
    updatedLesson: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.lesson = action.payload;
      state.validations = {};
    },
    updatingLesson: (state) => {
      state.updating = true;
    },
    openedLessonForm: (state, action) => {
      state.open_form = true;
      state.lesson = action.payload;
    },
    closedLessonForm: (state) => {
      state.open_form = false;
      state.lesson = {};
      state.validations = {};
    },
    openedSettingsForm: (state, action) => {
      state.open_settings_form = true;
      state.lesson = action.payload;
    },
    closedSettingsForm: (state) => {
      state.open_settings_form = false;
      state.lesson = {};
      state.validations = {};
    },
    openedModalLesson: (state, action) => {
      state.lesson = action.payload;
      state.open_reward_form = true;
    },
    closedModalLesson: (state, action) => {
      state.lesson = {};
      state.open_reward_form = false;
    },
    resettedLessons: (state) => {
      state.initialized = false;
      state.lessons = [];
    },
    handleSetFilters: (state, action) => {
      state.fetch = true;
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotLesson,
  gettingLesson,
  fetchedLessons,
  fetchingLessons,
  handleSetFilters,
  handleValidations,
} = slice.actions;

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const getLesson = (id) => async (dispatch) => {
  try {
    dispatch(gettingLesson());

    const response = await get({
      endpoint: `/api/v1/public/lessons/${id}`,
    });
    const { lesson } = response.data;

    dispatch(gotLesson(lesson));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchLessons = (data) => async (dispatch) => {
  try {
    dispatch(fetchingLessons());

    const response = await fetch({
      endpoint: `/api/v1/public/lessons`,
      data,
    });

    dispatch(fetchedLessons(response.data));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

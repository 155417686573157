import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import ReactAudioPlayer from "react-audio-player";
import { useSelector, useDispatch } from "react-redux";
import { Icon, Header, Button } from "semantic-ui-react";

import Math from "./Math";
import { updateUserQuiz } from "app/store/user_quizzes";

export default function Questions(props) {
  const dispatch = useDispatch();

  const { dark, locale, quiz_slug, answers, questions } = props;

  const [question, setQuestion] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAudioUrl, setCurrentAudioUrl] = useState();

  useEffect(() => {
    return () => {
      setCurrentIndex(0);
    };
  }, []);

  useEffect(() => {
    setQuestion(questions[currentIndex]);
  }, [currentIndex, questions]);

  if (!question || !quiz_slug) {
    return null;
  }

  const {
    kind,
    title,
    choices,
    audios,
    values,
    template_type,
    translations,
  } = question;
  const audio_url = audios["title"];

  return (
    <QuestionsContainer>
      <Header
        style={{ textAlign: "center", cursor: "pointer" }}
        onMouseOver={() => {
          if (audio_url) {
            setCurrentAudioUrl(audio_url);
          } else {
            setCurrentAudioUrl();
          }
        }}
        onMouseOut={() => {
          setCurrentAudioUrl();
        }}
      >
        {translations["title"] || title}{" "}
        <Icon size="tiny" name="file audio outline" />
      </Header>

      {audio_url && <ReactAudioPlayer src={audio_url} autoPlay />}

      <div className={dark ? "questions" : "questions light"}>
        <div className="question-section">
          <div className="question-count">
            <span>
              {I18n.t("user_quizzes_question_of_total", {
                number: currentIndex + 1,
                total: questions.length,
              })}
            </span>
          </div>
        </div>
        <div className="answer-section">
          {choices.map((choice) => {
            const { id, title, audios, translations } = choice;
            const { id: question_id } = question;
            const answer = answers[question_id] || {};
            const audio_url = audios["text"];

            return (
              <div
                key={`choice-${id}`}
                className={answer.selected == id ? "answer selected" : "answer"}
                onMouseOver={() => {
                  if (audio_url) {
                    setCurrentAudioUrl(audio_url);
                  } else {
                    setCurrentAudioUrl();
                  }
                }}
                onMouseOut={() => {
                  setCurrentAudioUrl();
                }}
                onClick={() => {
                  dispatch(
                    updateUserQuiz(quiz_slug, {
                      answers: {
                        ...answers,
                        [question_id]: {
                          selected: id,
                        },
                      },
                    })
                  ).then(() => {
                    // User deselected choice
                    // if (answer.selected) {
                    //   return;
                    // }
                    // if (currentIndex < questions.length - 1) {
                    //   setTimeout(() => {
                    //     setCurrentIndex(currentIndex + 1);
                    //   }, 500);
                    // }
                  });
                }}
              >
                {answer.selected == id ? (
                  <input
                    key={`choice-checked-${id}`}
                    type={kind == "single_choice" ? "radio" : "checkbox"}
                    checked
                    style={{
                      margin: "4px 4px 4px -4px",
                      width: 14,
                      height: 14,
                    }}
                  />
                ) : (
                  <input
                    key={`choice-unchecked-${id}`}
                    type={kind == "single_choice" ? "radio" : "checkbox"}
                    style={{
                      margin: "4px 4px 4px -4px",
                      width: 14,
                      height: 14,
                    }}
                  />
                )}

                {translations["text"] || title}
              </div>
            );
          })}
        </div>
        {currentIndex !== 0 && (
          <Icon
            inverted
            name="chevron left"
            color={dark ? "violet" : "black"}
            size="big"
            style={{
              position: "absolute",
              bottom: 10,
              left: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              setCurrentIndex(currentIndex - 1);
            }}
          />
        )}

        {questions.length - 1 !== currentIndex && (
          <Icon
            inverted
            name="chevron right"
            color={dark ? "violet" : "black"}
            size="big"
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              cursor: "pointer",
            }}
            onClick={() => {
              setCurrentIndex(currentIndex + 1);
            }}
          />
        )}

        {currentAudioUrl && <ReactAudioPlayer src={currentAudioUrl} autoPlay />}
        <Math question={values} />
      </div>
    </QuestionsContainer>
  );
}

const QuestionsContainer = styled.div`
  .questions {
    background-color: #252d4a;
    width: 100%;
    /* min-height: 200px; */
    height: min-content;
    min-height: 268px;
    border-radius: 19px;
    padding: 20px;
    box-shadow: 0px 3px 9px 0px rgb(0 0 0 / 75%);
    display: flex;
    justify-content: space-evenly;
    position: relative;
    padding-bottom: 80px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    &.light {
      background: white;
      box-shadow: 0px 2px 4px 0px rgb(130 135 138 / 75%);

      .answer-section {
        .answer {
          color: #4e4848;
          background-color: #e8e8e8;
        }

        .selected,
        .selected:hover {
          background-color: #fbfbfb;
          background-color: #deeaad59;
        }

        .answer:hover {
          background-color: #fbfbfb;
        }
      }

      .question-count {
        color: #585a5d;
      }
    }

    .question-section {
      width: 100%;
      position: relative;
    }

    .question-count {
      margin-bottom: 20px;
      color: #c7d5e2;
    }

    .question-count span {
      font-size: 28px;
    }

    .question-text {
      margin-bottom: 12px;
    }

    .timer-text {
      background: rgb(230, 153, 12);
      padding: 15px;
      margin-top: 20px;
      margin-right: 20px;
      border: 5px solid rgb(255, 189, 67);
      border-radius: 15px;
      text-align: center;
    }

    /* ANSWERS/RIGHT SECTION */
    .answer-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .answer {
        width: 100%;
        font-size: 16px;
        color: #ffffff;
        background-color: #252d4a;
        border-radius: 10px;
        display: flex;
        padding: 6px 10px;
        justify-content: flex-start;
        text-align: left;
        border: 2px solid #234668;
        cursor: pointer;
        margin-bottom: 8px;
      }

      .selected,
      .selected:hover {
        background-color: #555e7d;
        background-color: #8e95ad;
        color: #252d4a;
      }

      .incorrect {
        background-color: #ff3333;
      }

      .answer:hover {
        background-color: #555e7d;
      }

      .answer:focus {
        outline: none;
      }

      .answer svg {
        margin-right: 5px;
      }
    }
  }
`;

import React, { useState, useEffect } from "react";

import styled from "styled-components";
import DateTime from "luxon/src/datetime.js";
import { Menu, Icon, Image, Segment, Dropdown } from "semantic-ui-react";

import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { NotificationChannel } from "app/channels";

import logo from "app/assets/images/logo.png";
import download_apple from "app/assets/images/download_apple_black.png";
import download_google from "app/assets/images/google-play-badge.png";

import { setMobileDevice } from "app/store/main";
import { getSettings } from "app/store/settings";
import {
  getMe,
  logout,
  sessionOpen,
  updateTimeZone,
  updateLanguage,
} from "app/store/me";

export default function Topbar(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const mobile = searchParams.get("mobile");

  const {
    user: {
      locale,
      account_id,
      account_type,
      timezone,
      avatar_url,
      short_display_name,
    },
    fetch,
    logged_in,
    login_needed,
  } = useSelector((state) => state.me);

  const { account } = useSelector((state) => state.accounts);
  const { locales, permissions } = useSelector((state) => state.settings);
  const {
    profile_allowed,
    family_allowed,
    tasks_allowed,
    gifts_allowed,
    subscriptions_allowed,
    notifications_allowed,
    payment_methods_allowed,
    account_settings_allowed,
  } = permissions;

  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (logged_in && !timer) {
      setTimer(
        setTimeout(() => {
          dispatch(sessionOpen()).then(() => {
            setTimer(null);
          });
        }, 10000)
      );
    }
    if (login_needed) {
      window.location = "/login";
      // navigate("/login");
    }
  }, [logged_in, login_needed, timer]);

  useEffect(() => {
    dispatch(getMe());
  }, [logged_in, fetch, account]);

  useEffect(() => {
    const { zoneName } = DateTime.local();

    if (!logged_in || timezone === zoneName) return;

    setTimeout(() => {
      dispatch(updateTimeZone({ timezone: zoneName }));
    }, 2000);
  }, [logged_in, timezone]);

  useEffect(() => {
    dispatch(getSettings());
  }, [logged_in, account_id, account_type]);

  useEffect(() => {
    if (mobile) {
      dispatch(setMobileDevice(mobile));
    }
  }, [mobile]);

  function navigateFromUserDropdown(event, { value }) {
    navigate(value);
  }

  if (mobile) {
    return null;
  }

  return (
    <Segment
      className="top-bar"
      style={{
        margin: 0,
        padding: "0px 10px",
        border: "none",
        boxShadow: "none",
        background: "#f1f1f1",
      }}
    >
      {false && logged_in && <NotificationChannel />}
      <Menu secondary>
        <Menu.Item style={{ padding: 0, margin: "0px 15px 0px 0px" }}>
          <Link to="/" style={{ display: "flex", alignItems: "center" }}>
            <Image src={logo} size="mini" style={{ margin: 0 }} />
            <span
              style={{
                marginLeft: 4,
                fontWeight: "bold",
                color: "#363254",
                fontSize: "1.6em",
              }}
            >
              Clevers
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item style={{ padding: 0 }}>
          <Icon name="world" color="red" />
          <Dropdown
            inline
            value={locale}
            options={locales}
            defaultValue={locale}
            onChange={(event, { value }) => {
              dispatch(updateLanguage(value));
            }}
          />
        </Menu.Item>

        <Menu.Menu position="right">
          {false && (
            <>
              <Menu.Item style={{ padding: 0 }}>
                <Image
                  src={download_apple}
                  width="100px"
                  as="a"
                  href="/"
                  // target="_blank"
                />
              </Menu.Item>
              <Menu.Item style={{ padding: 0 }}>
                <Image
                  src={download_google}
                  width="100px"
                  as="a"
                  href="/"
                  // target="_blank"
                />
              </Menu.Item>
            </>
          )}

          {false && (
            <Menu.Item style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Link to="/help" style={{ color: "#40425a" }}>
                HELP
              </Link>
            </Menu.Item>
          )}
        </Menu.Menu>

        {!logged_in && (
          <Menu.Menu>
            <Menu.Item
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                width: 1,
                background: "gray",
              }}
            ></Menu.Item>

            <Menu.Item style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Link
                to="/signup"
                className="uppercase"
                style={{ color: "#40425a" }}
              >
                Sign up
              </Link>
            </Menu.Item>
            <Menu.Item style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Link
                to="/login"
                className="uppercase"
                style={{ color: "#40425a" }}
              >
                Log in
              </Link>
            </Menu.Item>
          </Menu.Menu>
        )}

        {logged_in && (
          <Menu.Menu>
            <Menu.Item>
              <Dropdown
                trigger={
                  <span>
                    {avatar_url ? (
                      <Image
                        src={avatar_url}
                        size="small"
                        circular
                        style={{
                          width: 28,
                          height: 28,
                          display: "inline",
                          marginRight: 4,
                          boxShadow: "0px 0px 3px #a9b6bf",
                        }}
                      />
                    ) : (
                      <Icon name="user" style={{ fontSize: "1.2em" }} />
                    )}

                    {short_display_name}
                  </span>
                }
                pointing="top right"
              >
                <Dropdown.Menu>
                  {profile_allowed && (
                    <Dropdown.Item
                      icon="user"
                      value="/account_settings/profile"
                      text="Profile"
                      onClick={navigateFromUserDropdown}
                    />
                  )}
                  {family_allowed && (
                    <Dropdown.Item
                      value="/account_settings/family"
                      icon="group"
                      text="Family"
                      onClick={navigateFromUserDropdown}
                    />
                  )}

                  {tasks_allowed && (
                    <Dropdown.Item
                      value="/account_settings/tasks"
                      icon="clipboard list"
                      text="Tasks"
                      onClick={navigateFromUserDropdown}
                    />
                  )}

                  {gifts_allowed && (
                    <Dropdown.Item
                      value="/account_settings/gifts"
                      icon="tasks"
                      text="Gifts"
                      onClick={navigateFromUserDropdown}
                    />
                  )}

                  {notifications_allowed && (
                    <Dropdown.Item
                      value="/account_settings/notifications"
                      icon="alarm"
                      text="Notifications"
                      onClick={navigateFromUserDropdown}
                    />
                  )}

                  {false && subscriptions_allowed && (
                    <Dropdown.Item
                      value="/account_settings/subscriptions"
                      icon="hand point up"
                      text="Subscriptions"
                      onClick={navigateFromUserDropdown}
                    />
                  )}

                  {false && payment_methods_allowed && (
                    <Dropdown.Item
                      value="/account_settings/payment_methods"
                      icon="payment"
                      text="Payment Methods"
                      onClick={navigateFromUserDropdown}
                    />
                  )}

                  {account_settings_allowed && (
                    <Dropdown.Item
                      value="/account_settings/edit"
                      icon="edit"
                      text="Account Settings"
                      onClick={navigateFromUserDropdown}
                    />
                  )}
                  <Dropdown.Divider />

                  <Dropdown.Item
                    style={{
                      textAlign: "center",
                      margin: "10px 0px 0px 0px",
                      padding: 0,
                    }}
                    onClick={() => dispatch(logout())}
                  >
                    <SignOut>Sign out</SignOut>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        )}
      </Menu>
    </Segment>
  );
}

const SignOut = styled.div`
  padding: 10px;
  margin: -16px;
  background: #00b5ad;
  color: white;

  &:hover {
    background: #48b3ae;
  }
`;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "app/utils";

const slice = createSlice({
  name: "plans",
  initialState: {
    plan: {},
    plans: [],
    byPlanId: {},
    selectedPlan: null,
    meta: {},
    fetch: true,
    getting: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    destroying: false,
    initialized: false,
    open_form: false,
    open_settings_form: false,
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 6,
      sort: { id: "asc" },
      filter: { interval: "monthly" },
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingPlan: (state) => {
      state.getting = true;
    },
    gotPlan: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.plan = action.payload;
    },
    fetchedPlans: (state, action) => {
      const { plans, meta } = action.payload;

      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.plans = plans;
      state.meta = meta;
      state.byPlanId = plans.reduce((previous, current) => {
        previous[current.id] = current;
        return previous;
      }, {});
    },
    fetchingPlans: (state) => {
      state.fetching = true;
    },
    openingPlanForm: (state, action) => {
      state.open_form = true;
      state.plan = action.payload;
      state.validations = {};
    },
    closedPlanForm: (state) => {
      state.open_form = false;
      state.plan = {};
      state.validations = {};
    },
    openingSettingsForm: (state, action) => {
      state.validations = {};
      state.plan = action.payload;
      state.open_settings_form = true;
    },
    closedSettingsForm: (state) => {
      state.plan = {};
      state.validations = {};
      state.open_settings_form = false;
    },
    createdPlan: (state, action) => {
      state.fetch = true;
      state.creating = false;
      state.open_form = false;
      state.validations = {};
      state.plan = action.payload;
      state.plans = [...state.plans, action.payload];
    },
    creatingPlan: (state) => {
      state.creating = true;
    },
    updatedPlan: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.validations = {};
      state.plan = action.payload;
      state.plans = [
        ...state.plans.filter((plan) => plan.id !== action.payload.id),
        action.payload,
      ];
    },
    updatingPlan: (state) => {
      state.updating = true;
    },
    destroyedPlan: (state, action) => {
      const plans = [
        ...state.plans.filter((plan) => plan.id !== action.payload.id),
      ];
      state.fetch = true;
      state.destroying = false;
      state.validations = {};
      state.open_settings_form = false;
      state.plans = plans;

      const { filters } = state;
      const isEmptyList = plans.length === 0;

      if (isEmptyList && filters.page > 1) {
        state.filters = { ...filters, page: filters.page - 1 };
      }
    },
    destroyingPlan: (state) => {
      state.destroying = true;
    },
    fetchedMeta: (state, action) => {
      state.meta = action.payload;
      state.pages = action.payload.pages;
    },
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action.payload;
    },
    handleSetFilters: (state, action) => {
      state.fetch = true;
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotPlan,
  gettingPlan,
  setSelectedPlan,
  openingPlanForm,
  closedPlanForm,
  openingSettingsForm,
  closedSettingsForm,
  fetchedPlans,
  fetchingPlans,
  createdPlan,
  creatingPlan,
  updatedPlan,
  updatingPlan,
  destroyedPlan,
  destroyingPlan,
  fetchedMeta,
  handleSetFilters,
  handleValidations,
} = slice.actions;

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const selectPlan = (plan) => async (dispatch) => {
  dispatch(setSelectedPlan(plan));
};

export const openForm = (plan = {}) => async (dispatch) => {
  dispatch(openingPlanForm(plan));
};

export const closeForm = () => async (dispatch) => {
  dispatch(closedPlanForm());
};

export const openSettingsForm = (plan = {}) => async (dispatch) => {
  dispatch(openingSettingsForm(plan));
};

export const closeSettingsForm = () => async (dispatch) => {
  dispatch(closedSettingsForm());
};

export const getPlan = (id) => async (dispatch) => {
  try {
    dispatch(gettingPlan());

    const response = await get({
      endpoint: `/api/v1/plans/${id}`,
    });
    const { plan } = response.data;

    dispatch(gotPlan(plan));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchPlans = (data) => async (dispatch) => {
  try {
    dispatch(fetchingPlans());

    const response = await fetch({
      endpoint: `/api/v1/plans`,
      data,
    });
    const { plans, meta } = response.data;

    dispatch(fetchedMeta(meta));
    dispatch(fetchedPlans({ plans: plans, meta }));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createPlan = (data) => async (dispatch) => {
  try {
    dispatch(creatingPlan());

    const response = await create({
      endpoint: `/api/v1/plans`,
      data: { plan: data },
    });
    const { plan } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdPlan(plan));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updatePlan = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingPlan());

    const response = await update({
      endpoint: `/api/v1/plans/${id}`,
      data: { plan: data },
    });
    const { plan } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedPlan(plan));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const destroyPlan = (id, data) => async (dispatch) => {
  try {
    dispatch(destroyingPlan());

    const response = await destroy({
      endpoint: `/api/v1/plans/${id}`,
      data: { plan: data },
    });
    const { plan } = response.data;

    toastDeleteSuccessMessage();
    dispatch(destroyedPlan(plan));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

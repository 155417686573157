import React from "react";

import Lottie from "react-lottie";

export default function EmptyLottieView(props) {
  const {
    title,
    style,
    speed = 1,
    loop = false,
    autoplay = false,
    animation,
    options = {},
    segments = [],
  } = props;

  return (
    <>
      <h3 className="text-center">{title}</h3>
      <Lottie
        speed={speed}
        isClickToPauseDisabled
        segments={segments}
        options={{
          loop,
          autoplay,
          animationData: animation,
          ...options,
        }}
        style={{
          width: "40%",
          height: "100%",
          alignSelf: "center",
          marginTop: 0,
          marginLeft: "auto",
          marginRight: "auto",
          zIndex: 1,
          textAlign: "center",
          ...style,
        }}
        // isStopped={false}
        isPaused={false}
      />
    </>
  );
}

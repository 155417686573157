import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update, destroy } from "../api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "app/utils";

const slice = createSlice({
  name: "kids",
  initialState: {
    kid: {},
    kids: [],
    byKidId: {},
    selectedKid: null,
    meta: { grade_levels: [] },
    fetch: true,
    getting: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    destroying: false,
    initialized: false,
    open_form: false,
    open_settings_form: false,
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 6,
      sort: { id: "asc" },
      filter: {},
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingKid: (state) => {
      state.getting = true;
    },
    gotKid: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.kid = action.payload;
    },
    fetchedKids: (state, action) => {
      const { kids, meta } = action.payload;

      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.kids = kids;
      state.meta = meta;
      state.byKidId = kids.reduce((previous, current) => {
        previous[current.id] = current;
        return previous;
      }, {});
    },
    fetchingKids: (state) => {
      state.fetching = true;
    },
    openingKidForm: (state, action) => {
      state.open_form = true;
      state.kid = action.payload;
      state.validations = {};
    },
    closedKidForm: (state) => {
      state.open_form = false;
      state.kid = {};
      state.validations = {};
    },
    openingSettingsForm: (state, action) => {
      state.validations = {};
      state.kid = action.payload;
      state.open_settings_form = true;
    },
    closedSettingsForm: (state) => {
      state.kid = {};
      state.validations = {};
      state.open_settings_form = false;
    },
    createdKid: (state, action) => {
      state.fetch = true;
      state.creating = false;
      state.open_form = false;
      state.validations = {};
      state.kid = action.payload;
      state.kids = [...state.kids, action.payload];
    },
    creatingKid: (state) => {
      state.creating = true;
    },
    updatedKid: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.validations = {};
      state.kid = action.payload;
      state.kids = [
        ...state.kids.filter((kid) => kid.id !== action.payload.id),
        action.payload,
      ];
    },
    updatingKid: (state) => {
      state.updating = true;
    },
    destroyedKid: (state, action) => {
      const kids = [
        ...state.kids.filter((kid) => kid.id !== action.payload.id),
      ];
      state.fetch = true;
      state.destroying = false;
      state.validations = {};
      state.open_settings_form = false;
      state.kids = kids;

      const { filters } = state;
      const isEmptyList = kids.length === 0;

      if (isEmptyList && filters.page > 1) {
        state.filters = { ...filters, page: filters.page - 1 };
      }
    },
    destroyingKid: (state) => {
      state.destroying = true;
    },
    fetchedMeta: (state, action) => {
      state.meta = action.payload;
      state.pages = action.payload.pages;
    },
    setSelectedKid: (state, action) => {
      state.selectedKid = action.payload;
    },
    handleSetFilters: (state, action) => {
      state.fetch = true;
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotKid,
  gettingKid,
  setSelectedKid,
  openingKidForm,
  closedKidForm,
  openingSettingsForm,
  closedSettingsForm,
  fetchedKids,
  fetchingKids,
  createdKid,
  creatingKid,
  updatedKid,
  updatingKid,
  destroyedKid,
  destroyingKid,
  fetchedMeta,
  handleSetFilters,
  handleValidations,
} = slice.actions;

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const selectKid = (kid) => async (dispatch) => {
  dispatch(setSelectedKid(kid));
};

export const openForm = (kid = {}) => async (dispatch) => {
  dispatch(openingKidForm(kid));
};

export const closeForm = () => async (dispatch) => {
  dispatch(closedKidForm());
};

export const openSettingsForm = (kid = {}) => async (dispatch) => {
  dispatch(openingSettingsForm(kid));
};

export const closeSettingsForm = () => async (dispatch) => {
  dispatch(closedSettingsForm());
};

export const getKid = (id) => async (dispatch, getState) => {
  try {
    dispatch(gettingKid());

    const state = getState();
    const { account_id } = state.me;

    const response = await get({
      endpoint: `/api/v1/kids/${id}`,
    });
    const { user } = response.data;

    dispatch(gotKid(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchKids = (data) => async (dispatch) => {
  try {
    dispatch(fetchingKids());

    const response = await fetch({
      endpoint: `/api/v1/kids`,
      data,
    });
    const { users, meta } = response.data;

    dispatch(fetchedMeta(meta));
    dispatch(fetchedKids({ kids: users, meta }));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createKid = (data) => async (dispatch, getState) => {
  try {
    dispatch(creatingKid());

    const state = getState();
    const { account_id } = state.me;
    const response = await create({
      endpoint: `/api/v1/kids`,
      data: { kid: data },
    });
    const { user } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdKid(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateKid = (id, data) => async (dispatch, getState) => {
  try {
    dispatch(updatingKid());

    const state = getState();
    const { account_id } = state.me;
    const response = await update({
      endpoint: `/api/v1/kids/${id}`,
      data: { kid: data },
    });
    const { user } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedKid(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const destroyKid = (id, data) => async (dispatch, getState) => {
  try {
    dispatch(destroyingKid());

    const state = getState();
    const { account_id } = state.me;
    const response = await destroy({
      endpoint: `/api/v1/kids/${id}`,
      data: { kid: data },
    });
    const { user } = response.data;

    toastDeleteSuccessMessage();
    dispatch(destroyedKid(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

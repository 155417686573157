import { createSlice } from "@reduxjs/toolkit";

import { fetch } from "../api";

const slice = createSlice({
  name: "help_pages",
  initialState: {
    help_pages: [],
    fetching: true,
    fetched: false,
  },
  reducers: {
    fetched: (state, action) => {
      state.help_pages = action.payload;
      state.fetching = false;
      state.fetched = true;
    },
  },
});

export default slice.reducer;
// Actions
const { fetched } = slice.actions;

export const fetchHelpPages = (data) => async (dispatch) => {
  try {
    const response = await fetch({
      endpoint: "/api/v1/help_pages",
      data,
    });
    const { help_pages } = response.data;

    dispatch(fetched(help_pages));
  } catch (e) {
    return console.error(e.message);
  }
};

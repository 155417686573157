import React, { useState, useEffect } from "react";

import Lottie from "react-lottie";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import Status from "./Status";
import SocialLogin from "app/components/social_login";

import * as signupLottie from "../../assets/lottie/signup.json";

import config from "app/config";
import { signup } from "app/store/me";

export default function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { csrf_token } = useSelector((state) => state.csrf_token);
  const { user, logged_in, signup_inprogress, validations } = useSelector(
    (state) => state.me
  );
  const [form, setForm] = useState({
    email: "",
    password: "",
    fullname: "",
    agree_to_terms: false,
    agree_to_promotions: false,
  });

  useEffect(() => {
    if (logged_in) {
      navigate("/");
    }
  }, [logged_in]);

  return (
    <>
      <Status />
      <Grid textAlign="center" verticalAlign="middle" style={{ padding: 0 }}>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Lottie
            width={240}
            height={240}
            options={{
              loop: true,
              autoplay: true,
              animationData: signupLottie.default,
            }}
            isStopped={false}
            isPaused={false}
          />
          <Form
            size="large"
            style={{
              textAlign: "center",
            }}
            loading={signup_inprogress}
          >
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Full Name"
                onChange={(event) => {
                  setForm({ ...form, fullname: event.target.value });
                }}
                error={
                  validations.fullname
                    ? {
                        content: validations.fullname,
                      }
                    : false
                }
              />
              <Form.Input
                fluid
                icon="mail"
                iconPosition="left"
                placeholder="Email"
                onChange={(event) => {
                  setForm({ ...form, email: event.target.value });
                }}
                error={
                  validations.email
                    ? {
                        content: validations.email,
                      }
                    : false
                }
              />
              <Form.Input
                fluid
                icon="lock"
                type="password"
                iconPosition="left"
                placeholder="Password"
                autoComplete="new-password"
                onChange={(event) => {
                  setForm({ ...form, password: event.target.value });
                }}
                error={
                  validations.password
                    ? {
                        content: validations.password,
                      }
                    : false
                }
              />

              <Form.Checkbox
                checked={form.agree_to_promotions}
                onChange={(event) => {
                  setForm({
                    ...form,
                    agree_to_promotions: !form.agree_to_promotions,
                  });
                }}
                label="I agree to receive promotional emails and discounts"
              />

              <Form.Checkbox
                checked={form.agree_to_terms}
                onChange={(event) => {
                  setForm({
                    ...form,
                    agree_to_terms: !form.agree_to_terms,
                  });
                }}
                label={
                  <label>
                    I accept the{" "}
                    <a href="/terms" target="_blank">
                      Terms of Service
                    </a>
                  </label>
                }
                error={validations.agree_to_terms}
              />

              <Button
                color="teal"
                fluid
                size="large"
                onClick={() => {
                  dispatch(
                    signup({
                      user: form,
                      account: { name: form.fullname, type: "family" },
                    })
                  );
                }}
              >
                Sign up
              </Button>
            </Segment>
          </Form>
          {false && (
            <SocialLogin
              url={config.url}
              text="Sign up with"
              csrf_token={csrf_token}
            />
          )}
          <Message
            style={{
              color: "#474a4e",
              fontWeight: "bold",
            }}
          >
            Have an account? <Link to="/login">Login</Link>
          </Message>
        </Grid.Column>
      </Grid>
    </>
  );
}

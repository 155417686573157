import React from "react";

import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";

import store from "app/store";
import config from "app/config";
import Routes from "app/routes";
import NavBar from "app/screens/nav_bar";
// import Footer from "app/screens/footer";

import { AppContainer } from "app/styles";
import { ActionCableProvider } from "app/cable";
import SpeechReader from "app/components/SpeechReader";
import SpeechRecognition from "app/components/SpeechRecognition";
import VideoPlayerModal from "app/components/VideoPlayerModal";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faAppleAlt,
  faIceCream,
  faCookie,
} from "@fortawesome/free-solid-svg-icons";

import "app/assets/styles/index.css";

library.add(fab, faAppleAlt, faIceCream, faCookie);

export default function App() {
  return (
    <ActionCableProvider url={config.cable_url}>
      <AppContainer>
        <Provider store={store}>
          <CookiesProvider>
            <Router>
              <NavBar />
              <Routes />
              <ToastContainer />
              <VideoPlayerModal />
            </Router>
          </CookiesProvider>
        </Provider>
      </AppContainer>
    </ActionCableProvider>
  );
}

// After App container
// <StripeProvider apiKey="null">
// </StripeProvider>

import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "app/cookie";
import { get, fetch, create, update, destroy } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "user_chess_learns",
  initialState: {
    user_chess_learn: null,
    user_chess_learns: [],
    getting: false,
    initialized: false,
    fetch: true,
    fetched: false,
    fetching: false,
    editing: false,
    created: false,
    creating: false,
    updating: false,
    deleting: false,
    validations: {},
  },
  reducers: {
    gettingUserChessLearn: (state) => {
      state.getting = true;
    },
    gotUserChessLearn: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.user_chess_learn = action.payload;
    },
    fetchedUserChessLearns: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.user_chess_learns = action.payload;
    },
    fetchingUserChessLearns: (state) => {
      state.fetching = true;
    },
    createdUserChessLearn: (state, action) => {
      state.creating = false;
      state.created = true;
      state.validations = {};
      state.user_chess_learn = action.payload;
      state.user_chess_learns = [...state.user_chess_learns, action.payload];
    },
    creatingUserChessLearn: (state) => {
      state.creating = true;
    },
    updatedUserChessLearn: (state, action) => {
      const { user_chess_learns } = state;
      const user_chess_learn = action.payload;

      state.updating = false;
      state.validations = {};
      state.user_chess_learn = user_chess_learn;

      const index = user_chess_learns.findIndex(
        (uq) => uq.id == user_chess_learn.id
      );

      if (index == -1) {
        state.user_chess_learns = [...user_chess_learns, user_chess_learn];
      } else {
        user_chess_learns[index] = user_chess_learn;
        state.user_chess_learns = [...user_chess_learns];
      }
    },
    updatingUserChessLearn: (state) => {
      state.updating = true;
    },
    deletedUserChessLearn: (state, action) => {
      state.deleting = false;
      state.validations = {};
      state.user_chess_learns = [
        ...state.user_chess_learns.filter(
          (user_chess_learn) => user_chess_learn.id !== action.payload.id
        ),
      ];
    },
    deletingUserChessLearn: (state) => {
      state.deleting = true;
    },
    resettedUserChessLearn: (state) => {
      state.user_chess_learn = null;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.creating = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotUserChessLearn,
  gettingUserChessLearn,
  fetchedUserChessLearns,
  fetchingUserChessLearns,
  createdUserChessLearn,
  creatingUserChessLearn,
  updatedUserChessLearn,
  updatingUserChessLearn,
  deletedUserChessLearn,
  deletingUserChessLearn,
  resettedUserChessLearn,
  handleValidations,
} = slice.actions;

export const resetUserChessLearn = () => async (dispatch) => {
  dispatch(resettedUserChessLearn());
};

export const getUserChessLearn = (id) => async (dispatch) => {
  try {
    dispatch(gettingUserChessLearn());

    const response = await get({
      endpoint: `/api/v1/user_chess_learns/${id}`,
    });
    const { user_chess_learn } = response.data;

    dispatch(gotUserChessLearn(user_chess_learn));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchUserChessLearns = (data) => async (dispatch) => {
  try {
    dispatch(fetchingUserChessLearns());

    const response = await fetch({
      endpoint: `/api/v1/user_chess_learns`,
      data,
    });
    const { user_chess_learns } = response.data;

    dispatch(fetchedUserChessLearns([...user_chess_learns]));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createUserChessLearn = (data) => async (dispatch) => {
  try {
    dispatch(creatingUserChessLearn());

    const response = await create({
      endpoint: `/api/v1/user_chess_learns`,
      data: { user_chess_learn: data },
    });
    const { user_chess_learn } = response.data;

    dispatch(createdUserChessLearn(user_chess_learn));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateUserChessLearn = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingUserChessLearn());

    const response = await update({
      endpoint: `/api/v1/user_chess_learns/${id}`,
      data: { user_chess_learn: data },
    });
    const { user_chess_learn } = response.data;

    dispatch(updatedUserChessLearn(user_chess_learn));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const deleteUserChessLearn = (id, data) => async (dispatch) => {
  try {
    dispatch(deletingUserChessLearn());

    const response = await destroy({
      endpoint: `/api/v1/user_chess_learns/${id}`,
      data: { user_chess_learn: data },
    });
    const { user_chess_learn } = response.data;

    dispatch(deletedUserChessLearn(user_chess_learn));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import shared from "./shared";
import globals from "./globals";
import family from "./family";
import teams from "./teams";
import locale from "./locale";
import profile from "./profile";
import preference from "./preference";
import users from "./users";
import assignments from "./assignments";
import account_settings from "./account_settings";
import user_quizzes from "./user_quizzes";

export const en = {
  ...shared,
  ...globals,
  ...family,
  ...teams,
  ...locale,
  ...users,
  ...profile,
  ...preference,
  ...assignments,
  ...account_settings,
  ...user_quizzes,
};

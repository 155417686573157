import React, { useState, useEffect } from "react";

import styled from "styled-components";

const Timer = (props) => {
  const { timespent = 0, stop } = props;

  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [hour, setHour] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(timespent);

  const [secondsPassed, setSecondsPassed] = useState(0);

  useEffect(() => {
    setIsActive(!stop);
  }, [stop]);

  useEffect(() => {
    setCounter(timespent);
  }, [timespent]);

  useEffect(() => {
    const secondCounter = counter % 60;
    const hourCounter = Math.floor(counter / 60 / 60);
    const minuteCounter = Math.floor(counter / 60) - hourCounter * 60;

    let computedSecond =
      String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
    let computedMinute =
      String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

    let computedHour = hourCounter === 0 ? `00` : hourCounter;

    setSecond(computedSecond);
    setMinute(computedMinute);
    setHour(computedHour);
  }, [counter]);

  useEffect(() => {
    if (!isActive || secondsPassed < 20) return;

    setSecondsPassed(0);
  }, [isActive, secondsPassed]);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        setCounter((counter) => counter + 1);
        setSecondsPassed((secondsPassed) => secondsPassed + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }

  return (
    <Wrapper>
      {hour !== "00" ? (
        <Time>
          <span class="hour">{hour}</span>
          <span>:</span>
          <span class="minute">{minute}</span>
          <span>:</span>
          <span class="second">{second}</span>
        </Time>
      ) : (
        <Time>
          <span class="minute">{minute}</span>
          <span>:</span>
          <span class="second">{second}</span>
        </Time>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  place-items: center;
  padding: 7px;
  background: #626e7d;
  border-radius: 6px;
`;

const Time = styled.div`
  font-size: 1.4rem;
  color: #dedede;
`;

export default Timer;

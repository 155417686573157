import React, { useEffect, useState } from "react";

import I18n from "i18n-js";
import moment from "moment-timezone";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import {
  Label,
  Button,
  Menu,
  Icon,
  Message,
  Segment,
  Popup,
} from "semantic-ui-react";

import Gifts from "app/components/Gifts";

import { setFilters } from "app/store/tasks";

export default function Status(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { date_filters } = useSelector((state) => state.globals);

  const start_date = moment(date_filters.start_date);
  const end_date = moment(date_filters.end_date);
  const [dateRange, onChange] = useState([start_date, end_date]);

  const {
    user: { locale, points },
    logged_in,
  } = useSelector((state) => state.me);

  useEffect(() => {
    const start_date = moment(dateRange[0]).format("YYYY-MM-DD");
    const end_date = moment(dateRange[1]).format("YYYY-MM-DD");

    dispatch(
      setFilters({
        filter: {
          range: [start_date, end_date],
        },
      })
    );
  }, [dateRange]);

  return (
    <Segment
      style={{
        margin: 0,
        padding: 0,
        position: "sticky",
        top: 0,
        zIndex: 10,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Menu secondary style={{ width: "100%" }}>
        <Menu.Item style={{ padding: "0px 0px 0px 4px" }}>
          <Button as="div" labelPosition="right">
            <Button
              icon
              style={{
                display: "flex",
                alignItems: "center",
                padding: "3px 10px 3px 6px",
              }}
            >
              {I18n.t("your_points")}
            </Button>
            <Label
              as="a"
              basic
              pointing="left"
              style={{ color: "#96990d", fontWeight: "bold" }}
            >
              {points}
            </Label>
          </Button>
        </Menu.Item>
        <Menu.Item style={{ padding: "0px 0px 0px 4px" }}>
          <Gifts />
        </Menu.Item>

        <Menu.Menu position="right">
          <Menu.Item style={{ padding: "0px 0px 0px 4px" }}>
            <DateRangePicker
              locale={locale}
              onChange={onChange}
              value={dateRange}
              clearIcon={null}
            />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </Segment>
  );
}

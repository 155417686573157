import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ExplainSimpleAnswers(props) {
  const { ref, refs, answers } = props;

  function getIcon(value) {
    switch (value) {
      case "apple":
        return "apple-alt";
        break;
      case "ice-cream":
        return "ice-cream";
        break;
      default:
        return "apple-alt";
    }
  }

  return (
    <AnswerWrapper>
      <Description ref={(ref) => (refs.current["answers"] = ref)}>
        {answers.map((answer) => {
          const { id, type, value } = answer;

          const icon = getIcon(value);

          switch (type) {
            case "constant":
              return (
                <div
                  id={id}
                  className="constant"
                  ref={(ref) => (refs.current[id] = ref)}
                >
                  <FontAwesomeIcon
                    icon={icon}
                    size="xs"
                    color="green"
                    style={{
                      transition: "transform 330ms ease-in-out",
                    }}
                  />
                </div>
              );
              break;
            case "operator":
              return (
                <div
                  id={id}
                  className="operator"
                  ref={(ref) => (refs.current[id] = ref)}
                >
                  {value}
                </div>
              );
              break;
            case "equal":
              return (
                <div id={id} className="equal">
                  {value}
                </div>
              );
              break;
            case "coeficient":
              return (
                <div id={id} className="coeficient">
                  {value}
                </div>
              );
              break;
            case "variable":
              return (
                <div id={id} className="variable">
                  {value}
                </div>
              );
              break;
            case "answer":
              return (
                <div id={id} className="answer">
                  {value}
                </div>
              );
              break;
            default:
          }
        })}
      </Description>
    </AnswerWrapper>
  );
}

const AnswerWrapper = styled.div`
  width: 100%;
  display: flex;
  position: unset;

  .constant {
    margin: 0px 2px;
    display: inline-block;
    visibility: hidden;
  }
  .operator {
    margin: 0px;
    padding: 0px;
    font-size: 30px;
    height: 2px;
    display: block;
    visibility: hidden;
  }
  .equal {
    margin: 4px 4px;
    color: black;
  }
  .coeficient {
    margin: 4px 4px;
  }
  .variable {
    margin: 4px 4px;
    color: #839747;
  }
`;

const Description = styled.div`
  position: unset;
`;

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { fetch } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "voice_feedbacks",
  initialState: {
    voice_feedback: {},
    voice_feedbacks: [],
    key_by_voices: {},
    validations: {},
  },
  reducers: {
    fetchedVoiceFeedbacks: (state, action) => {
      state.voice_feedbacks = action.payload;
      state.key_by_voices = action.payload.reduce((previous, current) => {
        previous[current.id] = current.audio.message;
        return previous;
      }, {});
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const { fetchedVoiceFeedbacks, handleValidations } = slice.actions;

export const fetchVoiceFeedbacks = (data) => async (dispatch) => {
  try {
    const response = await fetch({
      endpoint: `/api/v1/voice_feedbacks`,
      data,
    });
    const { voice_feedbacks } = response.data;

    dispatch(fetchedVoiceFeedbacks(voice_feedbacks));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { create } from "app/api";
import { handleMeResponse, handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "questions/synthesis",
  initialState: {
    synthesis: {
      steps: [],
      answers: [],
      questions: [],
      remainders: [],
    },
    validations: {},
  },
  reducers: {
    settingSynthesis: (state, action) => {
      state.synthesis = action.payload;
    },
    settingSimpleSynthesis: (state, action) => {
      const { steps, answers, questions } = action.payload;

      state.synthesis = {
        steps,
        answers,
        questions,
        remainders: [],
      };
    },
    resettedSynthesis: (state) => {
      state.synthesis = {
        steps: [],
        answers: [],
        questions: [],
        remainders: [],
      };
    },
    handleValidations: (state, action) => {
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  settingSynthesis,
  resettedSynthesis,
  settingSimpleSynthesis,
  handleValidations,
} = slice.actions;

export const resetSynthesis = () => async (dispatch) => {
  dispatch(resettedSynthesis());
};

export const createSynthesis = (question_id, locale) => async (dispatch) => {
  try {
    const response = await create({
      endpoint: `/api/v1/questions/${question_id}/synthesis`,
      data: { locale },
    });

    dispatch(settingSynthesis(response.data));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createSimpleSynthesis = (question_id, locale) => async (
  dispatch
) => {
  try {
    const response = await create({
      endpoint: `/api/v1/questions/${question_id}/simple_synthesis`,
      data: { locale },
    });

    dispatch(settingSimpleSynthesis(response.data));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

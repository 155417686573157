import React, { useState, useEffect } from "react";

import Lottie from "react-lottie";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Input,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import * as signup from "../../assets/lottie/signup.json";
import { acceptInvitation } from "../../store/me";

export default function AcceptInvitation() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { logged_in, validations } = useSelector((state) => state.me);

  const search = location.search;
  const params = new URLSearchParams(search);
  const invitation_token = params.get("invitation_token");
  const [data, setData] = useState({
    password: "",
    password_confirmation: "",
    invitation_token,
    agree_to_terms: true,
    agree_to_promotions: false,
  });

  const [agreeToPromotions, setAgreeToPromotions] = useState(false);

  useEffect(() => {
    if (!invitation_token) {
      navigate("/");
    }
  }, [invitation_token]);

  useEffect(() => {
    if (logged_in) {
      navigate("/");
    }
  }, [logged_in]);

  return (
    <Grid textAlign="center" verticalAlign="middle" style={{ padding: 0 }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Lottie
          width={240}
          height={240}
          options={{
            loop: true,
            autoplay: true,
            animationData: signup.default,
          }}
          isStopped={false}
          isPaused={false}
        />
        <Form size="large" loading={false}>
          <Segment stacked>
            <Form.Input
              fluid
              // required
              icon="lock"
              type="password"
              value={data.password}
              iconPosition="left"
              placeholder="Password"
              onChange={(event) => {
                setData({ ...data, password: event.target.value });
              }}
              error={
                validations.password
                  ? {
                      content: validations.password,
                    }
                  : false
              }
            />

            <Form.Input
              fluid
              // required
              icon="lock"
              type="password"
              value={data.password_confirmation}
              iconPosition="left"
              placeholder="Password Confirmation"
              onChange={(event) => {
                setData({ ...data, password_confirmation: event.target.value });
              }}
              error={
                validations.password_confirmation
                  ? {
                      content: validations.password_confirmation,
                    }
                  : false
              }
            />

            <Form.Checkbox
              defaultIndeterminate
              checked={agreeToPromotions}
              onChange={() => {
                setAgreeToPromotions(!agreeToPromotions);
                setData({ ...data, agree_to_promotions: !agreeToPromotions });
              }}
              label="I agree to receive promotional emails and discounts"
            />

            <Button
              color="teal"
              fluid
              size="large"
              onClick={() => {
                dispatch(acceptInvitation(data));
              }}
            >
              Accept
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}

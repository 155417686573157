import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import { Button } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";

export default function Formula(props) {
  const dispatch = useDispatch();
  const { formula } = props;

  const { type, variables } = formula;

  return (
    <FormulaWrapper>
      <Description>
        {variables.map((variable) => {
          const { type, value } = variable;

          switch (type) {
            case "constant":
              return <span className="constant">{value}</span>;
              break;
            case "operator":
              return <span className="operator">{value}</span>;
              break;
            case "equal":
              return <span className="equal">{value}</span>;
              break;
            case "coeficient":
              return <span className="coeficient">{value}</span>;
              break;
            case "variable":
              return <span className="variable">{value}</span>;
              break;
            default:
          }
          return variable.value;
        })}
      </Description>
    </FormulaWrapper>
  );
}

const FormulaWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;

  .constant {
    margin: 4px 4px;
  }
  .operator {
    margin: 4px 4px;
  }
  .equal {
    margin: 4px 4px;
    color: black;
  }
  .coeficient {
    margin: 4px 4px;
  }
  .variable {
    margin: 4px 4px;
    color: #839747;
  }
`;

const Description = styled.div``;

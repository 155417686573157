import React, { useState, useEffect } from "react";

import { Grid } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Loading from "app/components/Loading";

import { loginWithToken } from "../../store/me";

export default function QrCode(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();

  const search = location.search;
  const params = new URLSearchParams(search);
  const token = params.get("token");

  const { logged_in, validations } = useSelector((state) => state.me);

  useEffect(() => {
    if (!id || !token) {
      navigate("/");
    }
  }, [id, token]);

  useEffect(() => {
    if (logged_in) {
      navigate("/");
    }
  }, [logged_in]);

  useEffect(() => {
    dispatch(loginWithToken(id, token));
  }, [id, token]);

  return (
    <Grid style={{ marginTop: 54 }}>
      <Loading />
    </Grid>
  );
}

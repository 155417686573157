import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { create } from "app/api";

const slice = createSlice({
  name: "qr_codes",
  initialState: {
    svg: null,
  },
  reducers: {
    gotQRCode: (state, action) => {
      state.svg = action.payload;
    },
    resettedQRCode: (state, action) => {
      state.svg = null;
    },
  },
});

const { gotQRCode, resettedQRCode } = slice.actions;

export const resetQRCode = () => async (dispatch) => {
  dispatch(resettedQRCode());
};

export const createQRCode = (data) => async (dispatch) => {
  try {
    const response = await create({
      endpoint: `/api/v1/qr_codes`,
      data,
    });
    const { qr_code } = response.data;

    dispatch(gotQRCode(qr_code.svg));
  } catch (response) {}
};

export default slice.reducer;

import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "app/cookie";
import { get, fetch, create, update, destroy } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "assignment_user_quizzes",
  initialState: {
    user_quiz: null,
    user_quizzes: [],
    getting: false,
    initialized: false,
    fetched: false,
    fetching: false,
    editing: false,
    created: false,
    creating: false,
    updating: false,
    deleting: false,
    validations: {},
  },
  reducers: {
    gettingUserQuiz: (state) => {
      state.getting = true;
    },
    gotUserQuiz: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.user_quiz = action.payload;
    },
    fetchedUserQuizzes: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.user_quiz = action.payload[0];
      state.user_quizzes = action.payload;
    },
    fetchingUserQuizzes: (state) => {
      state.fetching = true;
    },
    createdUserQuiz: (state, action) => {
      state.creating = false;
      state.created = true;
      state.validations = {};
      state.user_quiz = action.payload;
      state.user_quizzes = [...state.user_quizzes, action.payload];
    },
    creatingUserQuiz: (state) => {
      state.creating = true;
    },
    updatedUserQuiz: (state, action) => {
      const { user_quizzes } = state;
      const user_quiz = action.payload;

      state.updating = false;
      state.validations = {};
      state.user_quiz = user_quiz;

      const index = user_quizzes.findIndex((uq) => uq.id == user_quiz.id);

      if (index == -1) {
        state.user_quizzes = [...user_quizzes, user_quiz];
      } else {
        user_quizzes[index] = user_quiz;
        state.user_quizzes = [...user_quizzes];
      }
    },
    updatingUserQuiz: (state) => {
      state.updating = true;
    },
    deletedUserQuiz: (state, action) => {
      state.deleting = false;
      state.validations = {};
      state.user_quizzes = [
        ...state.user_quizzes.filter(
          (user_quiz) => user_quiz.id !== action.payload.id
        ),
      ];
    },
    deletingUserQuiz: (state) => {
      state.deleting = true;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.creating = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotUserQuiz,
  gettingUserQuiz,
  fetchedUserQuizzes,
  fetchingUserQuizzes,
  createdUserQuiz,
  creatingUserQuiz,
  updatedUserQuiz,
  updatingUserQuiz,
  deletedUserQuiz,
  deletingUserQuiz,
  handleValidations,
} = slice.actions;

export const getUserQuiz = (id, assignment_id) => async (dispatch) => {
  try {
    dispatch(gettingUserQuiz());

    const response = await get({
      endpoint: `/api/v1/assignments/${assignment_id}/user_quizzes/${id}`,
    });
    const { user_quiz } = response.data;

    dispatch(gotUserQuiz(user_quiz));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchUserQuizzes = (assignment_id, data) => async (dispatch) => {
  try {
    dispatch(fetchingUserQuizzes());

    const response = await fetch({
      endpoint: `/api/v1/assignments/${assignment_id}/user_quizzes`,
      data,
    });
    const { user_quizzes } = response.data;

    dispatch(fetchedUserQuizzes(user_quizzes));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createUserQuiz = (assignment_id, data) => async (dispatch) => {
  try {
    dispatch(creatingUserQuiz());

    const response = await create({
      endpoint: `/api/v1/assignments/${assignment_id}/user_quizzes`,
      data: { user_quiz: data },
    });
    const { user_quiz } = response.data;

    dispatch(createdUserQuiz(user_quiz));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateUserQuiz = (id, assignment_id, data) => async (dispatch) => {
  try {
    dispatch(updatingUserQuiz());

    const response = await update({
      endpoint: `/api/v1/assignments/${assignment_id}/user_quizzes/${id}`,
      data: { user_quiz: data },
    });
    const { user_quiz } = response.data;

    dispatch(updatedUserQuiz(user_quiz));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const deleteUserQuiz = (id, assignment_id, data) => async (dispatch) => {
  try {
    dispatch(deletingUserQuiz());

    const response = await destroy({
      endpoint: `/api/v1/assignments/${assignment_id}/user_quizzes/${id}`,
      data: { user_quiz: data },
    });
    const { user_quiz } = response.data;

    dispatch(deletedUserQuiz(user_quiz));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

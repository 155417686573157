import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import Lottie from "react-lottie";
import styled from "styled-components";
import { Grid, Button, Segment, Container } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import Loading from "app/components/Loading";

import { getMe } from "app/store/me";
import { connectZoom } from "app/store/zoom";

import classImage from "app/assets/images/empty-class.jpg";
import sparkleLottie from "app/assets/lottie/sparkle-animation.json";

export default function Zoom() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const code = params.get("code");

  const { logged_in } = useSelector((state) => state.me);
  const { initialized, connected } = useSelector((state) => state.zoom);

  const {
    links: { zoom },
  } = useSelector((state) => state.settings);

  useEffect(() => {
    if (!logged_in) return;

    dispatch(connectZoom({ code }));
  }, [code, logged_in]);

  useEffect(() => {
    if (connected) dispatch(getMe());
  }, [connected]);

  if (!initialized || !logged_in) return <Loading />;

  return (
    <Container style={{ marginTop: 40 }}>
      <Grid stackable columns={2}>
        <Grid.Column width={16}>
          <Segment style={{ textAlign: "center" }}>
            {connected && (
              <ZoomMessage>
                <span className="title">Zoom</span>
                <span className="message">is connected!</span>
                <span className="continue" onClick={() => navigate("/")}>
                  Continue
                </span>
              </ZoomMessage>
            )}
            {!connected && (
              <ZoomMessage>
                <span className="title">Zoom</span>
                <span className="message">is not connected!</span>
                <span className="try_again" onClick={() => window.open(zoom)}>
                  Try again
                </span>
              </ZoomMessage>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

const ZoomMessage = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: #535354;

  .title {
    color: #138fcc;
  }
  span {
    margin-right: 4px;
  }
  .continue {
    color: #55a951;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid #9dd29a;
    padding: 2px 10px;
    border-radius: 2px;
  }
  .try_again {
    color: #a24c21;
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 500;
    border: 1px solid #b18f7e;
    padding: 2px 10px;
    border-radius: 2px;
  }
`;

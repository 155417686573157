import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import ReactAudioPlayer from "react-audio-player";
import { Button, Statistic, Container } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Game from "./Game";
import Start from "../components/Start";
import Status from "../components/Status";
import EmptyLottieView from "app/components/EmptyLottieView";

import { getMe } from "app/store/me";
import { fetchVoiceFeedbacks } from "app/store/voice_feedbacks";
import { getTask, startTask, completeTask } from "app/store/tasks";
import {
  getUserTicTac,
  updateUserTicTac,
  resetUserTicTac,
} from "app/store/user_tic_tacs";

import { getTicTac, moveTicTac, resetTicTac } from "app/store/tic_tacs";

import evaluatingLottie from "app/assets/lottie/scan-barcode.json";

export default function UserTicTacs() {
  let { slug, user_tic_tac_slug } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [currentAudioUrl, setCurrentAudioUrl] = useState();
  const { key_by_voices } = useSelector((state) => state.voice_feedbacks);

  const {
    user: { id: user_slug, locale },
  } = useSelector((state) => state.me);

  const { tic_tac, fetched } = useSelector((state) => state.tic_tacs);
  const { task } = useSelector((state) => state.tasks);
  const { user_tic_tac } = useSelector((state) => state.user_tic_tacs);

  useEffect(() => {
    dispatch(fetchVoiceFeedbacks());
  }, [locale]);

  useEffect(() => {
    dispatch(getTask(slug));

    return () => {
      dispatch(resetTicTac());
      dispatch(resetUserTicTac());
    };
  }, [slug]);

  useEffect(() => {
    dispatch(getUserTicTac(user_tic_tac_slug));
  }, [slug, locale, user_tic_tac_slug, task]);

  useEffect(() => {
    if (!user_tic_tac) return;

    const { tic_tac_id } = user_tic_tac;

    dispatch(getTicTac(tic_tac_id));
  }, [user_tic_tac]);

  useEffect(() => {
    if (!tic_tac) return;

    const { draw, winner_id } = tic_tac;

    if (!draw && winner_id && winner_id == user_slug) {
      setCurrentAudioUrl(key_by_voices.you_won);
      setTimeout(() => {
        dispatch(getUserTicTac(user_tic_tac_slug));
      }, 3000);
      setTimeout(() => {
        submitTicTac();
      }, 1000);
      return;
    }

    if (draw) {
      setCurrentAudioUrl(key_by_voices.both_win);
      setTimeout(() => {
        dispatch(getUserTicTac(user_tic_tac_slug));
      }, 3000);

      setTimeout(() => {
        submitTicTac();
      }, 1000);
      return;
    }

    if (winner_id) {
      setTimeout(() => {
        dispatch(getUserTicTac(user_tic_tac_slug));
      }, 3000);
      return;
    }
  }, [tic_tac.draw, tic_tac.winner_id]);

  useEffect(() => {
    if (!user_tic_tac) return;

    const { status } = task;
    const { evaluation_status } = user_tic_tac;

    if (status === "completed" && evaluation_status !== "completed") {
      setTimeout(() => {
        dispatch(getUserTicTac(user_tic_tac_slug));
      }, 2000);
    }

    if (evaluation_status === "completed") {
      dispatch(getMe());
    }
  }, [task, user_tic_tac_slug, user_tic_tac]);

  function submitTicTac() {
    const { id } = user_tic_tac;

    dispatch(completeTask(slug));
  }

  if (!fetched) {
    return null;
  }

  function getScreen() {
    const { status, timespent_in_words } = task;
    const {
      id,
      mode,
      draw,
      winner_id,
      board,
      players,
      current_player,
    } = tic_tac;

    const { stats, points, evaluation_status } = user_tic_tac;

    if (evaluation_status === "completed") {
      return (
        <>
          <Container style={{ alignItems: "center" }}>
            <Stats>
              <Statistic size="huge">
                <Statistic.Value text>
                  You
                  <br />
                  Scored
                </Statistic.Value>
              </Statistic>
              <Statistic size="huge" style={{ margin: 0 }} color="blue">
                <Statistic.Value text>
                  {stats.map((s) => `${s}%`).join(",")}
                </Statistic.Value>
              </Statistic>
              <Statistic size="small" style={{ margin: 0 }} color="orange">
                <Statistic.Value text>{timespent_in_words}</Statistic.Value>
              </Statistic>
              <Button
                color="black"
                onClick={() => navigate("/")}
              >
                Continue
              </Button>
            </Stats>
          </Container>
        </>
      );
    } else if (status === "completed") {
      return (
        <EmptyLottieView
          title="Evaluation is in progress..."
          animation={evaluatingLottie}
          speed={0.4}
          options={{
            loop: true,
            autoplay: true,
          }}
          style={{ width: "45%", marginBottom: 40, marginTop: -80 }}
        />
      );
    } else {
      return (
        <>
          <EarnPointMessage>
            <span>{I18n.t("you_will_earn")}</span>
            <span className="points">{points}</span>
            <span>{I18n.t("points")}</span>
          </EarnPointMessage>
          {status === "pending" ? (
            <Start onStart={() => dispatch(startTask(slug))} />
          ) : (
            <Game
              mode={mode}
              board={board}
              won={!!winner_id || draw}
              players={players}
              user_slug={user_slug}
              current_player={current_player}
              onMove={(data) => dispatch(moveTicTac(id, data))}
            />
          )}

          {currentAudioUrl && (
            <ReactAudioPlayer
              src={currentAudioUrl}
              autoPlay
              playbackspeed={0.5}
              onEnded={() => setCurrentAudioUrl()}
            />
          )}
        </>
      );
    }
  }

  const { timespent, status } = task;

  return (
    <>
      <Status
        status={status}
        timespent={timespent}
        start_timer={status === "started"}
        show_action_buttons={false}
      />
      {getScreen()}
    </>
  );
}

const Stats = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 167px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
`;

const EarnPointMessage = styled.div`
  text-align: center;
  margin: 20px;
  font-size: 25px;
  color: #515e6e;

  .points {
    color: #96990d;
    font-weight: bold;
    margin: 0px 4px;
  }
`;

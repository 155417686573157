import React, { useState, useEffect } from "react";

import Lottie from "react-lottie";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
  Input,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import * as signup from "../../assets/lottie/signup.json";
import { forgotPassword } from "../../store/me";

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, logged_in, validations } = useSelector((state) => state.me);
  const [data, setData] = useState({
    email: "",
  });

  useEffect(() => {
    if (logged_in) {
      navigate("/");
    }
  }, [logged_in]);

  return (
    <Grid textAlign="center" verticalAlign="middle" style={{ padding: 0 }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Lottie
          width={240}
          height={240}
          options={{
            loop: true,
            autoplay: true,
            animationData: signup.default,
          }}
          isStopped={false}
          isPaused={false}
        />
        <Form size="large" loading={false}>
          <Segment stacked>
            <Form.Input
              fluid
              required
              icon="mail"
              value={data.email}
              iconPosition="left"
              placeholder="Email"
              onChange={(event) => {
                setData({ ...data, email: event.target.value });
              }}
              error={
                validations.email
                  ? {
                      content: validations.email,
                    }
                  : false
              }
            />

            <Button
              color="teal"
              fluid
              size="large"
              onClick={() => {
                dispatch(forgotPassword(data));
              }}
            >
              Send
            </Button>
          </Segment>
        </Form>
        <Message
          style={{
            color: "#474a4e",
            fontWeight: "bold",
          }}
        >
          Back to? <Link to="/login">Login</Link>
        </Message>
      </Grid.Column>
    </Grid>
  );
}

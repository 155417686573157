import React, { useState, useEffect } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";

import { Container } from "semantic-ui-react";

const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
  const MAX_LINE_LENGTH = 10;
  const MAX_LINES = 2;
  const LENGTH_OF_ELLIPSIS = 3;
  const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
  const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
  const groupWordsByLength = new RegExp(
    `([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
    "gm"
  );
  const splitValues = value
    .replace(trimWordsOverLength, "$1...")
    .match(groupWordsByLength)
    .slice(0, 2)
    .map((val, i) => (
      <tspan
        key={val}
        dy={12 * i}
        x={-10}
        style={{ fontFamily: "sans-serif", fontSize: "11px" }}
      >
        {val}
      </tspan>
    ));
  return (
    <g transform={`translate(${x + 10},${y + 20})`}>
      <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
        {splitValues}
      </text>
    </g>
  );
};

export default function Progress(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { progress } = props;
  const { data, keys, index_by } = progress;

  return (
    <Container style={{ height: 400 }}>
      <ResponsiveBar
        padding={0.6}
        margin={{ top: 50, right: 20, bottom: 50, left: 30 }}
        data={data}
        indexBy={index_by}
        // groupMode={"grouped"}
        enableGridX={false}
        enableGridY={true}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        keys={keys}
        role="application"
        // defs={[
        //   {
        //     id: "dots",
        //     type: "patternDots",
        //     background: "inherit",
        //     color: "rgba(33,33,33,0.2)",
        //     size: 4,
        //     padding: 1,
        //     stagger: true,
        //   },
        //   {
        //     id: "lines",
        //     type: "patternLines",
        //     background: "inherit",
        //     color: "rgba(66,12,1,0.1)",
        //     rotation: -45,
        //     lineWidth: 6,
        //     spacing: 10,
        //   },
        // ]}
        // fill={[
        //   {
        //     match: {
        //       id: "pensding",
        //     },
        //     id: "dots",
        //   },
        //   {
        //     match: {
        //       id: "stasrted",
        //     },
        //     id: "lines",
        //   },
        // ]}
        colors={[
          // "#f7f0e6",
          // "orange",
          // "#97a66f",

          "#dbd4d6",
          "#267d93",
          "#88b02a",

          // "#fae04d",
          // "#789792",
          // "#b1646a",
          // "#efa9a1",
          // "#8470c7",
        ]}
        // colors={{ scheme: "nivo" }}
        // axisLeft={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: -40,
        }}
        axisBottom={{
          tickSize: 10,
          tickPadding: 0,
          tickRotation: 0,
          legend: "",
          legendPosition: "middle",
          legendOffset: 32,
          renderTick: HorizontalTick,
        }}
        borderWidth={1}
        borderColor="#000"
        borderRadius={2}
        enableLabel={true}
        pointLabel="y"
        enablePointLabel={true}
        labelSkipHeight={12}
        isInteractive={true}
        animate={true}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 54,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Container>
  );
}

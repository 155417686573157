import { namespace } from "../util";

const translations = {
  pending: "Start",
  ready: "Ready",
  started: "Continue",
  completed: "Done",
};

export default namespace("assignments", translations);

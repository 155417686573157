import React from "react";

import Loading from "app/components/Loading";
import Status from "app/components/shared/Status";

export default function TaskLoading() {
  return (
    <>
      <Status />
      <Loading />
    </>
  );
}

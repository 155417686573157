import logger from "redux-logger";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import me from "./me";
import cart from "./cart";
import main from "./main";
import kids from "./kids";
import zoom from "./zoom";
import talk from "./talk";
import gifts from "./gifts";
import teams from "./teams";
import chess from "./chess";
import reports from "./reports";
import globals from "./globals";
import credentials from "./credentials";

import plans from "./plans";
import subscriptions from "./subscriptions";

import robot from "./robot";
import questions_synthesis from "./questions/synthesis";
import voice_feedbacks from "./voice_feedbacks";
import kids_schedules from "./kids/schedules";

import csrf_token from "./csrf_token";
import qr_codes from "./qr_codes";

import checkout from "./checkout";
import setup_intents from "./setup_intents";
import payment_methods from "./payment_methods";

import accounts from "./accounts";

import saml_domains from "./saml_domains";
import saml_settings from "./saml_settings";

import settings from "./settings";
import synthesis from "./synthesis";
import timezones from "./timezones";
import position from "./position";
import help_pages from "./help_pages";
import preference from "./preference";
import users from "./users";
import family_users from "./family/users";
import tasks from "./tasks";
import assignments from "./assignments";

import user_quizzes from "./user_quizzes";
import user_sudokus from "./user_sudokus";
import user_chess_plays from "./user_chess_plays";
import user_chess_learns from "./user_chess_learns";
import user_chess_catches from "./user_chess_catches";
import user_chess_challenges from "./user_chess_challenges";
import tic_tacs from "./tic_tacs";
import user_tic_tacs from "./user_tic_tacs";

import assignment_user_quizzes from "./assignment/user_quizzes";

import public_lessons from "./public/lessons";
import public_synthesis from "./public/synthesis";

const reducer = combineReducers({
  me,
  cart,
  main,
  kids,
  zoom,
  talk,
  teams,
  gifts,
  chess,
  reports,
  globals,
  credentials,

  plans,
  subscriptions,

  robot,
  questions_synthesis,
  voice_feedbacks,
  kids_schedules,

  checkout,
  setup_intents,
  payment_methods,

  csrf_token,
  qr_codes,

  accounts,

  saml_domains,
  saml_settings,

  settings,
  synthesis,
  timezones,
  position,
  help_pages,
  preference,

  users,
  family_users,

  tasks,
  assignments,

  user_quizzes,
  user_sudokus,
  user_chess_plays,
  user_chess_learns,
  user_chess_catches,
  user_chess_challenges,
  tic_tacs,
  user_tic_tacs,

  assignment_user_quizzes,

  public_lessons,
  public_synthesis,
});

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export default store;

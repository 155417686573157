import React, { useRef, useState, useEffect, useCallback } from "react";

import styled from "styled-components";
import ReactAudioPlayer from "react-audio-player";
import { useDispatch, useSelector } from "react-redux";

import { border } from "./styles";
import { minimax } from "./minimax";
import { getRandomInt } from "./utils";
import { SQUARE_DIMS, DRAW, DIMS } from "./constants";

import Board from "./Board";
import Talk from "app/components/Talk";
import Robot from "app/components/MovingRobot";

import {
  setType,
  setPlaying,
  setScale,
  setAnimate,
  setPosition,
  setLocation,
  setDimentions,
  setMultiplier,
} from "app/store/robot";

export default function TicTacToe(props) {
  const {
    won,
    mode,
    user_slug,
    onMove,
    board,
    players,
    current_player,
  } = props;
  const dispatch = useDispatch();

  const { key_by_voices } = useSelector((state) => state.voice_feedbacks);

  const refs = useRef({});
  const [winner, setWinner] = useState(false);
  const [game, setGame] = useState(new Board(board));
  const [gameStyles, setGameStyles] = useState({});
  const [text, setText] = useState("Hello");
  const [currentAudioUrl, setCurrentAudioUrl] = useState();

  useEffect(() => {
    dispatch(setLocation("bottom-left"));
    dispatch(setDimentions({ width: 44, height: 44 }));
    dispatch(setAnimate({ x: 130, y: 300 }));
  }, []);

  useEffect(() => {
    setWinner(false);
    setGame(new Board(board));
  }, [board]);

  useEffect(() => {
    let timeout;
    let player = players[current_player];
    const winner = game.getWinner(board);
    const speak = Math.random() > 0.65;

    if (winner !== null) {
      setWinner(true);
      setGameStyles(game.getStrikethroughStyles());
      return;
    }

    if (!winner && player && player.type == "robot" && !won) {
      if (speak) {
        setCurrentAudioUrl(key_by_voices.thinking);
      }

      timeout = setTimeout(() => {
        computerMove(player.marker, speak);
      }, 3000);
    }

    return () => timeout && clearTimeout(timeout);
  }, [game, current_player]);

  const humanMove = (index) => {
    if (current_player == user_slug && !won) {
      onMove({ position: index });
    }
  };

  const computerMove = useCallback(
    (marker, speak) => {
      const game = new Board(board.concat());
      const emptyIndices = game.getEmptySquares(board);

      let index;

      switch (mode) {
        case "easy":
          do {
            index = getRandomInt(0, 8);
          } while (!emptyIndices.includes(index));
          break;
        case "medium":
          const mediumMove = !emptyIndices.length && Math.random() < 0.33;
          if (mediumMove) {
            index = minimax(game, marker)[1];
          } else {
            do {
              index = getRandomInt(0, 8);
            } while (!emptyIndices.includes(index));
          }
          break;
        case "hard":
          const smartMove = !emptyIndices.length && Math.random() < 0.66;
          if (smartMove) {
            index = minimax(game, marker)[1];
          } else {
            do {
              index = getRandomInt(0, 8);
            } while (!emptyIndices.includes(index));
          }
          break;
        case "expert":
        default:
          index =
            emptyIndices.length == 0
              ? getRandomInt(0, 8)
              : minimax(game, marker)[1];
      }

      if (speak) {
        setCurrentAudioUrl(key_by_voices.move_here);
      }

      if (!game[index]) {
        const ref = refs.current[`square-${index}`];
        if (!ref) return;
        const height = ref.offsetParent.offsetHeight;
        dispatch(
          setAnimate({
            x: ref.offsetLeft - 10,
            y: height - ref.offsetTop - 84,
          })
        );

        dispatch(setType("left-hand"));

        onMove({ position: index });
      }
    },
    [board]
  );

  return (
    <Container dims={DIMS}>
      {board.map((value, index) => {
        const isActive = value !== null;
        const id = `square-${index}`;

        return (
          <Square
            key={index}
            ref={(ref) => (refs.current[id] = ref)}
            onClick={() => humanMove(index)}
          >
            {isActive && <Marker>{value === "x" ? "X" : "O"}</Marker>}
          </Square>
        );
      })}
      {winner && <Strikethrough styles={gameStyles} />}

      <Robot moving={true} />
      {currentAudioUrl && (
        <ReactAudioPlayer
          autoPlay
          src={currentAudioUrl}
          onEnded={() => setCurrentAudioUrl()}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ dims }) => `${dims * (SQUARE_DIMS + 5)}px`};
  flex-flow: wrap;
  position: relative;
  margin: 60px auto;
`;

const Square = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SQUARE_DIMS}px;
  height: ${SQUARE_DIMS}px;
  ${border};

  &:hover {
    cursor: pointer;
  }
`;

Square.displayName = "Square";

const Marker = styled.p`
  font-size: 68px;
`;

const ButtonRow = styled.div`
  display: flex;
  width: 150px;
  justify-content: space-between;
`;

const Screen = styled.div``;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;
const ChooseText = styled.p``;

const Strikethrough = styled.div`
  position: absolute;
  background-color: indianred;
  height: 5px;
  ${({ styles }) => styles}
`;

import React, { useState, useEffect, useRef } from "react";

import I18n from "i18n-js";
import Lottie from "react-lottie";
import styled from "styled-components";
import {
  Ref,
  Rail,
  Grid,
  Image,
  Sticky,
  Button,
  Header,
  Segment,
  Container,
} from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import Loading from "app/components/Loading";

import { refreshCart, deleteFromCart } from "app/store/cart";
import { resetCheckout, checkoutWithCreditCard } from "app/store/checkout";

import classImage from "app/assets/images/empty-class.jpg";
import sparkleLottie from "app/assets/lottie/sparkle-animation.json";

export default function Checkout() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const contextRef = useRef();

  const { success, charging } = useSelector((state) => state.checkout);
  const {
    checkout: {
      has_discount,
      cart_line_items,
      display_items_price,
      display_items_tax,
      display_items_total,
      display_discount_of_price,
    },
  } = useSelector((state) => state.cart);

  const [items, setItems] = useState(cart_line_items);

  useEffect(() => {
    return () => {
      dispatch(resetCheckout());
    };
  }, []);

  useEffect(() => {
    setItems(cart_line_items);
  }, [cart_line_items]);

  if (false) return <Loading />;

  if (cart_line_items.length == 0) {
    navigate("/");
  }

  return (
    <Container style={{ marginTop: 40 }}>
      <Grid stackable columns={2}>
        {success && (
          <Lottie
            speed={0.5}
            isClickToPauseDisabled
            options={{
              loop: false,
              autoplay: true,
              animationData: sparkleLottie,
            }}
            style={{
              width: 450,
              height: 450,
              alignSelf: "center",
              marginTop: 34,
              marginLeft: "auto",
              marginRight: "auto",
              zIndex: 1,
              textAlign: "center",
              position: "absolute",
              left: "27%",
              top: 50,
            }}
            isStopped={false}
            isPaused={false}
          />
        )}

        <Grid.Column width={12}>
          <Segment>
            {items.map((item) => {
              const {
                name,
                product_id,
                has_discount,
                display_price,
                display_discount_price,
                photo_url,
              } = item;

              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 10,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={photo_url || classImage}
                      style={{ width: 60, margin: "0px 4px" }}
                    />
                    <span
                      style={{
                        margin: "0px 4px",
                        color: "#454950",
                        fontWeight: "bold",
                      }}
                    >
                      {name}
                    </span>
                  </div>
                  <div>
                    {has_discount ? (
                      <span style={{ margin: "0px 20px" }}>
                        <span style={{ color: "#88901a", fontWeight: "bold" }}>
                          {display_discount_price}
                        </span>
                        /
                        <span
                          style={{
                            fontWeight: "bold",
                            color: "rgb(75 79 86)",
                            textDecoration: "line-through",
                          }}
                        >
                          {display_price}
                        </span>
                      </span>
                    ) : (
                      <span
                        style={{
                          margin: "0px 20px",
                          color: "#88901a",
                          fontWeight: "bold",
                        }}
                      >
                        {display_price}
                      </span>
                    )}
                    <Button
                      basic
                      compact
                      color="red"
                      style={{ display: success ? "none" : "" }}
                      onClick={() => dispatch(deleteFromCart({ product_id }))}
                    >
                      <Button.Content visible>
                        {I18n.t("remove")}
                      </Button.Content>
                    </Button>
                  </div>
                </div>
              );
            })}
          </Segment>
        </Grid.Column>
        <Grid.Column width={4}>
          <Segment>
            <Header>Details</Header>

            <CartPaymentDetails>
              <CartRow>
                <span>{I18n.t("price")}</span>

                <span className="cart-total-price">{display_items_price}</span>
              </CartRow>

              {has_discount && (
                <CartRow>
                  <span>{I18n.t("discount")}</span>

                  <span className="discount-price">
                    ({display_discount_of_price})
                  </span>
                </CartRow>
              )}

              <CartRow>
                <span>{I18n.t("tax")}</span>

                <span className="cart-total-price">{display_items_tax}</span>
              </CartRow>

              <LineSeparator />

              <CartRow>
                <TotalPrice>{I18n.t("total")}</TotalPrice>

                <TotalPrice style={{ color: "#88901a" }}>
                  {display_items_total}
                </TotalPrice>
              </CartRow>
            </CartPaymentDetails>

            <Button
              fluid
              loading={charging}
              style={{ color: "white", background: "#4f5f6b" }}
              onClick={() =>
                dispatch(checkoutWithCreditCard()).then(() =>
                  dispatch(refreshCart())
                )
              }
            >
              <Button.Content visible>
                {I18n.t("complete_payment")}
              </Button.Content>
            </Button>
          </Segment>
        </Grid.Column>
      </Grid>
    </Container>
  );
}

const CartPaymentDetails = styled.div`
  margin-bottom: 20px;
`;
const CartRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  color: #51586f;

  .discount-price {
    color: #bb9a1c;
  }
`;
const LineSeparator = styled.div`
  border-bottom-color: #232121;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  margin-bottom: 10px;
`;

const TotalPrice = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

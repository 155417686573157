import axios from "axios";
import I18n from "i18n-js";
import { createSlice } from "@reduxjs/toolkit";

import { getCookie, setCookie, removeCookie } from "app/cookie";
import { setLocale, setTimeZone, setCSRFToken } from "app/utils";
import { get, create, update, destroy } from "app/api";
import {
  toastInfo,
  toastSuccess,
  handleMeResponse,
  handleActionResponse,
  toastLoginSuccessMessage,
  toastUpdateSuccessMessage,
  toastAcceptSuccessMessage,
} from "app/utils";

const initialState = {
  kid: false,
  fetch: true,
  sso: {
    enabled: false,
    endpoint: null,
  },
  user: {
    locale: "en",
    timezone: "UTC",
    account_id: null,
  },
  logging_in: false,
  fetched: false,
  fetching: true,
  signup_inprogress: false,
  login_in_progress: false,
  loading: true,
  logged_in: false,
  login_needed: false,
  validations: {},
};

const slice = createSlice({
  name: "me",
  initialState,
  reducers: {
    fetching: (state) => {
      state.fetching = true;
    },
    fetched: (state) => {
      state.fetched = true;
      state.fetching = false;
    },
    loginStarted: (state) => {
      state.logging_in = true;
    },
    loginSuccess: (state, action) => {
      state.fetch = false;
      state.logging_in = false;
      state.fetching = false;
      state.logged_in = true;
      state.signup_inprogress = false;
      state.login_in_progress = false;
      state.validations = {};
      state.user = action.payload;
      state.account_id = action.payload.account_id;
    },
    loginFailed: (state, action) => {
      state.logging_in = false;
      state.login_in_progress = false;
    },
    logoutSuccess: (state, action) => {
      state.kid = false;
      state.user = { locale: "en", account: {} };
      state.fetching = false;
      state.logged_in = false;
      state.validations = {};
    },
    updateSuccess: (state, action) => {
      state.fetching = false;
      state.validations = {};
      state.user = action.payload;
    },
    unlockSuccess: (state, action) => {
      state.fetching = false;
      state.login_needed = true;
      state.validations = {};
    },
    confirmSuccess: (state) => {
      state.login_needed = true;
      state.validations = {};
    },
    gotSsoDetails: (state, action) => {
      state.sso = action.payload;
      state.validations = {};
    },
    loginNotNeeded: (state) => {
      state.login_needed = false;
    },
    gettingMe: (state) => {
      state.login_in_progress = true;
    },
    refetchMe: (state) => {
      state.fetch = true;
    },
    refeshedMe: (state, action) => {
      state.user = action.payload;
    },
    setUserLocale: (state, action) => {
      state.user = { ...state.user, locale: action.payload };
    },
    signupInProgress: (state, action) => {
      state.signup_inprogress = true;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.signup_inprogress = false;
      state.validations = action.payload;
    },
    resetInitial: () => {
      return { ...initialState, login_needed: true };
    },
  },
});

export default slice.reducer;

const {
  fetching,
  fetched,
  refetchMe,
  refeshedMe,
  gettingMe,
  loginStarted,
  loginSuccess,
  loginFailed,
  logoutSuccess,
  updateSuccess,
  unlockSuccess,
  confirmSuccess,
  loginNotNeeded,
  setUserLocale,
  gotSsoDetails,
  signupInProgress,
  resetInitial,
  handleValidations,
} = slice.actions;

export const resetLoginNeeded = () => async (dispatch) => {
  dispatch(loginNotNeeded());
};

export const resetMe = () => async (dispatch) => {
  dispatch(resetInitial());
};

export const getMe = () => async (dispatch) => {
  dispatch(getUser());
};

export const sessionOpen = () => async (dispatch) => {
  dispatch(userSessionOpen());
};

export const refreshMe = () => async (dispatch) => {
  try {
    const response = await get({
      endpoint: "/api/v1/me",
    });
    const { user } = response.data;

    setLocale(user.locale);
    setTimeZone(user.timezone);

    dispatch(refeshedMe(user));
  } catch (response) {
    const locale = getCookie("locale") || "en";

    setLocale(locale);
    dispatch(setUserLocale(locale));
    dispatch(loginFailed());
  }
};

export const userSessionOpen = () => async (dispatch) => {
  try {
    await get({
      endpoint: "/api/v1/me",
    });
  } catch (e) {
    dispatch(resetInitial());
  }
};

export const getSsoDetails = (email) => async (dispatch) => {
  try {
    const response = await get({
      endpoint: "/api/v1/sso",
      data: { email },
    });
    const { data } = response;

    dispatch(gotSsoDetails(data));
  } catch (e) {}
};

export const login = (data) => async (dispatch) => {
  const { email, password } = data;

  dispatch(loginUser(data));

  // email.match(/@/)
  //   ? dispatch(loginUser(data))
  //   : dispatch(loginKid({ username: email, password: password }));
};

export const loginWithToken = (id, token) => async (dispatch) => {
  try {
    dispatch(loginStarted());

    const response = await create({
      endpoint: "/api/v1/users/tokens",
      data: { id, token },
    });

    const { user } = response.data;

    setLocale(user.locale);
    setTimeZone(user.timezone);

    toastLoginSuccessMessage();
    dispatch(loginSuccess(user));
  } catch (response) {
    dispatch(loginFailed());

    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const logout = () => async (dispatch) => {
  dispatch(logoutUser()).then(() => {
    window.location.reload();
  });
};

export const getUser = () => async (dispatch) => {
  try {
    dispatch(gettingMe());

    const response = await get({
      endpoint: "/api/v1/me",
    });
    const { user } = response.data;

    setLocale(user.locale);
    setTimeZone(user.timezone);

    dispatch(loginSuccess(user));
  } catch (response) {
    const locale = getCookie("locale") || "en";

    setLocale(locale);
    dispatch(setUserLocale(locale));
    dispatch(loginFailed());
  }
};

export const signup = (data) => async (dispatch) => {
  try {
    dispatch(signupInProgress());

    const response = await create({
      endpoint: "/api/v1/users/registrations",
      data,
    });
    const { user } = response.data;

    setLocale(user.locale);
    setTimeZone(user.timezone);

    dispatch(loginSuccess(user));
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const loginUser = (data) => async (dispatch, getState) => {
  try {
    dispatch(loginStarted());

    const response = await create({
      endpoint: "/api/v1/users/login",
      data: { user: data },
    });

    const { user } = response.data;

    setLocale(user.locale);
    setTimeZone(user.timezone);

    toastLoginSuccessMessage();
    dispatch(loginSuccess(user));
  } catch (response) {
    dispatch(loginFailed());

    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const response = await destroy({
      endpoint: `/api/v1/users/logout`,
    });

    return dispatch(logoutSuccess()).then(() => window.location.reload);
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updatePassword = (data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: "/api/v1/me/update_password",
      data: { user: data },
    });
    const { user } = response.data;

    toastSuccess(I18n.t("update_success"));
    dispatch(updateSuccess(user));
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateTimeZone = (data) => async (dispatch, getState) => {
  try {
    const state = getState();

    if (!state.me.logged_in) {
      setTimeZone(data.timezone);

      return;
    }

    const response = await update({
      endpoint: "/api/v1/me",
      data: { user: data },
    });
    const { user } = response.data;

    setTimeZone(user.timezone);

    dispatch(updateSuccess(user));
  } catch (response) {}
};

export const updateMe = (data) => async (dispatch) => {
  try {
    dispatch(fetching());
    const response = await update({
      endpoint: "/api/v1/me",
      data: { user: data },
    });
    const { user } = response.data;

    dispatch(fetched());
    toastUpdateSuccessMessage();

    return dispatch(updateSuccess(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateLanguage = (locale) => async (dispatch, getState) => {
  try {
    const state = getState();

    if (!state.me.logged_in) {
      setLocale(locale);
      dispatch(setUserLocale(locale));
      return;
    }

    const response = await update({
      endpoint: "/api/v1/me",
      data: { user: { locale } },
    });
    const { user } = response.data;

    setLocale(user.locale);
    setTimeZone(user.timezone);

    dispatch(updateSuccess(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const resendConfirmation = (data) => async (dispatch) => {
  try {
    const response = await create({
      endpoint: "/api/v1/users/confirmations",
      data: { user: data },
    });
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const acceptConfirmation = (data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: "/api/v1/users/confirmations",
      data: { user: data },
    });

    dispatch(confirmSuccess());
    dispatch(toastAcceptSuccessMessage());
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const unlockEmail = (data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: "/api/v1/users/unlocks",
      data: { user: data },
    });

    toastSuccess(I18n.t("account_unlocked"));
    dispatch(unlockSuccess());
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const unlockKidsEmail = (data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: "/api/v1/kids/unlocks",
      data: { user: data },
    });

    toastSuccess(I18n.t("account_unlocked"));
    dispatch(unlockSuccess());
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    const response = await create({
      endpoint: "/api/v1/users/forgot_password",
      data: { user: data },
    });
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const resetPassword = (data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: "/api/v1/users/reset_password",
      data: { user: data },
    });
    const { user } = response.data;

    setLocale(user.locale);
    setTimeZone(user.timezone);

    toastLoginSuccessMessage();
    dispatch(loginSuccess(user));
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const acceptInvitation = (data) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: "/api/v1/users/invitations",
      data: { user: data },
    });
    const { user } = response.data;
    const { account_name, short_display_name } = user;

    setLocale(user.locale);
    setTimeZone(user.timezone);

    toastInfo(
      I18n.t("welcome_invited_to_account", {
        name: short_display_name,
        account: account_name,
      })
    );
    dispatch(confirmSuccess());
    dispatch(loginSuccess(user));
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

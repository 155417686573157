import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
  toastInvitationSuccessMessage,
} from "app/utils";

const slice = createSlice({
  name: "users",
  initialState: {
    getting: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    destroying: false,
    open_form: false,
    open_settings_form: false,
    user: {
      roles: ["member"],
    },
    users: [],
    grade_levels: [],
    meta: { pages: {} },
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 5,
      sort: { fullname: "asc" },
      filter: {},
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingUser: (state) => {
      state.getting = true;
    },
    gotUser: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.user = action.payload;
    },
    fetchedUsers: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.users = action.payload;
    },
    fetchedMeta: (state, action) => {
      const meta = action.payload;
      state.fetched = true;
      state.fetching = false;
      state.meta = meta;
      state.pages = meta.pages;
      state.grade_levels = meta.grade_levels;
    },
    fetchingUsers: (state) => {
      state.fetching = true;
    },
    openingForm: (state, action) => {
      state.open_form = true;
      state.user = action.payload;
    },
    closedForm: (state) => {
      state.open_form = false;
      state.user = {};
      state.validations = {};
    },
    openingSettingsForm: (state, action) => {
      state.open_settings_form = true;
      state.user = action.payload;
    },
    closingSettingsForm: (state) => {
      state.open_settings_form = false;
      state.user = {};
      state.validations = {};
    },
    createdUser: (state, action) => {
      state.creating = false;
      state.open_form = false;
      state.validations = {};
      state.users = [...state.users, action.payload];
    },
    creatingUser: (state) => {
      state.creating = true;
    },
    updatedUser: (state, action) => {
      state.updating = false;
      state.open_form = false;
      state.validations = {};
      state.users = [
        ...state.users.filter((user) => user.id !== action.payload.id),
        action.payload,
      ];
    },
    updatingUser: (state) => {
      state.updating = true;
    },
    destroyedUser: (state, action) => {
      state.destroying = false;
      state.open_settings_form = false;
      state.user = {};
      state.validations = {};
      state.users = [
        ...state.users.filter((user) => user.id !== action.payload.id),
      ];
    },
    destroyingUser: (state) => {
      state.destroying = true;
    },
    handleSetFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotUser,
  gettingUser,
  openingForm,
  closedForm,
  openingSettingsForm,
  closingSettingsForm,
  fetchedMeta,
  fetchedUsers,
  fetchingUsers,
  createdUser,
  creatingUser,
  updatedUser,
  updatingUser,
  destroyedUser,
  destroyingUser,
  handleSetFilters,
  handleValidations,
} = slice.actions;

export const openForm = (user = {}) => async (dispatch) => {
  dispatch(openingForm(user));
};

export const closeForm = () => async (dispatch) => {
  dispatch(closedForm());
};

export const openSettingsForm = (user) => async (dispatch) => {
  dispatch(openingSettingsForm(user));
};

export const closeSettingsForm = () => async (dispatch) => {
  dispatch(closingSettingsForm());
};

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const getUser = (id) => async (dispatch, getState) => {
  try {
    dispatch(gettingUser());

    const state = getState();
    const {
      account: { master_account },
    } = state.accounts;
    const response = await update({
      endpoint: master_account
        ? `/api/v1/master/users/${id}`
        : `/api/v1/users/${id}`,
    });

    const { user } = response.data;

    dispatch(gotUser(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchUsers = (data) => async (dispatch, getState) => {
  try {
    dispatch(fetchingUsers());

    const state = getState();
    const {
      account: { master_account },
    } = state.accounts;
    const response = await fetch({
      endpoint: master_account ? `/api/v1/master/users` : `/api/v1/users`,
      data,
    });

    const { meta, users } = response.data;

    dispatch(fetchedMeta(meta));
    dispatch(fetchedUsers(users));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createUser = (data) => async (dispatch, getState) => {
  try {
    dispatch(creatingUser());

    const state = getState();
    const {
      account: { master_account },
    } = state.accounts;
    const response = await create({
      endpoint: master_account ? `/api/v1/master/users` : `/api/v1/users`,
      data,
    });

    const { user } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdUser(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateUser = (id, changes) => async (dispatch, getState) => {
  try {
    dispatch(updatingUser());

    const state = getState();
    const {
      account: { master_account },
    } = state.accounts;
    const response = await update({
      endpoint: master_account
        ? `/api/v1/master/users/${id}`
        : `/api/v1/users/${id}`,
      data: { user: changes },
    });

    const { user } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedUser(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const destroyUser = (id, data) => async (dispatch, getState) => {
  try {
    dispatch(destroyingUser());

    const state = getState();
    const {
      account: { master_account },
    } = state.accounts;
    const response = await destroy({
      endpoint: master_account
        ? `/api/v1/master/users/${id}`
        : `/api/v1/users/${id}`,
      data: { user: data },
    });
    const { user } = response.data;

    toastDeleteSuccessMessage();
    dispatch(destroyedUser(user));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

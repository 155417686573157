import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, create, update, destroy } from "../api";
import {
  handleMeResponse,
  handleActionResponse,
  toastUpdateSuccessMessage,
} from "../utils";

const slice = createSlice({
  name: "account",
  initialState: {
    account: {},
    family_account: false,
    fetched: false,
    fetching: false,
    validations: {},
  },
  reducers: {
    fetchingAccount: (state) => {
      state.fetching = true;
    },
    fetchedAccount: (state, action) => {
      const account = action.payload;

      state.fetched = true;
      state.fetching = false;
      state.account = account;
      state.family_account = account.type === "family";
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const { fetchedAccount, fetchingAccount, handleValidations } = slice.actions;

export const getAccount = (id) => async (dispatch) => {
  try {
    dispatch(fetchingAccount());
    const response = await get({
      endpoint: `/api/v1/accounts/${id}`,
    });
    const { account } = response.data;

    dispatch(fetchedAccount(account));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const upgradeAccount = (id, type) => async (dispatch) => {
  try {
    dispatch(fetchingAccount());
    const response = await update({
      endpoint: `/api/v1/accounts/${id}/${type}`,
    });
    const { account } = response.data;

    toastUpdateSuccessMessage();
    dispatch(fetchedAccount(account));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateAccount = (id, data) => async (dispatch) => {
  try {
    dispatch(fetchingAccount());
    const response = await update({
      endpoint: `/api/v1/accounts/${id}`,
      data: { account: data },
    });
    const { account } = response.data;

    toastUpdateSuccessMessage();
    dispatch(fetchedAccount(account));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const switchAccount = (id) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: `/api/v1/accounts/${id}/switch`,
    });
    const { account } = response.data;

    dispatch(fetchedAccount(account));
  } catch (response) {
    const validations = handleMeResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import React, { useState, useEffect, useRef } from "react";

import styled from "styled-components";
import Chessboard from "chessboardjsx";
import { Button } from "semantic-ui-react";
import ReactAudioPlayer from "react-audio-player";
import { useSelector, useDispatch } from "react-redux";

import Start from "../components/Start";
import Status from "./integrations/StatusBar";
import Robot from "app/components/MovingRobot";

import {
  setType,
  setPlaying,
  setScale,
  setAnimate,
  // setPosition,
  setLocation,
  setDimentions,
  setMultiplier,
} from "app/store/robot";

export default function Game(props) {
  const size = 320;
  const dispatch = useDispatch();
  const boardRef = useRef();

  const boardPositionsRef = useRef({});

  const { user_chess_learn, onEnd, onStart } = props;
  const { status, solution, chess_learn_sections } = user_chess_learn;

  const [squares, setSquares] = useState({});
  const [section, setSection] = useState();
  const [sections, setSections] = useState([]);
  const [currentSection, setCurrentSection] = useState();
  const [userAction, setUserAction] = useState();
  const [lessonStarted, setLessonStarted] = useState(false);

  const [rewatch, setRewatch] = useState(false);
  const [lessonEnded, setLessonEnded] = useState(false);

  const [audioUrl, setAudioUrl] = useState();
  const [positions, setPositions] = useState({});
  const [draggable, setDraggable] = useState(false);
  const [boardReady, setBoardReady] = useState(false);
  const [audioEnded, setAudioEnded] = useState(false);
  const [actionEnded, setActionEnded] = useState(false);

  useEffect(() => {
    if (!rewatch) return;

    setRewatch(false);
    setLessonEnded(false);
    setPositions(solution);
    setCurrentSection(0);
  }, [rewatch]);

  useEffect(() => {
    if (!lessonStarted) return;

    setTimeout(() => {
      const elements = document.querySelectorAll("[data-squareid]");

      elements.forEach((element, i) => {
        squares[element.dataset["squareid"]] = element;
      });

      setSquares(squares);
      setBoardReady(true);
    }, 1000);
  }, [lessonStarted]);

  useEffect(() => {
    if (Object.keys(solution).length == 0) return;

    setTimeout(() => {
      setPositions(solution);
      boardPositionsRef.current = solution;
    }, 1000);

    dispatch(setAnimate({ x: 10, y: -50 }));
  }, [solution]);

  useEffect(() => {
    setDraggable(!!userAction);
  }, [userAction]);

  useEffect(() => {
    if (!lessonStarted) return;

    dispatch(setType("talking"));
    dispatch(setType("left-hand"));

    setSections(chess_learn_sections);
  }, [lessonStarted, chess_learn_sections]);

  useEffect(() => {
    if (sections.length === 0 || !boardReady || !lessonStarted) return;

    setCurrentSection(0);
  }, [sections, boardReady]);

  useEffect(() => {
    if (currentSection == undefined) return;

    setAudioEnded(false);
    setActionEnded(false);
    setSection(sections[currentSection]);
  }, [currentSection]);

  useEffect(() => {
    if (!audioEnded || !actionEnded) return;

    setCurrentSection(currentSection + 1);
  }, [audioEnded, actionEnded]);

  useEffect(() => {
    if (!section) return;

    setAudioUrl(section.audio_url);
    runStep();
  }, [section]);

  // useEffect(() => {
  //   game.load(fen);
  // }, []);

  function handleOnStart() {
    if (status == "pending") {
      onStart();
      setLessonStarted(true);
    } else {
      setLessonStarted(true);
    }
  }

  function onDrop(info) {
    const { piece, sourceSquare, targetSquare } = info;

    console.log("ONDROP", info);

    if (
      !userAction ||
      userAction.target !== targetSquare ||
      userAction.source !== sourceSquare ||
      userAction.piece !== piece
    ) {
      return "snapback";
    }

    setUserAction();

    const filtered = Object.entries(positions).filter(
      ([key]) => ![userAction.source, userAction.target].includes(key)
    );

    filtered.push([targetSquare, piece]);

    setPositions(Object.fromEntries(filtered));
    setActionEnded(true);
  }

  function runStep() {
    let delay = 0;
    const { actions } = section;

    if (actions.length === 0) {
      setActionEnded(true);

      return;
    }

    actions.forEach((action, i) => {
      const { type } = action;

      delay += action.delay;

      if (type == "set_draggable") {
        setDraggable(true);
      } else if (type == "set_position") {
        boardPositionsRef.current = action.position;
        setPositions(action.position);
      } else if (type == "set_style") {
        setTimeout(() => {
          squares[action.target].style[action.key] = action.value;
        }, delay);
      } else if (type == "clear_styles") {
        setTimeout(() => {
          Object.keys(squares).forEach((square, i) => {
            squares[square].style[action.key] = "";
          });
        }, delay);
      } else if (type == "move_robot") {
        setTimeout(() => {
          const square = squares[action.target];

          dispatch(
            setAnimate({
              x: square.offsetLeft - 28,
              y: 320 - square.offsetHeight - square.offsetTop,
            })
          );
        }, delay);
      } else if (type == "move") {
        setTimeout(() => {
          const square = squares[action.target];

          dispatch(
            setAnimate({
              x: square.offsetLeft - 28,
              y: 320 - square.offsetHeight - square.offsetTop,
            })
          );

          const piece = boardPositionsRef.current[action.source];

          const filtered = Object.entries(boardPositionsRef.current).filter(
            ([key]) => ![action.target, action.source].includes(key)
          );

          filtered.push([action.target, piece]);

          const filteredPositions = Object.fromEntries(filtered);
          boardPositionsRef.current = filteredPositions;
          setPositions(filteredPositions);
        }, delay);
      } else if (type == "move_piece") {
        setTimeout(() => {
          const piece = boardPositionsRef.current[action.source];

          const filtered = Object.entries(boardPositionsRef.current).filter(
            ([key]) => ![action.target, action.source].includes(key)
          );

          filtered.push([action.target, piece]);

          boardPositionsRef.current = Object.fromEntries(filtered);
          setPositions(Object.fromEntries(filtered));
        }, delay);
      } else if (type == "attack") {
        setTimeout(() => {
          const square = squares[action.target];

          dispatch(
            setAnimate({
              x: square.offsetLeft - 28,
              y: 320 - square.offsetHeight - square.offsetTop,
            })
          );

          const piece = boardPositionsRef.current[action.source];

          let filtered = Object.entries(boardPositionsRef.current).filter(
            ([key]) => ![action.target].includes(key)
          );

          let filteredPositions = Object.fromEntries(filtered);
          boardPositionsRef.current = filteredPositions;
          setPositions(filteredPositions);

          filtered = Object.entries(boardPositionsRef.current).filter(
            ([key]) => ![action.source].includes(key)
          );

          filtered.push([action.target, piece]);
          filteredPositions = Object.fromEntries(filtered);
          boardPositionsRef.current = filteredPositions;
          setPositions(filteredPositions);
        }, delay);
      } else if (type == "remove") {
        setTimeout(() => {
          const filtered = Object.entries(boardPositionsRef.current).filter(
            ([key]) => action.target !== key
          );

          boardPositionsRef.current = Object.fromEntries(filtered);
          setPositions(Object.fromEntries(filtered));
        }, delay);
      } else if (type == "set_styles") {
        const { styles } = action;

        styles.forEach((style, i) => {
          const { key, value } = style;

          delay += style.delay;

          if (style.type == "clear_style") {
            setTimeout(() => {
              style.targets.forEach((target, i) => {
                squares[target].style[key] = "";
              });
            }, delay);
          } else {
            setTimeout(() => {
              style.targets.forEach((target, i) => {
                squares[target].style[key] = value;
              });
            }, delay);
          }
        });
      } else if (type == "next_action") {
        setTimeout(() => {
          setActionEnded(true);
        }, delay);
      } else if (type == "user_action") {
        setTimeout(() => {
          setUserAction(action);
        }, delay);
      } else if (type == "end_lesson") {
        setTimeout(() => {
          setUserAction();
          setLessonEnded(true);
        }, delay);
      }
    });
  }

  return (
    <Wrapper style={{ position: "relative" }}>
      <BoardWrapper ref={boardRef}>
        {!lessonStarted ? (
          <Start onStart={handleOnStart} size={size} />
        ) : (
          <Chessboard
            width={320}
            position={positions}
            // sparePieces
            draggable={draggable}
            // position={fen}
            onDrop={onDrop}
            // dropOffBoard="snapback"
            // dropOffBoard="trash"
            orientation="white"
            boardStyle={{
              ...boardStyle,
              opacity: lessonEnded ? 0.2 : 1,
            }}
          />
        )}
        {lessonEnded && (
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: "40%",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Button color="black" onClick={() => setRewatch(true)}>
              Rewatch
            </Button>
            <Button color="green" onClick={() => onEnd()}>
              Finish
            </Button>
          </div>
        )}
        <Robot moving={true} />
      </BoardWrapper>
      {audioUrl && (
        <ReactAudioPlayer
          autoPlay
          src={audioUrl}
          onEnded={() => setAudioEnded(true)}
        />
      )}
    </Wrapper>
  );
}

const BoardWrapper = styled.div`
  /* display: flex; */
  width: 320px;
  position: relative;
  margin: auto auto;
  /* justify-content: space-around; */
  /* align-items: center; */
`;

const boardStyle = {
  borderRadius: "5px",
  boxShadow: `0 5px 15px rgba(0, 0, 0, 0.5)`,
};

const Wrapper = styled.div`
  .highlight-white {
    box-shadow: inset 0 0 2px 2px yellow;
  }
  .highlight-black {
    box-shadow: inset 0 0 2px 2px blue;
  }
`;

import React from "react";

import I18n from "i18n-js";
import { Button, Menu, Segment, Label } from "semantic-ui-react";

import { useSelector } from "react-redux";

import Timer from "app/components/Timer";

export default function Status(props) {
  const { onSubmit, timespent, status } = props;

  const {
    user: { points },
  } = useSelector((state) => state.me);

  return (
    <Segment
      style={{
        margin: 0,
        padding: 0,
        position: "sticky",
        top: 0,
        zIndex: 10,
        display: "flex",
        justifyContent: "space-between",
        // background: "#ab95a126",
      }}
    >
      <Menu secondary style={{ alignSelf: "left", margin: 0 }}>
        <Menu.Item style={{ padding: "0px 0px 0px 4px" }}>
          <Button as="div" labelPosition="right" style={{ height: 30 }}>
            <Button
              icon
              style={{
                display: "flex",
                alignItems: "center",
                padding: "3px 10px 3px 6px",
              }}
            >
              {I18n.t("your_points")}
            </Button>
            <Label
              as="a"
              basic
              pointing="left"
              style={{ color: "#96990d", fontWeight: "bold" }}
            >
              {points}
            </Label>
          </Button>
        </Menu.Item>
      </Menu>

      <Menu secondary style={{ alignSelf: "right", margin: 0 }}>
        <Menu.Item style={{ padding: "0px 0px 0px 0px" }}>
          <Timer
            timespent={timespent}
            stop={status === "completed" || status === "pending"}
          />
        </Menu.Item>

        {status === "started" && (
          <Menu.Item style={{ padding: "0px 0px 0px 4px" }}>
            <Button
              // basic
              color="blue"
              style={{ padding: 8 }}
              onClick={() => onSubmit()}
            >
              Complete
            </Button>
          </Menu.Item>
        )}
      </Menu>
    </Segment>
  );
}

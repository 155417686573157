import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "app/utils";

const slice = createSlice({
  name: "tasks",
  initialState: {
    task: {
      links: [],
      points: 0,
    },
    tasks: [],
    recurrence: null,
    getting: false,
    initialized: false,
    fetch: true,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    deleting: false,
    deleted: false,
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 8,
      sort: { start_date: "asc" },
      filter: {},
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingTask: (state) => {
      state.getting = true;
    },
    gotTask: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.task = action.payload;
    },
    fetchedTasks: (state, action) => {
      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.tasks = action.payload;
    },
    fetchingTasks: (state) => {
      state.fetching = true;
    },
    resettedFetchTasks: (state) => {
      state.fetch = true;
    },
    openingTaskForm: (state, action) => {
      state.open_form = true;
      state.task = action.payload;
    },
    closedTaskForm: (state) => {
      state.open_form = false;
      state.task = {};
      state.validations = {};
    },
    createdTask: (state, action) => {
      state.fetch = true;
      state.creating = false;
      state.open_form = false;
      state.validations = {};
    },
    creatingTask: (state) => {
      state.creating = true;
    },
    updatedTask: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.task = action.payload;
      state.open_form = false;
      state.validations = {};
    },
    updatingTask: (state) => {
      state.updating = true;
    },
    deletedTask: (state, action) => {
      state.fetch = true;
      state.deleting = false;
      state.deleted = true;
      state.validations = {};
    },
    deletingTask: (state) => {
      state.deleted = false;
      state.deleting = true;
    },
    gotRecurrence: (state, action) => {
      state.fetch = true;
      state.recurrence = action.payload;
    },
    openedForm: (state, action) => {
      state.open_form = true;
      state.task = action.payload;
    },
    closedForm: (state) => {
      state.open_form = false;
      state.task = { links: [], points: 0 };
      state.validations = {};
    },
    openedSettingsForm: (state, action) => {
      state.deleted = false;
      state.open_settings_form = true;
      state.task = action.payload;
    },
    closedSettingsForm: (state) => {
      state.deleted = false;
      state.open_settings_form = false;
      state.task = { links: [], points: 0 };
      state.validations = {};
    },
    fetchedMeta: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.meta = action.payload;
      state.pages = action.payload.pages;
    },
    resettedTask: (state, action) => {
      state.task = { links: [], points: 0 };
    },
    handleSetFilters: (state, action) => {
      state.fetch = true;
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  fetchedMeta,
  gotTask,
  gettingTask,
  openedForm,
  closedForm,
  openedSettingsForm,
  closedSettingsForm,
  fetchedTasks,
  fetchingTasks,
  createdTask,
  creatingTask,
  updatedTask,
  updatingTask,
  deletedTask,
  deletingTask,
  resettedTask,
  resettedFetchTasks,
  gotRecurrence,
  handleSetFilters,
  handleValidations,
} = slice.actions;

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const resetTask = () => async (dispatch) => {
  dispatch(resettedTask());
};

export const resetFetchTasks = () => async (dispatch) => {
  dispatch(resettedFetchTasks());
};

export const openForm = (task = {}) => async (dispatch) => {
  dispatch(openedForm(task));
};

export const closeForm = () => async (dispatch) => {
  dispatch(closedForm());
};

export const openSettingsForm = (task = {}) => async (dispatch) => {
  dispatch(openedSettingsForm(task));
};

export const closeSettingsForm = () => async (dispatch) => {
  dispatch(closedSettingsForm());
};

export const getTask = (id) => async (dispatch) => {
  try {
    dispatch(gettingTask());

    const response = await get({
      endpoint: `/api/v1/tasks/${id}`,
    });
    const { task } = response.data;

    dispatch(gotTask(task));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchTasks = (data) => async (dispatch) => {
  try {
    dispatch(fetchingTasks());

    const response = await fetch({
      endpoint: `/api/v1/tasks`,
      data,
    });
    const { meta, tasks } = response.data;

    dispatch(fetchedMeta(meta));
    dispatch(fetchedTasks(tasks));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createTask = (data) => async (dispatch) => {
  try {
    dispatch(creatingTask());

    const response = await create({
      endpoint: `/api/v1/tasks`,
      data: { task: data },
    });
    const { task } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdTask(task));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateTask = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingTask());

    const response = await update({
      endpoint: `/api/v1/tasks/${id}`,
      data: { task: data },
    });
    const { task } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedTask(task));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const destroyTask = (id, data) => async (dispatch) => {
  try {
    dispatch(deletingTask());

    const response = await destroy({
      endpoint: `/api/v1/tasks/${id}`,
      data: { task: data },
    });
    const { task } = response.data;

    toastDeleteSuccessMessage();
    dispatch(deletedTask(task));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const startTask = (id) => async (dispatch) => {
  try {
    dispatch(updatingTask());

    const response = await update({
      endpoint: `/api/v1/tasks/${id}/start`,
    });
    const { task } = response.data;

    dispatch(updatedTask(task));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const completeTask = (id) => async (dispatch) => {
  try {
    dispatch(updatingTask());

    const response = await update({
      endpoint: `/api/v1/tasks/${id}/complete`,
    });
    const { task } = response.data;

    dispatch(updatedTask(task));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const getRecurrence = (id) => async (dispatch) => {
  try {
    const response = await get({
      endpoint: `/api/v1/recurrences/${id}`,
    });
    const { recurrence } = response.data;

    dispatch(gotRecurrence(recurrence));
  } catch (response) {}
};

export const updateRecurrence = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingTask());

    const response = await update({
      endpoint: `/api/v1/recurrences/${id}`,
      data: { recurrence: data },
    });
    const { recurrence } = response.data;

    toastUpdateSuccessMessage();
    dispatch(gotRecurrence(recurrence));
  } catch (response) {}
};

import React, { useRef, useState, useEffect } from "react";

import I18n from "i18n-js";
import Chess from "chess.js";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";

const STOCKFISH = window.STOCKFISH;

export default function Stockfish(props) {
  const {
    fen,
    choice,
    problem,
    display_name,
    steps_taken,
    onEnd,
    onChange,
  } = props;

  const fenRef = useRef(fen);
  const [change, setChange] = useState();
  const [game, setGame] = useState(new Chess());
  const [engine, setEngine] = useState();
  const [winner, setWinner] = useState();
  const [gameLoaded, setGameLoaded] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    game.load(fenRef.current);

    setGameLoaded(true);
  }, []);

  useEffect(() => {
    if (!engine) return;

    if (fen == problem) {
      setWinner();
      fenRef.current = fen;
      setChange(fen);
      game.load(fen);
      engine.start();
    }
  }, [fen]);

  useEffect(() => {
    if (!engine) return;

    engine.start();
  }, [engine]);

  useEffect(() => {
    if (!gameLoaded) return;

    const options = {};

    /// We can load Stockfish via Web Workers or via STOCKFISH() if loaded from a <script> tag.
    let engine =
      typeof STOCKFISH === "function"
        ? STOCKFISH()
        : new Worker(options.stockfishjs || "/stockfish.js");
    let evaler =
      typeof STOCKFISH === "function"
        ? STOCKFISH()
        : new Worker(options.stockfishjs || "/stockfish.js");
    let engineStatus = {};
    let time = { wtime: 3000, btime: 3000, winc: 1500, binc: 1500 };
    let playerColor = choice;
    let clockTimeoutID = null;
    // let isEngineRunning = false;
    let announced_game_over;
    // do not pick up pieces if the game is over
    // only pick up pieces for White

    setInterval(function () {
      if (announced_game_over) {
        return;
      }

      if (game.game_over()) {
        const turn = game.turn();
        const winner_color = turn == "w" ? "black" : "white";
        setWinner(winner_color);

        announced_game_over = true;
        onEnd(winner_color == choice);
      }
    }, 500);

    function uciCmd(cmd, which) {
      // console.log('UCI: ' + cmd);

      (which || engine).postMessage(cmd);
    }
    uciCmd("uci");

    function clockTick() {
      let t =
        (time.clockColor === "white" ? time.wtime : time.btime) +
        time.startTime -
        Date.now();
      let timeToNextSecond = (t % 1000) + 1;
      clockTimeoutID = setTimeout(clockTick, timeToNextSecond);
    }

    function stopClock() {
      if (clockTimeoutID !== null) {
        clearTimeout(clockTimeoutID);
        clockTimeoutID = null;
      }
      if (time.startTime > 0) {
        let elapsed = Date.now() - time.startTime;
        time.startTime = null;
        if (time.clockColor === "white") {
          time.wtime = Math.max(0, time.wtime - elapsed);
        } else {
          time.btime = Math.max(0, time.btime - elapsed);
        }
      }
    }

    function startClock() {
      if (game.turn() === "w") {
        time.wtime += time.winc;
        time.clockColor = "white";
      } else {
        time.btime += time.binc;
        time.clockColor = "black";
      }
      time.startTime = Date.now();
      clockTick();
    }

    function get_moves() {
      let moves = "";
      let history = game.history({ verbose: true });

      for (let i = 0; i < history.length; ++i) {
        let move = history[i];
        moves +=
          " " + move.from + move.to + (move.promotion ? move.promotion : "");
      }

      return moves;
    }

    const prepareMove = () => {
      stopClock();
      // this.setState({ fen: game.fen() });
      let turn = game.turn() === "w" ? "white" : "black";

      if (!game.game_over()) {
        // if (turn === playerColor) {
        if (turn !== playerColor) {
          // playerColor = playerColor === "white" ? "black" : "white";

          if (initialLoad) {
            uciCmd("position fen " + fenRef.current);
            uciCmd("go depth 10");
            setInitialLoad(false);
            return;
          }

          uciCmd("position startpos moves" + get_moves());
          uciCmd("position startpos moves" + get_moves(), evaler);
          uciCmd("eval", evaler);

          if (time && time.wtime) {
            uciCmd(
              "go " +
                (time.depth ? "depth " + time.depth : "") +
                " wtime " +
                time.wtime +
                " winc " +
                time.winc +
                " btime " +
                time.btime +
                " binc " +
                time.binc
            );
          } else {
            uciCmd("go " + (time.depth ? "depth " + time.depth : ""));
          }
          // isEngineRunning = true;
        }

        if (game.history().length >= 2 && !time.depth && !time.nodes) {
          startClock();
        }
      }
    };

    evaler.onmessage = function (event) {
      let line;

      if (event && typeof event === "object") {
        line = event.data;
      } else {
        line = event;
      }

      /// Ignore some output.
      if (
        line === "uciok" ||
        line === "readyok" ||
        line.substr(0, 11) === "option name"
      ) {
        return;
      }
    };

    engine.onmessage = (event) => {
      let line;

      if (event && typeof event === "object") {
        line = event.data;
      } else {
        line = event;
      }
      // console.log('Reply: ' + line);
      if (line === "uciok") {
        engineStatus.engineLoaded = true;
      } else if (line === "readyok") {
        engineStatus.engineReady = true;
      } else {
        let match = line.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/);
        /// Did the AI move?
        if (match) {
          // isEngineRunning = false;
          game.move({ from: match[1], to: match[2], promotion: match[3] });

          fenRef.current = game.fen();
          setChange(game.fen());

          prepareMove();
          uciCmd("eval", evaler);
          //uciCmd("eval");
          /// Is it sending feedback?
        } else if (
          (match = line.match(/^info .*\bdepth (\d+) .*\bnps (\d+)/))
        ) {
          engineStatus.search = "Depth: " + match[1] + " Nps: " + match[2];
        }

        /// Is it sending feed back with a score?
        if ((match = line.match(/^info .*\bscore (\w+) (-?\d+)/))) {
          let score = parseInt(match[2], 10) * (game.turn() === "w" ? 1 : -1);
          /// Is it measuring in centipawns?
          if (match[1] === "cp") {
            engineStatus.score = (score / 100.0).toFixed(2);
            /// Did it find a mate?
          } else if (match[1] === "mate") {
            engineStatus.score = "Mate in " + Math.abs(score);
          }

          /// Is the score bounded?
          if ((match = line.match(/\b(upper|lower)bound\b/))) {
            engineStatus.score =
              ((match[1] === "upper") === (game.turn() === "w")
                ? "<= "
                : ">= ") + engineStatus.score;
          }
        }
      }
      // displayStatus();
    };

    setEngine({
      start: function () {
        uciCmd("ucinewgame");
        uciCmd("isready");

        engineStatus.engineReady = false;
        engineStatus.search = null;
        prepareMove();
        announced_game_over = false;
      },
      prepareMove: function () {
        prepareMove();
      },
      internalEngine: engine,
    });
  }, [gameLoaded]);

  function onDrop({ sourceSquare, targetSquare }) {
    // see if the move is legal
    const move = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q",
    });

    // illegal move
    if (move === null) return;

    return new Promise((resolve) => {
      fenRef.current = game.fen();
      setChange(game.fen());
      onChange(fenRef.current);

      resolve();
    }).then(() => engine.prepareMove());
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <PlayerName>
        {I18n.t("computer")}
        {winner && winner !== choice && (
          <Icon
            name="winner"
            style={{
              margin: "0px 2px",
              color: "#efc424",
              textShadow: "0px 0px 2px #000000",
            }}
          />
        )}{" "}
        (
        <span style={{ margin: "0px 2px", fontSize: 17, fontWeight: 500 }}>
          {I18n.t("catch_the_king")} - {steps_taken}
        </span>
        )
      </PlayerName>
      <div>
        {props.children({
          onDrop: onDrop,
          orientation: choice,
          position: fenRef.current,
        })}
      </div>
      <PlayerName>
        {display_name}
        {winner == choice && (
          <Icon
            name="winner"
            style={{
              paddingLeft: 10,
              color: "#efc424",
              textShadow: "0px 0px 2px #000000",
            }}
          />
        )}
      </PlayerName>
    </div>
  );
}

const PlayerName = styled.div`
  text-align: center;
  font-weight: bold;
  padding: 20px 0px;
  font-size: 20px;
  color: #332b1e;
`;

import axios from "axios";

import { createSlice } from "@reduxjs/toolkit";

import { getCookie } from "app/cookie";
import { get, fetch, create, update, destroy } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "user_tic_tacs",
  initialState: {
    user_tic_tac: null,
    user_tic_tacs: [],
    getting: false,
    initialized: false,
    fetch: true,
    fetched: false,
    fetching: false,
    editing: false,
    created: false,
    creating: false,
    updating: false,
    deleting: false,
    validations: {},
  },
  reducers: {
    gettingUserTicTac: (state) => {
      state.getting = true;
    },
    gotUserTicTac: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.user_tic_tac = action.payload;
    },
    fetchedUserTicTacs: (state, action) => {
      state.fetched = true;
      state.fetching = false;
      state.initialized = true;
      state.user_tic_tacs = action.payload;
    },
    fetchingUserTicTacs: (state) => {
      state.fetching = true;
    },
    createdUserTicTac: (state, action) => {
      state.creating = false;
      state.created = true;
      state.validations = {};
      state.user_tic_tac = action.payload;
      state.user_tic_tacs = [...state.user_tic_tacs, action.payload];
    },
    creatingUserTicTac: (state) => {
      state.creating = true;
    },
    updatedUserTicTac: (state, action) => {
      const { user_tic_tacs } = state;
      const user_tic_tac = action.payload;

      state.updating = false;
      state.validations = {};
      state.user_tic_tac = user_tic_tac;

      const index = user_tic_tacs.findIndex((uq) => uq.id == user_tic_tac.id);

      if (index == -1) {
        state.user_tic_tacs = [...user_tic_tacs, user_tic_tac];
      } else {
        user_tic_tacs[index] = user_tic_tac;
        state.user_tic_tacs = [...user_tic_tacs];
      }
    },
    updatingUserTicTac: (state) => {
      state.updating = true;
    },
    deletedUserTicTac: (state, action) => {
      state.deleting = false;
      state.validations = {};
      state.user_tic_tacs = [
        ...state.user_tic_tacs.filter(
          (user_tic_tac) => user_tic_tac.id !== action.payload.id
        ),
      ];
    },
    deletingUserTicTac: (state) => {
      state.deleting = true;
    },
    resettedUserTicTac: (state) => {
      state.user_tic_tac = null;
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.creating = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotUserTicTac,
  gettingUserTicTac,
  fetchedUserTicTacs,
  fetchingUserTicTacs,
  createdUserTicTac,
  creatingUserTicTac,
  updatedUserTicTac,
  updatingUserTicTac,
  deletedUserTicTac,
  deletingUserTicTac,
  resettedUserTicTac,
  handleValidations,
} = slice.actions;

export const resetUserTicTac = () => async (dispatch) => {
  dispatch(resettedUserTicTac());
};

export const getUserTicTac = (id) => async (dispatch) => {
  try {
    dispatch(gettingUserTicTac());

    const response = await get({
      endpoint: `/api/v1/user_tic_tacs/${id}`,
    });
    const { user_tic_tac } = response.data;

    dispatch(gotUserTicTac(user_tic_tac));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchUserTicTacs = (data) => async (dispatch) => {
  try {
    dispatch(fetchingUserTicTacs());

    const response = await fetch({
      endpoint: `/api/v1/user_tic_tacs`,
      data,
    });
    const { user_tic_tacs } = response.data;

    dispatch(fetchedUserTicTacs([...user_tic_tacs]));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createUserTicTac = (data) => async (dispatch) => {
  try {
    dispatch(creatingUserTicTac());

    const response = await create({
      endpoint: `/api/v1/user_tic_tacs`,
      data: { user_tic_tac: data },
    });
    const { user_tic_tac } = response.data;

    dispatch(createdUserTicTac(user_tic_tac));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateUserTicTac = (id, data) => async (dispatch) => {
  try {
    dispatch(updatingUserTicTac());

    const response = await update({
      endpoint: `/api/v1/user_tic_tacs/${id}`,
      data: { user_tic_tac: data },
    });
    const { user_tic_tac } = response.data;

    dispatch(updatedUserTicTac(user_tic_tac));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import shared from "./shared";
import globals from "./globals";
import account_settings from "./account_settings";
import user_quizzes from "./user_quizzes";

export const ru = {
  ...shared,
  ...globals,
  ...account_settings,
  ...user_quizzes,
};

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "../utils";

const slice = createSlice({
  name: "saml_domains",
  initialState: {
    saml_domain: {},
    saml_domains: [],
    fetch: true,
    getting: false,
    fetched: false,
    fetching: false,
    editing: false,
    creating: false,
    updating: false,
    destroyed: false,
    destroying: false,
    extracted: false,
    open_form: false,
    validations: {},
  },
  reducers: {
    extractedSamlDomain: (state, action) => {
      state.extracted = true;
      state.saml_domain = { ...state.saml_domain, ...action.payload };
    },
    extractingSamlDomain: (state) => {
      state.extracted = false;
    },
    gettingSamlDomain: (state) => {
      state.getting = true;
    },
    gotSamlDomain: (state, action) => {
      state.getting = false;
      state.fetch = true;
      state.validations = {};
      state.saml_domain = action.payload;
    },
    fetchingSamlDomains: (state) => {
      state.fetching = true;
    },
    fetchedSamlDomains: (state, action) => {
      state.fetch = false;
      state.fetching = false;
      state.validations = {};
      state.saml_domains = action.payload;
    },
    createdSamlDomain: (state, action) => {
      state.fetch = true;
      state.creating = false;
      state.open_form = false;
      state.validations = {};
      state.saml_domain = action.payload;
    },
    creatingSamlDomain: (state) => {
      state.creating = true;
    },
    updatedSamlDomain: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.validations = {};
      state.saml_domain = action.payload;
    },
    updatingSamlDomain: (state) => {
      state.updating = true;
    },
    destroyingSamlDomain: (state) => {
      state.destroying = true;
    },
    destroyedSamlDomain: (state) => {
      state.fetch = true;
      state.destroyed = true;
      state.destroying = false;
      state.open_form = false;
      state.validations = {};
    },
    verifyingSamlDomain: (state) => {
      state.verifying = false;
      state.validations = {};
    },
    verifiedSamlDomain: (state) => {
      state.verified = false;
      state.validations = {};
    },
    openedForm: (state, action) => {
      state.open_form = true;
    },
    closedForm: (state) => {
      state.open_form = false;
      state.validations = {};
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  openedForm,
  closedForm,
  extractedSamlDomain,
  extractingSamlDomain,
  gotSamlDomain,
  gettingSamlDomain,
  fetchingSamlDomains,
  fetchedSamlDomains,
  createdSamlDomain,
  creatingSamlDomain,
  updatedSamlDomain,
  updatingSamlDomain,
  destroyedSamlDomain,
  destroyingSamlDomain,
  verifyingSamlDomain,
  verifiedSamlDomain,
  handleValidations,
} = slice.actions;

export const openForm = (saml_domain = {}) => async (dispatch) => {
  dispatch(openedForm(saml_domain));
};

export const closeForm = () => async (dispatch) => {
  dispatch(closedForm());
};

export const verifySamlDomain = (id) => async (dispatch) => {
  try {
    const response = await update({
      endpoint: `/api/v1/saml_domains/${id}/verify`,
    });
    const { saml_domain } = response.data;
    dispatch(gotSamlDomain(saml_domain));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const getSamlDomain = () => async (dispatch) => {
  try {
    dispatch(gettingSamlDomain());

    const response = await get({
      endpoint: `/api/v1/saml_domains`,
    });
    const { saml_domain } = response.data;

    dispatch(gotSamlDomain(saml_domain));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchSamlDomains = () => async (dispatch) => {
  try {
    dispatch(fetchingSamlDomains());

    const response = await fetch({
      endpoint: `/api/v1/saml_domains`,
    });
    const { saml_domains } = response.data;

    dispatch(fetchedSamlDomains(saml_domains));
  } catch (response) {
    console.log(response);
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const createSamlDomain = (data) => async (dispatch) => {
  try {
    dispatch(creatingSamlDomain());

    const response = await create({
      endpoint: `/api/v1/saml_domains`,
      data: { saml_domain: data },
    });
    const { saml_domain } = response.data;

    toastCreateSuccessMessage();
    dispatch(createdSamlDomain(saml_domain));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateSamlDomain = (data) => async (dispatch) => {
  try {
    dispatch(updatingSamlDomain());

    const response = await update({
      endpoint: `/api/v1/saml_domains`,
      data: { saml_domain: data },
    });
    const { saml_domain } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedSamlDomain(saml_domain));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const destroySamlDomain = (id) => async (dispatch) => {
  try {
    dispatch(destroyingSamlDomain());

    const response = await destroy({
      endpoint: `/api/v1/saml_domains/${id}`,
    });
    toastDeleteSuccessMessage();
    dispatch(destroyedSamlDomain());
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get, fetch, create, update, destroy } from "app/api";
import {
  handleMeResponse,
  handleActionResponse,
  toastCreateSuccessMessage,
  toastUpdateSuccessMessage,
  toastDeleteSuccessMessage,
} from "app/utils";

const slice = createSlice({
  name: "kids_schedules",
  initialState: {
    kid_id: null,
    schedule: null,
    schedules: [],
    fetch: true,
    getting: false,
    fetched: false,
    fetching: false,
    editing: false,
    updating: false,
    initialized: false,
    open_reward_form: false,
    open_settings_form: false,
    pages: {
      current_page: 1,
      next_page: 0,
      prev_page: null,
      total_count: 0,
      total_pages: 0,
    },
    filters: {
      page: 1,
      per_page: 6,
      sort: { id: "asc" },
      filter: {},
      search: {},
    },
    validations: {},
  },
  reducers: {
    gettingSchedule: (state) => {
      state.getting = true;
    },
    gotSchedule: (state, action) => {
      state.getting = false;
      state.validations = {};
      state.schedule = action.payload;
    },
    fetchedSchedules: (state, action) => {
      const { meta, schedules } = action.payload;

      state.fetch = false;
      state.fetched = true;
      state.fetching = false;
      state.pages = meta.pages;
      state.schedules = schedules;
    },
    fetchingSchedules: (state) => {
      state.fetching = true;
      state.initialized = true;
    },
    updatedSchedule: (state, action) => {
      state.fetch = true;
      state.updating = false;
      state.schedule = action.payload;
      state.validations = {};
    },
    updatingSchedule: (state) => {
      state.updating = true;
    },
    openedScheduleForm: (state, action) => {
      state.open_form = true;
      state.schedule = action.payload;
    },
    closedScheduleForm: (state) => {
      state.open_form = false;
      state.schedule = {};
      state.validations = {};
    },
    openedSettingsForm: (state, action) => {
      state.open_settings_form = true;
      state.schedule = action.payload;
    },
    closedSettingsForm: (state) => {
      state.open_settings_form = false;
      state.schedule = {};
      state.validations = {};
    },
    openedModalSchedule: (state, action) => {
      state.schedule = action.payload;
      state.open_reward_form = true;
    },
    closedModalSchedule: (state, action) => {
      state.schedule = {};
      state.open_reward_form = false;
    },
    resettedSchedules: (state) => {
      state.initialized = false;
      state.schedules = [];
    },
    handleSetFilters: (state, action) => {
      state.fetch = true;
      state.filters = { ...state.filters, ...action.payload };
    },
    handleValidations: (state, action) => {
      state.fetching = false;
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const {
  gotSchedule,
  gettingSchedule,
  fetchedSchedules,
  fetchingSchedules,
  updatedSchedule,
  updatingSchedule,
  openedScheduleForm,
  closedScheduleForm,
  openedModalSchedule,
  closedModalSchedule,
  handleSetFilters,
  resettedSchedules,
  openedSettingsForm,
  closedSettingsForm,
  handleValidations,
} = slice.actions;

export const resetSchedules = () => async (dispatch) => {
  dispatch(resettedSchedules());
};

export const openScheduleForm = (schedule = {}) => async (dispatch) => {
  dispatch(openedScheduleForm(schedule));
};

export const closeScheduleForm = () => async (dispatch) => {
  dispatch(closedScheduleForm());
};

export const setFilters = (data) => async (dispatch) => {
  dispatch(handleSetFilters(data));
};

export const openSettingsForm = (schedule = {}) => async (dispatch) => {
  dispatch(openedSettingsForm(schedule));
};

export const closeSettingsForm = () => async (dispatch) => {
  dispatch(closedSettingsForm());
};

export const openModalSchedule = (schedule) => async (dispatch) => {
  dispatch(openedModalSchedule(schedule));
};

export const closeModalSchedule = () => async (dispatch) => {
  dispatch(closedModalSchedule());
};

export const getSchedule = (kid_id, id) => async (dispatch) => {
  try {
    dispatch(gettingSchedule());

    const response = await get({
      endpoint: `/api/v1/kids/${kid_id}/schedules/${id}`,
    });
    const { schedule } = response.data;

    dispatch(gotSchedule(schedule));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const fetchSchedules = (kid_id, data) => async (dispatch) => {
  try {
    dispatch(fetchingSchedules());

    const response = await fetch({
      endpoint: `/api/v1/kids/${kid_id}/schedules`,
      data,
    });

    dispatch(fetchedSchedules(response.data));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

export const updateSchedule = (kid_id, id, data) => async (dispatch) => {
  try {
    dispatch(updatingSchedule());

    const response = await update({
      endpoint: `/api/v1/kids/${kid_id}/schedules/${id}`,
      data: { schedule: data },
    });
    const { schedule } = response.data;

    toastUpdateSuccessMessage();
    dispatch(updatedSchedule(schedule));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

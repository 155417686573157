import React, { useState, useContext, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { ActionCableConsumer } from "app/cable";

import { updateMe } from "app/store/me";
import { toastInfo, toastError, toastSuccess, toastWarning } from "app/utils";

function DistanceClassChannel(props) {
  const dispatch = useDispatch();
  const { distance_class_id } = props;
  const [channel, setChannel] = useState({});

  useEffect(() => {
    setChannel({
      id: distance_class_id,
      channel: "DistanceClassChannel",
    });
  }, [distance_class_id]);

  function onReceived(data) {
    switch (data.type) {
      case "toast": {
        toastInfo(data.message);
        break;
      }

      default:
    }

    // toastInfo(data.message);
  }

  function onConnected() {
    // status == 'offline' && dispatch(updateMe({ status: 'online' }, false));
  }

  function onDisconnected() {
    // status == 'online' && dispatch(updateMe({ status: 'offline' }, false));
  }

  function onRejected() {
    // status == 'online' && dispatch(updateMe({ status: 'offline' }, false));
  }

  function handleGlobalMessage(data) {
    console.log(data);
    switch (data.type) {
      case "toast": {
        toastInfo(data.message);
        break;
      }

      default:
    }
  }

  if (!distance_class_id) return null;

  return (
    <ActionCableConsumer
      channel={channel}
      onReceived={onReceived}
      onConnected={onConnected}
      onDisconnected={onDisconnected}
      onRejected={onRejected}
    />
  );
}

export default DistanceClassChannel;

import styled from "styled-components";

import { sizes } from "./utils/styles";

const paddingLeft = sizes.appNavBarLeftWidth + sizes.secondarySideBarWidth + 40;

export const AppContainer = styled.div`
  background: #f9f9f9;
  height: 100%;
`;

export const GridContainer = styled.div`
  display: flex;
  min-height: 410px;
  flex-direction: column;
  justify-content: space-between;
`;
export const GridAction = styled.div``;
export const GridPagination = styled.div``;

export const TabPaneStyles = { border: "none", boxShadow: "none" };

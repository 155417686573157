import React, { useEffect, useState } from "react";

import { Menu, Segment } from "semantic-ui-react";

import Loading from "./Loading";

export default function Status(props) {
  return (
    <>
      <Segment
        style={{
          margin: 0,
          padding: 0,
          position: "sticky",
          top: 0,
          zIndex: 10,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Menu secondary style={{ width: "100%" }}></Menu>
      </Segment>
      <Loading />
    </>
  );
}

import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";

import { get } from "app/api";
import { handleActionResponse } from "app/utils";

const slice = createSlice({
  name: "settings",
  initialState: {
    roles: [],
    links: {},
    locales: [{ text: "English", value: "en" }],
    locale_entities: { en: { value: "en", long_name: "en-US" } },
    accounts: [],
    memberships: [],
    grade_levels: [],
    permissions: {},
    getting: false,
    validations: {},
  },
  reducers: {
    gettingSettings: (state) => {
      state.getting = true;
    },
    gotSettings: (state, action) => {
      const {
        roles,
        locales,
        accounts,
        grade_levels,
        memberships,
        permissions,
        links,
      } = action.payload;

      state.getting = false;
      state.roles = roles;
      state.links = links;
      state.locales = locales;
      state.grade_levels = grade_levels;
      state.locale_entities = locales.reduce((hash, locale) => {
        hash[locale.value] = locale;
        return hash;
      }, {});
      state.accounts = accounts;
      state.memberships = memberships;
      state.permissions = permissions;
    },
    handleValidations: (state, action) => {
      state.validations = action.payload;
    },
  },
});

export default slice.reducer;

const { gotSettings, gettingSettings, handleValidations } = slice.actions;

export const getSettings = () => async (dispatch, getState) => {
  try {
    dispatch(gettingSettings());

    const response = await get({
      endpoint: `/api/v1/settings`,
    });
    const { settings } = response.data;

    dispatch(gotSettings(settings));
  } catch (response) {
    const validations = handleActionResponse(response);
    if (validations) dispatch(handleValidations(validations));
  }
};

import React, { useState, useEffect, useRef, createRef } from "react";

import I18n from "i18n-js";
import { motion } from "framer-motion";
import styled from "styled-components";
import { Ref, Icon, Menu, Button } from "semantic-ui-react";
import { useSpeechSynthesis } from "react-speech-kit";
import { useSelector, useDispatch } from "react-redux";
import useSmoothScroll from "use-smooth-scroll";

import { useWindowDimensions } from "app/hooks";
import { setSpeaker, closeVoiceSwitcher } from "app/store/talk";

export default function VoiceSlideSelector() {
  const dispatch = useDispatch();
  const voicesRef = createRef(null);
  const { open } = useSelector((state) => state.talk);
  const [voices, setVoices] = useState([]);
  const [position, setPosition] = useState(0);
  const { height, width } = useWindowDimensions();

  const [speakers, setSpeakers] = useState([]);
  const { speaker } = useSelector((state) => state.talk);

  const { x, y } = position;

  const scrollTo = useSmoothScroll("x", voicesRef);

  useEffect(() => {
    if (!voicesRef.current) return;

    scrollTo(position);
  }, [position]);

  useEffect(() => {
    if (window.speechSynthesis) {
      setTimeout(() => {
        const v = window.speechSynthesis.getVoices();
        setVoices(v);
      }, 100);
    }
  }, []);

  useEffect(() => {
    const voice = voices.find((v) => v.lang == "en-US" || v.lang == "en");

    if (voice) dispatch(setSpeaker({ lang: voice.lang, name: voice.name }));

    setSpeakers(
      voices.map((voice) => {
        const locale = voice.lang.split("-")[0];

        return {
          key: `${voice.lang}-${voice.name}`,
          text: `${I18n.t(`locale_${locale}`)}-${voice.name}`,
          locale: I18n.t(`locale_${locale}`),
          name: voice.name,
          value: { lang: voice.lang, name: voice.name },
        };
      })
    );
  }, [voices]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <ScrollHandle
        onClick={() => {
          if (position - width >= 0) {
            setPosition(position - width);
          }
        }}
      >
        <Icon name="chevron left" style={{ height: 30 }} />
      </ScrollHandle>
      <Ref innerRef={voicesRef}>
        <Menu
          secondary
          style={{
            left: 0,
            marginTop: 0,
            marginBottom: 0,
            overflowX: "hidden",
          }}
        >
          {speakers.map((s) => {
            const active = s.key === `${speaker.lang}-${speaker.name}`;

            return (
              <Menu.Item key={s.key} style={{ padding: "0px 0px 0px 2px" }}>
                <Button
                  basic={!active}
                  color="black"
                  compact
                  style={{ padding: 8 }}
                  onClick={() => dispatch(setSpeaker(s.value))}
                >
                  <span
                    style={{
                      color: active ? "rgb(236 236 236)" : "#36236d",
                      fontWeight: "bold",
                    }}
                  >
                    {s.locale}
                  </span>
                  -<span>{s.name}</span>
                </Button>
              </Menu.Item>
            );
          })}
        </Menu>
      </Ref>
      <ScrollHandle
        style={{ transform: "rotate(180deg)", marginRight: 0 }}
        onClick={() => {
          if (position + width < voicesRef.current.scrollWidth) {
            setPosition(position + width);
          }
        }}
      >
        <Icon name="chevron left" style={{ height: 30 }} />
      </ScrollHandle>
    </div>
  );
}

const ScrollHandle = styled.div`
  height: 30px;
  font-size: 2em;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  box-shadow: 1px 0px 2px #a7a2a2;
  background: white;
  margin-right: 4px;

  &:hover {
    color: #858586;
  }
`;

import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import {
  Header,
  Button,
  Icon,
  Form,
  Card,
  Image,
  Modal,
} from "semantic-ui-react";
import Lottie from "react-lottie";

import styled, { keyframes } from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import Loading from "app/components/Loading";
import familyLottie from "app/assets/lottie/family.json";

import { getAccount, updateAccount, upgradeAccount } from "app/store/accounts";
import { updateMe, updatePassword, resendConfirmation } from "app/store/me";

export default function Identify() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [type, setType] = useState();
  const [level, setLevel] = useState();
  const [loading, setLoading] = useState(false);

  const { grade_levels } = useSelector((state) => state.settings);

  const {
    user: { locale, identified, account_id },
    logged_in,
    login_in_progress,
  } = useSelector((state) => state.me);

  if (!logged_in) {
    return null;
  }

  function finishSetup() {
    setLoading(true);

    if (type == "family") {
      dispatch(upgradeAccount(account_id, "family"));
    }

    if (type == "kid") {
      dispatch(updateMe({ level }));
      dispatch(updateAccount(account_id, { identified: true }));
    }
  }

  return (
    <Modal open={!identified} size="small">
      <Modal.Header>Account verification</Modal.Header>
      <Modal.Content>
        {step == 0 && (
          <Modal.Description>
            <Card.Group style={{ flexWrap: "nowrap" }}>
              <Card
                fluid
                style={{ cursor: "pointer", background: "#d2daf1" }}
                onClick={() => {
                  setType("family");
                }}
              >
                <Card.Content>
                  <Card.Header>Are you parent?</Card.Header>
                  <Card.Description>
                    You will be able to see dashboard of your kids activity and
                    assign tasks.
                  </Card.Description>
                  {type == "family" && (
                    <Icon
                      name="checkmark"
                      style={{ position: "absolute", right: 4, bottom: 8 }}
                    />
                  )}
                </Card.Content>
              </Card>

              <Card
                fluid
                style={{ cursor: "pointer", background: "#d2daf1" }}
                onClick={() => {
                  setType("kid");
                }}
              >
                <Card.Content>
                  <Card.Header>Are you kid?</Card.Header>
                  <Card.Description>
                    You will have assignments.
                  </Card.Description>
                  {type == "kid" && (
                    <Icon
                      name="checkmark"
                      style={{ position: "absolute", right: 4, bottom: 8 }}
                    />
                  )}
                </Card.Content>
              </Card>
            </Card.Group>
          </Modal.Description>
        )}

        {step == 1 && type == "kid" && (
          <Modal.Description>
            <Header as="h2" style={{ color: "#4e719d" }}>
              Select your grade level
            </Header>
            <Form.Select
              fluid
              value={level}
              options={grade_levels}
              placeholder="Grade Level"
              onChange={(event, { value }) => {
                setLevel(value);
              }}
            />
          </Modal.Description>
        )}

        <Modal.Description
          style={{ display: step == 1 && type == "family" ? "block" : "none" }}
        >
          <Header as="h2" style={{ color: "#4e719d" }}>
            We are upgrading your account to family!
          </Header>
          <Lottie
            speed={1}
            isClickToPauseDisabled
            style={{
              margin: "auto auto",
              height: 200,
            }}
            options={{
              loop: true,
              autoplay: true,
              animationData: familyLottie,
            }}
            isStopped={false}
            isPaused={false}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={step == 0 || loading}
          onClick={() => setStep(step - 1)}
          secondary
        >
          Back
        </Button>
        {step == 0 && (
          <Button disabled={!type} onClick={() => setStep(step + 1)} primary>
            Next
          </Button>
        )}
        {step == 1 && (
          <Button
            loading={loading}
            disabled={loading}
            onClick={() => finishSetup()}
            primary
          >
            Finish
          </Button>
        )}
      </Modal.Actions>
    </Modal>
  );
}

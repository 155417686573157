import React, { useState, useEffect } from "react";

import I18n from "i18n-js";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { ResponsiveBar } from "@nivo/bar";
import { generateCountriesData } from "@nivo/generators";

import { Icon, Grid, Header, Statistic, Container } from "semantic-ui-react";

export default function Gifts(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { earned_points, earned_gifts, total_gifts } = props;

  return (
    <Container
      style={{
        height: 400,
        justifyContent: "space-around",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header as="h4" icon textAlign="center">
        <Icon name="gift" style={{ color: "#1473ad" }} />
      </Header>

      <Grid.Row textAlign="center">
        <Statistic size="medium" color="orange" style={{ margin: 20 }}>
          <Statistic.Label>Total Gifts</Statistic.Label>
          <Statistic.Value>{total_gifts}</Statistic.Value>
        </Statistic>
      </Grid.Row>
      <Grid.Row textAlign="center">
        <Statistic size="medium" color="teal" style={{ margin: 20 }}>
          <Statistic.Label>Earned Gifts</Statistic.Label>
          <Statistic.Value>{earned_gifts}</Statistic.Value>
        </Statistic>
      </Grid.Row>
    </Container>
  );
}

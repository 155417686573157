import { namespace } from "../util";

const translations = {
  create: "Create",
  update: "Update",
  destroy: "Destroy",

  fullname: "Full Name",
  email: "Email",
  role: "Role",
  phone: "Phone",
  actions: "Actions",
};

export default namespace("users", translations);
